import { atom } from 'recoil';
import { ApplicationResource } from '@kernex/common';
import { Field } from '@kernex/core';

type State = {
  loading: boolean;
  service: ApplicationResource | null;
  fields: Field[];
};

const appAdminAtom = atom<State>({
  key: 'appAdminAtom',
  default: {
    loading: true,
    service: null,
    fields: [],
  },
});

export default appAdminAtom;
