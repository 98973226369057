import { FieldTypeDetailsRendererProps } from '@kernex/core';
import { useRecoilValue } from 'recoil';
import { useCallback, useEffect, useState } from 'react';
import { Tag } from 'antd';
import { ApplicationResource } from '@kernex/common';
import { Link } from 'react-router-dom';
import api from '../../../../api';
import appsAtom from '../../../apps/state';
import appAdminAtom from '../../../app-admin/state';
import organizationsAtom from '../../../organizations/atoms';

export default function DetailsRenderer(props: FieldTypeDetailsRendererProps) {
  const { organization } = useRecoilValue(organizationsAtom);
  const { app } = useRecoilValue(appsAtom);
  const { service } = useRecoilValue(appAdminAtom);
  const { value, field } = props;
  const [data, setData] = useState<Record<string, any>>();
  const [resource, setResource] = useState<ApplicationResource>();
  const appId = app?._id;

  const loadData = useCallback(async () => {
    if (appId && service && value && field.relationResourceId) {
      const arrayValue = Array.isArray(value) ? value : [value];
      const res = await api.applicationResources.get(field.relationResourceId, {
        query: {
          $select: ['slug', 'titleFieldName'],
        },
      });
      const response = await api.appResourceInstance(appId, res.slug).find({
        query: {
          _id: {
            $in: arrayValue,
          },
          $select: ['_id', res.titleFieldName],
          $limit: arrayValue.length,
        },
      });
      setResource(res);
      setData(response.data);
    }
  }, [appId, service?._id, value, field.relationResourceId]);

  useEffect(() => {
    loadData().then();
  }, [loadData]);

  if (!value || !data || !resource) {
    return null;
  }

  return resource && app && service && organization && data.map((entry: Record<string, any>) => (
    <Link
      to={`/app/organizations/${organization.slug}/apps/${app.slug}/content/${resource.slug}/${entry._id}`}
      key={entry._id}
    >
      <Tag>{resource?.titleFieldName && entry[resource.titleFieldName]}</Tag>
    </Link>
  ));
}
