import React, { HTMLAttributes } from 'react';
import { useEditor, useNode } from '@craftjs/core';

import styles from './index.module.css';

export function ContainerBlockPreview(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} />
  );
}

export default function ContainerBlock(props: HTMLAttributes<HTMLDivElement>) {
  const { children, className, ...rest } = props;
  const { enabled } = useEditor((state) => ({ enabled: state.options.enabled }));
  const { nodeId, connectors: { connect, drag } } = useNode((node) => ({
    nodeId: node.id,
  }));

  const { hasChildren } = useEditor((state, query) => ({
    hasChildren: query.node(nodeId).childNodes().length > 0,
  }));

  return (
    <div
      /*
      // @ts-ignore */
      ref={(ref) => connect(drag(ref))}
      {...rest}
    >
      {
        (hasChildren || !enabled) ? children : (<p className={styles.emptyText}>Drop components here</p>)
      }
    </div>
  );
}

ContainerBlock.craft = {
  name: 'Container',
  props: {
    style: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  custom: {
    styleSettings: {
      padding: {
        value: 20,
        unit: 'px',
      },
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

ContainerBlockPreview.craft = ContainerBlock.craft;
