import { AppUser, AppUserRole } from '@kernex/common';

export default function checkAppRole(role: AppUserRole | AppUserRole[], user?: AppUser | null): boolean {
  if (!user) {
    return false;
  }

  if (Array.isArray(role)) {
    return role.some((r) => r === user.role);
  }

  return user.role === role;
}
