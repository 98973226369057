import { Field, FieldTypeInputProps } from '@kernex/core';
import { useFieldTypes } from '../../../../plugins';

interface AppResourceFieldInputProps {
  appId: string;
  field: Field;
  value?: any;
  onChange?: (value: unknown) => void;
  relatedFieldValue?: any;
  relatedFieldTouched?: boolean;
}

export default function AppResourceFieldInput(props: AppResourceFieldInputProps) {
  const {
    field, value, onChange, appId, relatedFieldValue, relatedFieldTouched,
  } = props;

  const fieldTypes = useFieldTypes();

  const fieldType = fieldTypes.find((f) => f.type === field.type);

  if (fieldType) {
    const { Input } = fieldType;

    const inputProps: FieldTypeInputProps = {
      field,
      appId,
      value,
      onChange,
      relatedFieldValue,
      relatedFieldTouched,
    };

    return (<Input {...inputProps} />);
  }

  return null;
}
