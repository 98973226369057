import { Form, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  ApplicationResource,
  ApplicationResourceData,
} from '@kernex/common';
import Button from '../../../common/components/Button';
import api from '../../../../api';
import appsAtom from '../../../apps/state';
import { setFormErrorsFromServer } from '../../../common/utils/errors';

interface AppResourceFormProps {
  appId: string;
  service?: ApplicationResource;
}

export default function AppResourceForm(props: AppResourceFormProps) {
  const { appId, service } = props;
  const [, setAppsState] = useRecoilState(appsAtom);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onFinish = async (data: Omit<ApplicationResourceData, 'applicationId' | 'databaseConfig'>) => {
    setLoading(true);

    try {
      if (!service) {
        const response = await api.applicationResources.create({
          applicationId: appId,
          ...data,
        });
        setAppsState((prevState) => ({
          ...prevState,
          services: [...prevState.services, response],
        }));
        navigate(`../${response.slug}`);
      } else {
        await api.applicationResources.patch(service._id, data);
      }
    } catch (e) {
      setFormErrorsFromServer(e, form);
    }

    setLoading(false);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} initialValues={(service || {})}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input resource name' }]}
      >
        <Input placeholder="Resource name" />
      </Form.Item>
      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          { required: true, message: 'Please input resource slug!' },
          { min: 3, message: 'Slug must be at least 3 characters long!' },
          { max: 50, message: 'Slug must be at most 50 characters long!' },
          {
            pattern: /^[a-z0-9][a-z0-9-]*[a-z0-9]$/,
            message: 'Only alphanumeric characters and dashes are allowed',
          },
        ]}
      >
        <Input placeholder="Resource slug" />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input placeholder="Resource description" />
      </Form.Item>

      <div className="d-flex justify-content-center">
        <Form.Item className="m-0">
          <Button htmlType="submit" type="primary" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
