import {
  Form, Input, message, notification, Select,
} from 'antd';
import { useState } from 'react';
import { Application, DatabaseHost, DatabaseType } from '@kernex/common';
import Button from '../../../common/components/Button';
import api from '../../../../api';
import EncryptedValueInfo from '../../../common/components/EncryptedValueInfo';

interface ApplicationDatabaseSettingsProps {
  app: Application;
  onUpdate?: (app: Application) => void;
}

export default function AppDatabaseSettings(props: ApplicationDatabaseSettingsProps) {
  const { app, onUpdate } = props;
  const { database } = app;
  const [form] = Form.useForm<Application['database']>();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: Application['database']) => {
    setLoading(true);

    try {
      const response = await api.applications.patch(app._id, { database: values });
      message.success('Database settings updated');

      if (onUpdate) {
        onUpdate(response);
      }
    } catch (e) {
      notification.error({
        message: 'Something went wrong',
        description: 'Please try again',
      });
    }

    setLoading(false);
  };

  return (
    <Form layout="vertical" initialValues={database} form={form} onFinish={onFinish}>
      <Form.Item
        label="Host"
        name="host"
        rules={[{ required: true, message: 'Please select a host' }]}
      >
        <Select
          placeholder="Select a host"
          options={[
            { label: 'Hosted by Kernex', value: DatabaseHost.KERNEX },
            { label: 'Self Hosted', value: DatabaseHost.SELF_HOSTED },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Database"
        name="database"
        rules={[{ required: true, message: 'Please select a database' }]}
      >
        <Select
          placeholder="Select a database"
          options={[
            { label: 'MongoDB', value: DatabaseType.MONGODB },
            { label: 'MySQL (coming soon)', value: 'mysql', disabled: true },
            { label: 'PostgreSQL (coming soon)', value: 'postgres', disabled: true },
          ]}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => {
          if (form.getFieldValue('host') === DatabaseHost.SELF_HOSTED) {
            return (
              <Form.Item
                label={(
                  <span className="d-flex align-items-center justify-content-center">
                    Connection String (including database name)
                    &nbsp;
                    <EncryptedValueInfo />
                  </span>
                )}
                name={['config', 'connectionString']}
                rules={[{ required: true, message: 'Connection string is required' }]}
              >
                <Input placeholder="Connection string" />
              </Form.Item>
            );
          }

          return null;
        }}
      </Form.Item>
      <Form.Item className="m-0">
        <Button htmlType="submit" loading={loading}>Save</Button>
      </Form.Item>
    </Form>
  );
}
