import { FieldRelationType, FieldTypeInputProps } from '@kernex/core';
import AppResourceEntrySelect from '../../../app-resources/components/AppResourceEntrySelect';

export default function Input(props: FieldTypeInputProps) {
  const { field, value, onChange } = props;

  return (
    <AppResourceEntrySelect
      resourceId={String(field.relationResourceId)}
      value={value}
      onChange={onChange}
      mode={field.relationType === FieldRelationType.ONE_TO_MANY ? 'multiple' : undefined}
      placeholder={field.description}
    />
  );
}
