import {
  Form, Input, Button, Alert,
} from 'antd';
import { Link } from 'react-router-dom';
import { User } from '@kernex/common';
import api from '../../../../api';
import useFormSubmit from '../../../common/hooks/useFormSubmit';
import AuthLayout from '../../layout/AuthLayout';

export default function SignUpPage() {
  const [form] = Form.useForm();

  const [signUp, loading, response] = useFormSubmit(
    async (values: Pick<User, 'name' | 'email' | 'password'>) => api.users.create({
      name: values.name,
      email: values.email,
      password: values.password,
    }),
    { form },
  );

  return (
    <AuthLayout>
      <h1>Create an Account</h1>

      {
        !response ? (
          <Form
            layout="vertical"
            className="hp-mt-sm-16 hp-mt-32"
            onFinish={signUp}
            form={form}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: 'Please enter your name' },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please input your email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password' },
                { min: 8, message: 'Password should contain at least 8 characters' },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="passwordConfirm"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>

            <Form.Item className="hp-mt-16 hp-mb-8">
              <Button block type="primary" htmlType="submit" loading={loading}>
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Alert
            type="success"
            message={`You have successfully signed up, 
              please verify your email address by clicking the link in the verification email.
            `}
            className="mb-2"
          />
        )
      }

      <p>
        Already have an account?
        &nbsp;
        <Link to="/sign-in">Sign In</Link>
      </p>
    </AuthLayout>
  );
}
