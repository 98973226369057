import { InputHTMLAttributes } from 'react';
import classnames from 'classnames';
import styles from './index.module.css';

export default function SidebarInput(props: InputHTMLAttributes<HTMLInputElement>) {
  const { className, ...rest } = props;
  return (
    <input
      className={classnames(styles.input, className)}
      {...rest}
      {...rest}
    />
  );
}
