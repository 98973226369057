import { useCallback, useState } from 'react';

type UseToggle = [boolean, { on: () => void; off: () => void; toggle: () => void; }];

export default function useToggle(initial: boolean): UseToggle {
  const [value, setOn] = useState<boolean>(initial);

  const on = useCallback(() => {
    setOn(true);
  }, []);
  const off = useCallback(() => {
    setOn(false);
  }, []);
  const toggle = useCallback(() => {
    setOn((prevState) => !prevState);
  }, []);

  return [value, { on, off, toggle }];
}
