import { Card } from 'antd';
import AppTemplate from '../../definitions/AppTemplate';

interface AppTemplateCardProps {
  name: AppTemplate['name'];
  description: AppTemplate['description'];
  selected?: boolean;
  onClick?: () => void;
}

export default function AppTemplateCard(props: AppTemplateCardProps) {
  const {
    name, description, selected, onClick,
  } = props;

  return (
    <Card
      title={name}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        border: `1px solid ${selected ? 'blue' : 'gray'}`,
      }}
      className="h-100"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <p className="mb-0 text-muted">{description}</p>
    </Card>
  );
}
