import {
  Col, Form, Row, Tabs,
} from 'antd';
import { ReactNode, useState } from 'react';
import { Organization } from '@kernex/common';
import { Link } from 'react-router-dom';
import PageContent from '../../../layout/PageContent';
import OrganizationForm from '../OrganizationsPage/OrganizationForm';
import api from '../../../../api';
import { setFormErrorsFromServer } from '../../../common/utils/errors';
import Permission from '../../../permissions/utils/Permission';
import useOrganizationPermissions from '../../../permissions/hooks/useOrganizationPermissions';
import OrganizationBilling from '../../components/OrganizationBilling';
import useOrganization from '../../hooks/useOrganization';

function OrganizationEditForm({ organization }: { organization: Organization | null }) {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values: Pick<Organization, 'name' | 'slug'>) => {
    if (organization) {
      setLoading(true);
      try {
        await api.app.service('organizations').patch(organization?._id, values);
        window.location.reload();
      } catch (e) {
        setFormErrorsFromServer(e, form);
      }
      setLoading(false);
    }
  };

  return (
    <OrganizationForm
      form={form}
      onFinish={onFinish}
      loading={loading}
      organization={organization}
      showSubmitButton
    />
  );
}

interface OrganizationIndexPageProps {
  activeKey: 'info' | 'edit' | 'billing';
  paymentComplete?: boolean;
}

interface Tab {
  label: ReactNode;
  key: string;
  children: ReactNode;
}

export default function OrganizationIndexPage(props: OrganizationIndexPageProps) {
  const { activeKey, paymentComplete } = props;
  const organization = useOrganization();
  const canEdit = useOrganizationPermissions(Permission.ORGANIZATION_EDIT);

  const tabs: Tab[] = [
    {
      label: <Link to={`/app/organizations/${organization?.slug}`} className="link-unstyled">Info</Link>,
      key: 'info',
      children: (
        <div className="h-100 d-flex flex-column align-items-center justify-content-center">
          <h3>
            Welcome to
            {' '}
            {organization?.name}
          </h3>
          <h4>Please select an app on the left sidebar, or create a new app</h4>
        </div>
      ),
    },
  ];

  if (canEdit) {
    tabs.push(...[
      {
        label: <Link to={`/app/organizations/${organization?.slug}/edit`} className="link-unstyled">Edit</Link>,
        key: 'edit',
        children: (
          <Row>
            <Col span={24} md={12}>
              <OrganizationEditForm organization={organization} />
            </Col>
          </Row>
        ),
      },
      {
        label: <Link to={`/app/organizations/${organization?.slug}/billing`} className="link-unstyled">Billing</Link>,
        key: 'billing',
        children: <OrganizationBilling paymentComplete={paymentComplete} />,
      },
    ]);
  }

  return (
    <PageContent title={organization?.name || 'Organizations'}>
      <Tabs activeKey={activeKey} items={tabs} className="h-100" />
    </PageContent>
  );
}
