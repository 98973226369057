import {
  AppIntegration, AppIntegrationData, AppIntegrationService, ApplicationResource,
} from '@kernex/common';
import {
  Divider, Form, Input, Select, Switch,
} from 'antd';
import DrawerForm, { DrawerFormProps } from '../../../common/components/DrawerForm';
import AppResourceFieldSelect from '../AppResourceFieldSelect';
import api from '../../../../api';

interface AppResourceIntegrationDrawerFormProps extends Omit<DrawerFormProps<AppIntegrationData>, 'children'> {
  resourceId: ApplicationResource['_id'];
  onSuccess?: (integration: AppIntegration) => void;
  integration?: Pick<AppIntegration, '_id'> & Partial<AppIntegration>;
}

const initialValues = {
  service: Object.values(AppIntegrationService)[0],
  enabled: false,
  config: {
    indexName: '',
    fields: [],
  },
};

export default function AppResourceIntegrationDrawerForm(props: AppResourceIntegrationDrawerFormProps) {
  const {
    resourceId, integration, onSuccess, ...drawerFormProps
  } = props;

  const save = async (values: Omit<AppIntegrationData, 'resourceId'>) => {
    if (!integration) {
      return api.appIntegrations.create({ ...values, resourceId });
    }

    return api.appIntegrations.patch(integration._id, values);
  };

  const onFinish = async (values: Omit<AppIntegrationData, 'resourceId'>) => {
    const result = await save(values);

    if (onSuccess) {
      onSuccess(result);
    }
  };

  return (
    <DrawerForm<AppIntegrationData>
      title={!integration ? 'Add Integration' : 'Edit Integration'}
      initialValues={{ ...initialValues, ...(integration || {}) }}
      submitButtonText={!integration ? 'Add Integration' : 'Save'}
      onFinish={onFinish}
      {...drawerFormProps}
    >
      {
        (form) => (
          <>
            <Form.Item name="service" label="Service" rules={[{ required: true, message: 'Please select service' }]}>
              <Select>
                <Select.Option key={AppIntegrationService.ALGOLIA}>
                  Algolia
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="enabled"
              label="Enabled"
              valuePropName="checked"
              extra="Only enabled integrations will run. If you disable an integration, it will not run."
            >
              <Switch />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {
                () => {
                  const service = form.getFieldValue('service');

                  if (service === AppIntegrationService.ALGOLIA) {
                    return (
                      <>
                        <Divider>Credentials</Divider>
                        <Form.Item
                          name={['credentials', 'appId']}
                          label="Algolia Application ID"
                          rules={[{ required: true, message: 'Please enter your algolia application ID' }]}
                        >
                          <Input placeholder="App ID" />
                        </Form.Item>
                        <Form.Item
                          name={['credentials', 'adminApiKey']}
                          label="Algolia App ID"
                          rules={[{ required: true, message: 'Please enter admin api key' }]}
                        >
                          <Input placeholder="Admin API Key" />
                        </Form.Item>
                        <Divider>Config</Divider>
                        <Form.Item
                          name={['config', 'indexName']}
                          label="Algolia Index Name"
                          rules={[{ required: true, message: 'Please enter algolia index name' }]}
                        >
                          <Input placeholder="Index Name" />
                        </Form.Item>
                        <Form.Item
                          name={['config', 'fields']}
                          label="Select fields to index"
                        >
                          <AppResourceFieldSelect
                            placeholder="Select the fields you want to index"
                            mode="multiple"
                            resourceId={resourceId}
                          />
                        </Form.Item>
                      </>
                    );
                  }

                  return null;
                }
              }
            </Form.Item>
          </>
        )
      }
    </DrawerForm>
  );
}
