import { ReactNode } from 'react';
import { Card, Col, Row } from 'antd';

import classnames from 'classnames';
import styles from './index.module.css';

interface PageContentProps {
  title: string;
  description?: string;
  actions?: ReactNode;
  children?: ReactNode;
  disableCardWrapper?: boolean;
  footer?: ReactNode;
  headerExtra?: ReactNode;
}

export default function PageContent(props: PageContentProps) {
  const {
    title, description, children, actions, disableCardWrapper = true, footer, headerExtra,
  } = props;

  return (
    <div className={classnames(styles.container, 'd-flex flex-column')}>
      <div className={styles.titleContainer}>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="mb-0">{title}</h3>
          {actions && <div>{actions}</div>}
        </div>
        {description && <p className="text-muted m-0">{description}</p>}
        {headerExtra}
      </div>
      <div className={classnames('h-100 overflow-auto', styles.contentContainer)}>
        <Row className="flex-grow-1 pt-2 h-100">
          <Col span={24} className="d-flex flex-column h-100">
            {
              !disableCardWrapper ? (
                <Card className="flex-grow-1">{children}</Card>
              ) : children
            }
          </Col>
        </Row>
      </div>
      <div>
        {footer}
      </div>
    </div>
  );
}
