import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';

interface JsonInputProps extends Omit<TextAreaProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
}

function getDisplayValue(value: any): string {
  if (typeof value === 'string') {
    return value;
  }

  try {
    return JSON.stringify(value, null, 2);
  } catch (e) {
    return String(value);
  }
}

export default function JsonInput(props: JsonInputProps) {
  const {
    value, onChange, style = {}, ...textAreaProps
  } = props;

  return (
    <Input.TextArea
      value={getDisplayValue(value)}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      style={{ fontFamily: 'monospace', ...style }}
      {...textAreaProps}
    />
  );
}
