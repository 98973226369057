import { useState } from 'react';
import {
  Card, Checkbox, Col, Divider, Form, Input, notification, Row, Select, Spin, Steps,
} from 'antd';
import { useRecoilValue } from 'recoil';
import {
  ApplicationResource,
} from '@kernex/common';
import { FieldTypeType } from '@kernex/core';
import PageContent from '../../../layout/PageContent';
import Button from '../../../common/components/Button';
import api from '../../../../api';
import { ImportData, ImportResponseEntry } from '../../../../api/services/ApplicationResourceService';
import { sentenceCase } from '../../../../utils';
import appsAtom from '../../../apps/state';
import { SHOW_IMPORT_RESOURCES } from '../../../../global/constants';

type Field = ImportResponseEntry['fields'][number] & {
  import: boolean;
  imported?: boolean;
};

type Resource = Omit<ImportResponseEntry, 'fields'> & {
  name: string;
  import: boolean;
  imported?: boolean;
  fields: Field[];
};

export default function AppResourceImportPage() {
  const [step, setStep] = useState(0);
  const [resources, setResources] = useState<Resource[]>([]);
  const { app } = useRecoilValue(appsAtom);
  const [connectionString, setConnectionString] = useState<string>();
  const [importing, setImporting] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);

  const importField = async (field: Field, resource: ApplicationResource): Promise<Field> => {
    try {
      await api.fields.create({
        ...field,
        displayName: sentenceCase(field.name),
        resourceId: resource._id,
      });
      return { ...field, imported: true };
    } catch (e) {
      return field;
    }
  };

  const importResource = async (resource: Resource) => {
    if (app?._id && connectionString) {
      try {
        const { imported, import: shouldImport, ...data } = resource;
        const applicationResource = await api.applicationResources.create({
          ...data,
          applicationId: app._id,
        });
        const fields = await Promise.all(
          resource.fields.map((field) => importField(field, applicationResource)),
        );
        setResources((previous) => previous.map((s) => {
          if (s.name === resource.name) {
            return {
              ...s,
              fields,
              imported: true,
            };
          }
          return s;
        }));
      } catch (e) {
        // Ignore
      }
    }
  };

  const onImport = async (values: Record<number, Resource>) => {
    setImporting(true);
    setStep(2);

    try {
      await Promise.all(Object.values(values).filter((s) => s.import).map(importResource));
    } catch (e) {
      notification.error({
        message: 'Something went wrong',
      });
    }

    setImporting(false);
  };

  const onSelectResources = async (values: ImportData) => {
    setLoadingSelect(true);
    try {
      const result = await api.applicationResources.import(values);
      setConnectionString(values.connectionString);
      setResources(result.map((item) => ({
        ...item,
        import: true,
        name: sentenceCase(item.slug),
        fields: item.fields.map((field) => ({
          ...field,
          import: true,
        })),
      })));
      setStep(1);
    } catch (e) {
      notification.error({
        message: 'Something went wrong',
        description: 'Please try again',
      });
    }
    setLoadingSelect(false);
  };

  if (!SHOW_IMPORT_RESOURCES) {
    return null;
  }

  return (
    <PageContent title="Import Resources">
      <p>
        Import resources from a database.
        {' '}
        We will scan the database and allow you to select which resources to import.
        {' '}
        It will also create resources fields automatically based on your database.
      </p>
      <Row className="mt-4">
        <Col span={24}>
          <Steps current={step} onChange={setStep}>
            <Steps.Step
              title="Database"
              description="Connect to database"
            />

            <Steps.Step
              title="Select Resources"
              description="Select resources to import"
            />

            <Steps.Step title="Import" description="Import" />
          </Steps>
          <Row justify="center" className="mt-4">
            <Col span={24} md={22} lg={20} xl={18} xxl={16}>
              {
                step === 0 && (
                  <Form layout="vertical" initialValues={{ connectionString }} onFinish={onSelectResources}>
                    <Form.Item
                      label="Connection String"
                      name="connectionString"
                      rules={[{ required: true, message: 'Please input connection string' }]}
                    >
                      <Input placeholder="Mongodb Connection String" />
                    </Form.Item>
                    <Form.Item noStyle>
                      <div className="d-flex justify-content-center">
                        <Button htmlType="submit" loading={loadingSelect}>
                          Select Resources
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                )
              }
              {
                step === 1 && (
                  <Form initialValues={resources} layout="vertical" onFinish={onImport}>
                    <Row gutter={[16, 16]} justify="center">
                      {
                        resources.map((resource, index) => (
                          <Col span={24} key={resource.slug}>
                            <Card size="small">
                              <Row gutter={[16, 16]}>
                                <Col span={24} md={4}>
                                  <Form.Item
                                    name={[index, 'import']}
                                    valuePropName="checked"
                                    label=" "
                                  >
                                    <Checkbox>Import</Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={24} md={10}>
                                  <Form.Item
                                    name={[index, 'name']}
                                    label="Resource Name"
                                    rules={[{ required: true, message: 'Please enter resource name' }]}
                                  >
                                    <Input size="small" />
                                  </Form.Item>
                                </Col>
                                <Col span={24} md={10}>
                                  <Form.Item
                                    name={[index, 'slug']}
                                    label="Resource Slug"
                                    rules={[
                                      { required: true, message: 'Please input resource slug!' },
                                      { min: 3, message: 'Slug must be at least 3 characters long!' },
                                      { max: 50, message: 'Slug must be at most 50 characters long!' },
                                      {
                                        pattern: /^[a-z0-9][a-z0-9-]*[a-z0-9]$/,
                                        message: 'Only alphanumeric characters and dashes are allowed',
                                      },
                                    ]}
                                  >
                                    <Input size="small" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Divider>Fields</Divider>
                              {
                                resource.fields.map((field, fieldIndex) => (
                                  <Row key={field.name} gutter={[16, 16]}>
                                    <Col span={24} md={4}>
                                      <Form.Item
                                        name={[index, 'fields', fieldIndex, 'import']}
                                        valuePropName="checked"
                                        label=" "
                                      >
                                        <Checkbox>Import</Checkbox>
                                      </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                      <Form.Item
                                        name={[index, 'fields', fieldIndex, 'name']}
                                        label="Field Name"
                                        rules={[
                                          { required: true, message: 'Please enter field name' },
                                          {
                                            pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
                                            message: 'Only alphanumeric characters, underscores and numbers',
                                          },
                                        ]}
                                      >
                                        <Input size="small" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                      <Form.Item
                                        name={[index, 'fields', fieldIndex, 'type']}
                                        label="Field Type"
                                        rules={[{ required: true, message: 'Please select field type' }]}
                                      >
                                        <Select
                                          size="small"
                                          placeholder="Field type"
                                          options={[
                                            { label: 'Text', value: FieldTypeType.TEXT },
                                            { label: 'Rich Text', value: FieldTypeType.RICH_TEXT },
                                            { label: 'Number', value: FieldTypeType.NUMBER },
                                            { label: 'Boolean', value: FieldTypeType.BOOLEAN },
                                          ]}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={24} md={4}>
                                      <Form.Item
                                        name={[index, 'fields', fieldIndex, 'required']}
                                        valuePropName="checked"
                                        label=" "
                                      >
                                        <Checkbox>Required</Checkbox>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                ))
                              }
                            </Card>
                          </Col>
                        ))
                      }
                      <Col>
                        <Button
                          onClick={() => {
                            setStep(0);
                          }}
                        >
                          Back
                        </Button>
                        <Form.Item noStyle>
                          <Button className="ms-2" type="primary" htmlType="submit">Import</Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )
              }
              {
                step === 2 && (
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      {
                        importing && (
                          <div className="d-flex align-items-center justify-content-center">
                            <p className="me-2 mb-0">Importing resources, please wait</p>
                            <Spin />
                          </div>
                        )
                      }
                      {
                        !importing && resources.map((resource) => (
                          <Card size="small" className="mb-2" key={resource.name}>
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="m-0">{resource.name}</h5>
                              <h5 className="m-0">
                                <b>
                                  Imported:
                                  {' '}
                                  {resource.imported ? 'YES' : 'NO'}
                                </b>
                              </h5>
                            </div>
                            <Divider>Fields</Divider>
                            {
                              resource.fields.map((field) => (
                                <div className="d-flex align-items-center justify-content-between" key={field.name}>
                                  <p className="m-0">{field.name}</p>
                                  <p className="m-0"><b>{`Imported: ${field.imported ? 'YES' : 'NO'}`}</b></p>
                                </div>
                              ))
                            }
                          </Card>
                        ))
                      }
                    </Col>
                  </Row>
                )
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContent>
  );
}
