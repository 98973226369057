import {
  Field,
  FieldTypeType,
  FieldRelationType,
  FieldType,
} from '@kernex/core';

export default class FieldHelper {
  fieldType?: FieldType;

  constructor(
    readonly field: Field,
    readonly fields: Field[] = [],
    readonly fieldTypes: FieldType[] = [],
  ) {
    this.fieldType = fieldTypes.find((f) => f.type === field.type);
  }

  get isArray() {
    const { type, relationType, isArray } = this.field;

    return isArray || (
      type === FieldTypeType.RELATION
      && relationType === FieldRelationType.ONE_TO_MANY
    );
  }

  getTypescriptType() {
    const { field, fieldType } = this;
    const { type } = field;

    if (fieldType) {
      let tsType = fieldType.typescriptType || 'any';

      if (this.isArray) {
        tsType = tsType.includes('|') ? `(${type})[]` : `${tsType}[]`;
      }

      return tsType;
    }

    return 'any';
  }

  getFieldInterfaceCode() {
    const { field, fields } = this;
    const pieces = [`  ${field.name}`];

    let isOptional = false;

    if (!field.required && !field.autogenerated) {
      isOptional = true;

      if (field.type === FieldTypeType.SLUG) {
        const relatedField = fields.find((f) => f._id === field.relatedFieldId);

        if (relatedField) {
          isOptional = !relatedField.required && !relatedField.autogenerated;
        }
      }
    }

    if (isOptional) {
      pieces.push('?');
    }

    pieces.push(`: ${[this.getTypescriptType()]};`);

    return pieces.join('');
  }
}

export function fieldHelper(
  field: Field,
  fields: Field[] = [],
  fieldTypes: FieldType[] = [],
) {
  return new FieldHelper(field, fields, fieldTypes);
}
