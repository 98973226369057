import { FormInstance, notification } from 'antd';
import useAsyncCall, { UseAsyncCallOptions, UseAsyncCall } from './useAsyncCall';
import { setFormErrorsFromServer } from '../utils/errors';

type UseFormSubmitOptions<Response> = UseAsyncCallOptions<Response> & {
  form?: FormInstance;
  successMessage?: string;
};

export default function useFormSubmit<Response, T extends any[]>(
  fn: (...args: T) => Promise<Response>,
  options: UseFormSubmitOptions<Response> = {},
): UseAsyncCall<Response, T> {
  return useAsyncCall(fn, {
    onError: (error: unknown) => {
      const { onError, form } = options;

      if (onError) onError(error);
      if (form) setFormErrorsFromServer(error, form);
    },
    onSuccess: (response: Response) => {
      const { onSuccess, successMessage } = options;

      if (onSuccess) onSuccess(response);
      if (successMessage) {
        notification.success({ message: successMessage });
      }
    },
  });
}
