import { atom } from 'recoil';
import { User } from '../../../api';

interface AuthAtom {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
}

const authAtom = atom<AuthAtom>({
  key: 'auth',
  default: {
    isAuthenticated: false,
    user: null,
    loading: true,
  },
});

// eslint-disable-next-line import/prefer-default-export
export { authAtom };
