import {
  Outlet, useParams,
} from 'react-router-dom';
import { useEffect } from 'react';
import { Paginated } from '@feathersjs/feathers';
import { useRecoilState } from 'recoil';
import { Organization } from '@kernex/common';

import api from '../../../../api';
import organizationsAtom from '../../atoms';
import LoadingResponse from '../../../common/components/LoadingResponse';
import useAuth from '../../../authentication/hooks/useAuth';

export default function OrganizationPage() {
  const { user } = useAuth();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();

  const [{ loading, organization }, setOrganizationsState] = useRecoilState(organizationsAtom);

  const getOrganization = async () => {
    if (user?._id) {
      setOrganizationsState((previous) => ({ ...previous, loading: true }));

      const response = await api.organizations.find({
        query: {
          slug: organizationSlug,
          $limit: 1,
        },
      }) as Paginated<Organization>;

      const [org] = response.data;

      if (org) {
        const appUsers = await api.appUsers.find({
          query: {
            userId: user._id,
            organizationId: org._id,
            $limit: 100,
          },
        });

        const apps = await api.applications.find({
          query: {
            _id: { $in: appUsers.data.map((u) => u.appId) },
            organizationId: org._id,
            $limit: 100,
          },
        });

        setOrganizationsState((previous) => ({
          ...previous,
          organization: org,
          apps: apps.data,
        }));
      }

      setOrganizationsState((previous) => ({ ...previous, loading: false }));
    }
  };

  useEffect(() => {
    getOrganization().then();
  }, [organizationSlug, user?._id]);

  if (loading) {
    return (
      <LoadingResponse loading={loading} />
    );
  }

  if (!organization) {
    return (
      <div className="h-100 w-100 d-flex align-items-center justify-content-center">
        <h2 className="text-center">Organization not found</h2>
      </div>
    );
  }

  return <Outlet />;
}
