import { useRecoilValue } from 'recoil';
import appsAtom from '../state';

export default function useAppUser() {
  const { appUser, loading } = useRecoilValue(appsAtom);

  return {
    user: appUser,
    loading,
  };
}
