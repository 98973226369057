import { useRecoilState } from 'recoil';
import { notification } from 'antd';

import api, { User } from '../../../api';
import { authAtom } from '../state';
import { getServerErrorMessage } from '../../common/utils/errors';

export default function useAuth() {
  const [auth, setAuth] = useRecoilState(authAtom);

  const { user, loading } = auth;

  const authenticate = async (force = false) => {
    setAuth((prev) => ({ ...prev, loading: true }));

    try {
      const response = await api.app.authentication.reAuthenticate(force);
      setAuth((previous) => ({
        ...previous,
        user: response.user,
        isAuthenticated: true,
        loading: false,
      }));
    } catch (e) {
      setAuth((prev) => ({ ...prev, loading: false }));
    }
  };

  const logout = async () => {
    try {
      await api.app.authentication.logout();
      setAuth((previous) => ({
        ...previous,
        user: null,
        isAuthenticated: false,
      }));
    } catch (e) {
      notification.error({
        message: 'Logout failed',
      });
    }
  };

  const signIn = async (values: Pick<User, 'email'> & { password: string }) => {
    try {
      const response = await api.app.authenticate({
        email: values.email,
        password: values.password,
        strategy: 'local',
      });
      setAuth((previous) => ({
        ...previous,
        user: response.user,
        isAuthenticated: true,
      }));
    } catch (e) {
      notification.error({
        message: getServerErrorMessage(e, 'Wrong email or password'),
      });
    }
  };

  return {
    user, loading, authenticate, logout, signIn,
  };
}
