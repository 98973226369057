import {
  Form, Input, Button, Alert,
} from 'antd';
import { User } from '@kernex/common';
import { useParams } from 'react-router-dom';
import api from '../../../../api';
import useFormSubmit from '../../../common/hooks/useFormSubmit';
import AuthLayout from '../../layout/AuthLayout';

export default function AppSumoSignUpPage() {
  const { code } = useParams<{ code: string }>();
  const [form] = Form.useForm();

  console.info('code', code);

  const [signUp, loading, response] = useFormSubmit(
    async (values: Pick<User, 'name' | 'email' | 'password'> & { appSumoCodes: string }) => api.users.create({
      name: values.name,
      email: values.email,
      password: values.password,
      appSumoCodes: values.appSumoCodes.split(',').map((item) => item.trim()),
    }),
    { form },
  );

  return (
    <AuthLayout>
      <h1>Sign Up | App Sumo</h1>

      {
        !response ? (
          <Form
            layout="vertical"
            className="hp-mt-sm-16 hp-mt-32"
            onFinish={signUp}
            form={form}
            initialValues={{
              appSumoCodes: code,
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: 'Please enter your name' },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please input your email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password' },
                { min: 8, message: 'Password should contain at least 8 characters' },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="passwordConfirm"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>

            <Form.Item
              label="AppSumo Code(s)"
              name="appSumoCodes"
              rules={[
                { required: true, message: 'Please enter your AppSumo code(s)' },
              ]}
              className="mb-0"
            >
              <Input placeholder="Enter your AppSumo code(s)" />
            </Form.Item>
            <small>
              Separate multiple codes with commas, e.g.
              {' '}
              <b>code1, code2</b>
            </small>

            <Form.Item className="hp-mt-16 hp-mb-8 mt-4">
              <Button block type="primary" htmlType="submit" loading={loading}>
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Alert
            type="success"
            message={`You have successfully signed up, 
              please verify your email address by clicking the link in the verification email.
            `}
            className="mb-2"
          />
        )
      }
    </AuthLayout>
  );
}
