import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Field } from '@kernex/core';

import Button from '../../../common/components/Button';
import AppResourceFieldForm from '../../components/AppResourceFieldForm';
import AppResourceFieldCard from '../../components/AppResourceFieldCard';
import api from '../../../../api';

interface AppResourceFieldsProps {
  resourceId: Field['resourceId'];
}

export default function AppResourceFields(props: AppResourceFieldsProps) {
  const { resourceId } = props;

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState<Field>();
  const [fields, setFields] = useState<Field[]>([]);

  const loadFields = async () => {
    const response = await api.fields.find({
      query: {
        resourceId,
        $limit: 100,
      },
    });

    setFields(response.data);
  };

  useEffect(() => {
    loadFields().then();
  }, [resourceId]);

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {
            fields.map((field) => (
              <Col span={24} key={field._id}>
                {
                  !fieldToEdit || fieldToEdit._id !== field._id ? (
                    <AppResourceFieldCard
                      field={field}
                      onEditClick={() => {
                        setFieldToEdit(field);
                      }}
                      onDeleteSuccess={() => {
                        setFields((previous) => previous.filter((f) => f._id !== field._id));
                      }}
                    />
                  ) : (
                    <AppResourceFieldForm
                      field={fieldToEdit}
                      fields={fields}
                      resourceId={resourceId}
                      onCancel={() => setFieldToEdit(undefined)}
                      onSuccess={(newField) => {
                        setFields((previous) => (
                          previous.map((f) => (
                            f._id === newField._id ? newField : f
                          ))
                        ));
                        setFieldToEdit(undefined);
                      }}
                    />
                  )
                }
              </Col>
            ))
          }
        </Row>
      </Col>
      {
        showCreateForm ? (
          <Col span={24}>
            <AppResourceFieldForm
              fields={fields}
              onCancel={() => {
                setShowCreateForm(false);
              }}
              onSuccess={(field: Field) => {
                setFields((prevFields) => [...prevFields, field]);
                setShowCreateForm(false);
              }}
              resourceId={resourceId}
            />
          </Col>
        ) : (
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowCreateForm(true);
                  }}
                >
                  + Add Field
                </Button>
              </Col>
            </Row>
          </Col>
        )
      }
    </Row>
  );
}
