import {
  Drawer, DrawerProps, Form, FormProps, FormInstance,
} from 'antd';
import React, { useEffect } from 'react';
import Button from '../Button';
import { setFormErrorsFromServer } from '../../utils/errors';

export interface DrawerFormProps<Values> extends Pick<DrawerProps, 'open' | 'title' | 'size'>,
  Pick<FormProps<Values>, 'onFinish' | 'layout' | 'initialValues'> {
  children: React.ReactNode | ((form: FormInstance<Values>) => React.ReactNode);
  formProps?: Partial<FormProps<Values>>;
  onClose: () => void;
  submitButtonText?: string;
}

export default function DrawerForm<Values>(props: DrawerFormProps<Values>) {
  const {
    children, open, onClose, onFinish, layout = 'vertical', formProps = {}, title, submitButtonText = 'Save',
    initialValues, size,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const onSubmit = async (values: Values) => {
    if (onFinish) {
      try {
        await onFinish(values);

        // Reset form
        form.resetFields();

        if (onClose) {
          onClose();
        }
      } catch (e) {
        setFormErrorsFromServer(e, form);
      }
    }
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        if (onClose) {
          onClose();
        }
        form.resetFields();
      }}
      title={title}
      footer={(
        <div className="d-flex justify-content-center">
          <Button onClick={form.submit} type="primary">{submitButtonText}</Button>
        </div>
      )}
      size={size}
      destroyOnClose
    >
      <Form
        layout={layout}
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues}
        {...formProps}
      >
        {typeof children === 'function' ? children(form) : children}
        <Form.Item style={{ display: 'none' }}>
          <button type="submit">Save</button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
