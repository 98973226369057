import React from 'react';
import useOrganizationUser from '../../../organizations/hooks/useOrganizationUser';
import checkOrganizationPermissions from '../../utils/checkOrganizationPermissions';
import Permission from '../../utils/Permission';

interface OrganizationPermissionsProps {
  permission: Permission | Permission[];
  children?: () => React.ReactElement;
}

export default function OrganizationPermissions(props: OrganizationPermissionsProps) {
  const { permission, children = null } = props;
  const { isOwner } = useOrganizationUser();

  const hasPermissions = checkOrganizationPermissions(permission, isOwner);

  if (!hasPermissions) {
    return null;
  }

  return children ? children() : null;
}
