// eslint-disable-next-line import/prefer-default-export
import { FeathersError } from '@feathersjs/errors';
import { FormInstance, notification } from 'antd';

/**
 * Get an error message from a server error
 * @param error
 * @param defaultMessage
 */
export function getServerErrorMessage(
  error: unknown,
  defaultMessage: string | null = 'Something went wrong',
): string | null {
  if (error instanceof FeathersError) {
    return error.message;
  }

  return defaultMessage;
}

// eslint-disable-next-line import/prefer-default-export
export function setFormErrorsFromServer(e: unknown, form: FormInstance) {
  if (e instanceof FeathersError) {
    /**
     * Error example:
     * {
          "name": "BadRequest",
          "message": "This slug is already in use",
          "code": 400,
          "className": "bad-request",
          "data": {},
          "errors": {
              "slug": "This slug is already in use"
          }
     * }
     */
    if (e.errors) {
      form.setFields(Object.keys(e.errors).map((field) => {
        const fieldErrors = e.errors[field];
        return { name: field, errors: Array.isArray(fieldErrors) ? fieldErrors : [fieldErrors] };
      }));
    }

    notification.error({
      message: e.message,
      description: 'Please try again',
    });
  } else {
    notification.error({
      message: 'Something went wrong',
      description: 'Please try again',
    });
  }
}
