import {
  Alert, Col, Row, Steps,
} from 'antd';
import { Application, ApplicationResource, Organization } from '@kernex/common';
import { useState } from 'react';
import { Field, FieldTypeType } from '@kernex/core';
import { Link } from 'react-router-dom';
import AppForm from '../AppForm';
import AppTemplateSelect from '../AppTemplateSelect';
import AppTemplate, { AppTemplateResource, AppTemplateResourceField } from '../../definitions/AppTemplate';
import Button from '../../../common/components/Button';
import api from '../../../../api';
import Spin from '../../../common/components/Spin';
import useOrganization from '../../../organizations/hooks/useOrganization';

interface CreateAppFlowProps {
  organizationId: Organization['_id'];
}

async function createField(field: AppTemplateResourceField, resource: ApplicationResource) {
  const { templateId, ...data } = field;

  return api.fields.create({
    ...data,
    resourceId: resource._id,
  });
}

async function createResource(
  resource: AppTemplateResource,
  application: Application,
  createdResources: Record<AppTemplateResource['slug'], ApplicationResource>,
): Promise<ApplicationResource> {
  const { fields, ...data } = resource;

  const appResource = await api.applicationResources.create({
    ...data,
    applicationId: application._id,
  });

  let i = 0;

  const createdFields: Record<AppTemplateResourceField['templateId'], Field> = {};

  while (i < fields.length) {
    const fieldData: AppTemplateResourceField = fields[i];

    // Relate this field to other fields
    if (fieldData.relatedFieldId && createdFields[fieldData.relatedFieldId]) {
      fieldData.relatedFieldId = createdFields[fieldData.relatedFieldId]._id;
    }

    if (fieldData.type === FieldTypeType.RELATION && fieldData.relationResourceId) {
      fieldData.relationResourceId = createdResources[fieldData.relationResourceId]._id;
    }

    // eslint-disable-next-line no-await-in-loop
    const field = await createField(fieldData, appResource);

    createdFields[fieldData.templateId] = field;

    i += 1;
  }

  return appResource;
}

export default function CreateAppFlow(props: CreateAppFlowProps) {
  const { organizationId } = props;
  const organization = useOrganization();
  const [step, setStep] = useState(0);
  const [template, setTemplate] = useState<AppTemplate | null>(null);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [app, setApp] = useState<Application | null>();
  const [templateBuildError, setTemplateBuildError] = useState(false);

  const onNext = () => {
    setStep((prev) => prev + 1);
  };

  const loadTemplate = async (createdApp: Application) => {
    setLoading(true);

    const createdResources: Record<AppTemplateResource['slug'], ApplicationResource> = {};

    if (template) {
      try {
        let i = 0;

        while (i < template.resources.length) {
          const resourceData = template.resources[i];

          // eslint-disable-next-line no-await-in-loop
          const resources = await createResource(resourceData, createdApp, createdResources);
          createdResources[resourceData.slug] = resources;

          i += 1;
        }
      } catch (e) {
        setTemplateBuildError(true);
      }
    }

    setLoading(false);
    setFinished(true);
  };

  const onAppCreated = (createdApp: Application) => {
    setStep((prev) => prev + 1);
    setApp(createdApp);

    if (template) {
      loadTemplate(createdApp).then();
    } else {
      setFinished(true);
    }
  };

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Steps current={step}>
              <Steps.Step title="Start" description="Select a template" />
              <Steps.Step title="Info" description="App info" />
              <Steps.Step title="Finish" description="Finish creating your app" />
            </Steps>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            {
              step === 0 && (
                <>
                  <AppTemplateSelect template={template} onChange={setTemplate} />
                  <h5 className="font-weight-bold mt-4">
                    {
                      template
                        // eslint-disable-next-line max-len
                        ? `We will create all the resources & API's for you. This app will be ready to be used with the ${template.name} template`
                        // eslint-disable-next-line max-len
                        : 'No template selected, you will have a blank app and you\'ll have to create everything from scratch'
                    }
                  </h5>
                </>
              )
            }
            {
              step === 1 && (
                <AppForm organizationId={organizationId} onSuccess={onAppCreated} />
              )
            }
            {
              step === 2 && (
                <Row gutter={[16, 16]}>
                  {
                    app && (
                      <Col span={24}>
                        <Alert
                          message="App created successfully"
                          type="success"
                        />
                      </Col>
                    )
                  }
                  {
                    loading && (
                      <Col span={24}>
                        <div className="d-flex justify-content-center align-items-center">
                          <p className="m-0 mr-2 p-0">Creating your template, please wait</p>
                          <Spin />
                        </div>
                      </Col>
                    )
                  }
                  {
                    templateBuildError && (
                      <Col span={24}>
                        <Alert type="error" message="Error while creating the template" />
                      </Col>
                    )
                  }
                  {
                    app && (!template || finished) && (
                      <Col span={24}>
                        <div className="d-flex justify-content-center">
                          <Link to={`/app/organizations/${organization?.slug}/apps/${app?.slug}`}>
                            <Button type="primary">Go to app</Button>
                          </Link>
                          {
                            template && (
                              <a href={template.url} target="_blank" className="ms-2" rel="noreferrer">
                                <Button type="ghost">
                                  Installation Instructions
                                </Button>
                              </a>
                            )
                          }
                        </div>
                      </Col>
                    )
                  }
                </Row>
              )
            }
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {
            step > 0 && (
              <Col>
                <Button onClick={() => { setStep((prev) => prev - 1); }}>Back</Button>
              </Col>
            )
          }
          {
            step <= 0 && (
              <Col>
                <Button type="primary" onClick={onNext}>Next</Button>
              </Col>
            )
          }
        </Row>
      </Col>
    </Row>
  );
}
