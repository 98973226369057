import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Avatar, Divider, Dropdown, Layout, Menu,
} from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AppUserRole } from '@kernex/common';

import useAuth from '../../authentication/hooks/useAuth';
import Icon from '../../common/components/Icon';
import organizationsAtom from '../../organizations/atoms';
import appsAtom from '../../apps/state';
import { truncate } from '../../../utils';
import { SHOW_IMPORT_RESOURCES, SHOW_PAGES } from '../../../global/constants';
import OrganizationPermissions from '../../permissions/components/OrganizationPermissions';
import Permission from '../../permissions/utils/Permission';
import AppMediaLibraryDrawer from '../../app-media-library/components/AppMediaLibraryDrawer';
import checkAppRole from '../../permissions/utils/checkAppRole';
import useAppUser from '../../apps/hooks/useAppUser';

interface AppLayoutProps {
  children: React.ReactNode;
}

export default function AppLayout(props: AppLayoutProps) {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();

  const [{ organization, apps }] = useRecoilState(organizationsAtom);
  const { app: loadedApp, services: resources, pages } = useRecoilValue(appsAtom);
  const [collapsed, setCollapsed] = useState(false);

  const openMenuItems: string[] = [];

  const { user } = useAppUser();
  const showMenuItems = checkAppRole([AppUserRole.OWNER, AppUserRole.DEVELOPER], user);

  const isDocsPage = loadedApp && location.pathname.includes(`${loadedApp.slug}/documentation`);

  useEffect(() => {
    setCollapsed(Boolean(isDocsPage));
  }, [isDocsPage]);

  function getMenuKey(parts: string[]) {
    return `${organization?.slug}/apps/${loadedApp?.slug}/${parts.join('/')}`;
  }

  function getOrganizationUrl(url: string) {
    return `/app/organizations/${organization?.slug}/${url}`;
  }

  const appUrl = `/app/organizations/${organization?.slug}/apps/${loadedApp?.slug}`;

  function getAppUrl(url: string) {
    return getOrganizationUrl(`apps/${loadedApp?.slug}/${url}`);
  }

  if (loadedApp) {
    openMenuItems.push(getMenuKey(['content']), getMenuKey(['resources']));

    const parts = location.pathname.split('/');
    if (parts[0] === '') {
      parts.shift();
    }
    let path = '';
    parts.forEach((part) => {
      path = path === '' ? part : `${path}/${part}`;
      openMenuItems.push(path);
    });
  }

  const menu = (
    <Menu>
      {
        organization && (
          <Menu.Item key="organization-settings">
            <Link to={`/app/organizations/${organization.slug}`}>
              Organization Settings
            </Link>
          </Menu.Item>
        )
      }
      <Menu.Item key="app">
        <Link to="/">
          Your Organizations
        </Link>
      </Menu.Item>
      <Menu.Item key="account">
        <Link to="/app/account">
          Your Account
        </Link>
      </Menu.Item>
      <Menu.Item onClick={auth.logout} key="sign-out">Sign Out</Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Layout.Sider
        collapsible
        width={250}
        theme="light"
        style={{
          overflowX: 'auto',
          height: '100vh',
        }}
        className="ant-sidebar app-sidebar"
        collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div className="h-100 d-flex flex-column">
            <div>
              <div className="p-2">
                <Dropdown overlay={menu}>
                  <div className="d-flex align-items-center">
                    <Avatar
                      shape="square"
                      size={40}
                    >
                      {(organization?.name || auth.user?.name || 'U')[0].toUpperCase()}
                    </Avatar>
                    <div className="ms-2">
                      <h5 className="m-0">
                        {truncate(organization?.name || auth.user?.name, 10)}
                      </h5>
                      <p className="m-0">{truncate(auth.user?.email, 14)}</p>
                    </div>
                    <Icon size={12} name="down" className="ms-2" />
                  </div>
                </Dropdown>
              </div>
              <Divider className="m-0" />
            </div>
            <div className="flex-grow-1 overflow-auto">
              {
                loadedApp && (
                  <Menu
                    mode="inline"
                    defaultOpenKeys={openMenuItems}
                    defaultSelectedKeys={openMenuItems}
                  >
                    <Menu.ItemGroup title={loadedApp.name}>
                      <Menu.SubMenu
                        title="Content"
                        icon={<Icon name="content" />}
                        key={getMenuKey(['content'])}
                      >
                        {
                          resources.map((resource) => (
                            <Menu.Item
                              title={resource.name}
                              key={getMenuKey(['content', resource.slug])}
                            >
                              <Link to={getAppUrl(`content/${resource.slug}`)}>
                                {resource.name}
                              </Link>
                            </Menu.Item>
                          ))
                        }
                        {
                          resources.length === 0 && (
                            <Menu.Item key="admin-no-resource-message">
                              You have no resources
                            </Menu.Item>
                          )
                        }
                      </Menu.SubMenu>
                      {
                        showMenuItems && (
                          <Menu.SubMenu
                            title="Resources"
                            icon={<Icon name="resource" />}
                            key={getMenuKey(['resources'])}
                          >
                              {
                                resources.map((resource) => (
                                  <Menu.Item
                                    title={resource.name}
                                    key={getMenuKey(['resources', resource.slug])}
                                  >
                                    <Link to={getAppUrl(`resources/${resource.slug}`)}>
                                      {resource.name}
                                    </Link>
                                  </Menu.Item>
                                ))
                              }
                            <Menu.Item
                              title="+ Create Resource"
                              key={getMenuKey(['resources', 'new'])}
                              icon={<Icon name="plus" />}
                            >
                              <Link to={getAppUrl('resources/new')}>
                                Create Resource
                              </Link>
                            </Menu.Item>
                              {
                                SHOW_IMPORT_RESOURCES && (
                                  <Menu.Item
                                    title="+ Import Resource"
                                    key={getMenuKey(['resources', 'import'])}
                                    icon={<Icon name="import" />}
                                  >
                                    <Link to={getAppUrl('resources/import')}>
                                      Import Resources
                                    </Link>
                                  </Menu.Item>
                                )
                              }
                          </Menu.SubMenu>
                        )
                      }
                      <AppMediaLibraryDrawer
                        key="app-media-library-drawer"
                        renderTrigger={(onOpen) => (
                          <Menu.Item
                            title="Media Library"
                            key={getMenuKey(['media-library'])}
                            icon={<Icon name="picture" />}
                            onClick={onOpen}
                          >
                            Media Library
                          </Menu.Item>
                        )}
                      />
                      <Menu.Item
                        title="Actions"
                        key={getMenuKey(['actions'])}
                        icon={<Icon name="action" />}
                      >
                        <Link to={getAppUrl('actions')}>
                          Actions
                        </Link>
                      </Menu.Item>
                      {
                        showMenuItems && (
                          <>
                            <Menu.Item
                              title="API Documentation"
                              key={getMenuKey(['api-documentation'])}
                              icon={<Icon name="api" />}
                            >
                              <Link to={getAppUrl('documentation')}>
                                API Docs
                              </Link>
                            </Menu.Item>
                            <Menu.Item
                              title="Settings"
                              key={getMenuKey(['settings'])}
                              icon={<Icon name="setting" />}
                            >
                              <Link to={appUrl}>
                                Settings
                              </Link>
                            </Menu.Item>
                          </>
                        )
                      }
                      {
                        SHOW_PAGES && (
                          <Menu.SubMenu
                            title="Pages"
                            icon={<Icon name="web" />}
                            key={getMenuKey(['pages'])}
                          >
                            {
                              pages.map((page) => (
                                <Menu.Item title={page.name} key={getMenuKey(['pages', page.slug])}>
                                  <Link to={getAppUrl(`pages/${page._id}`)}>
                                    {page.name}
                                  </Link>
                                </Menu.Item>
                              ))
                            }
                            <Menu.Item title="+ Create Page" key={getMenuKey(['pages', 'new'])}>
                              <Link to={getAppUrl('pages/new')}>
                                + Create Page
                              </Link>
                            </Menu.Item>
                          </Menu.SubMenu>
                        )
                      }
                    </Menu.ItemGroup>
                    <Menu.Item key="divider">
                      <Divider />
                    </Menu.Item>
                  </Menu>
                )
              }
              {
                organization && (
                  <Menu
                    mode="inline"
                    defaultOpenKeys={openMenuItems}
                    defaultSelectedKeys={openMenuItems}
                  >
                    <Menu.ItemGroup title={(
                      <span>
                        <Icon name="app" />
                        {' '}
                        <span style={{ marginLeft: 5 }}>APPS</span>
                      </span>
                    )}
                    >
                      {
                        apps.map((app) => (
                          <Menu.Item
                            title={app.name}
                            key={[organization?.slug, 'apps', app.slug].join('/')}
                            icon={<Icon name="app" />}
                          >
                            <Link to={getOrganizationUrl(`apps/${app.slug}`)}>
                              {app.name}
                            </Link>
                          </Menu.Item>
                        ))
                      }
                      <OrganizationPermissions permission={Permission.APP_CREATE}>
                        {
                          () => (
                            <Menu.Item
                              title="+ Create App"
                              key="create-app"
                              icon={<Icon name="createApp" />}
                            >
                              <Link to={getOrganizationUrl('apps/new')}>
                                + Create App
                              </Link>
                            </Menu.Item>
                          )
                        }
                      </OrganizationPermissions>
                    </Menu.ItemGroup>
                  </Menu>
                )
              }
            </div>
          </div>
        </div>
      </Layout.Sider>
      <Layout>
        <Layout.Content className="page-content" style={{ height: '100vh', overflow: 'auto' }}>
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
