import {
  Form, FormInstance, Input, Modal, ModalProps,
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Organization } from '@kernex/common';
import api from '../../../../api';
import { setFormErrorsFromServer } from '../../../common/utils/errors';
import Button from '../../../common/components/Button';

interface OrganizationFormProps {
  form: FormInstance<Pick<Organization, 'name' | 'slug'>>;
  onFinish: (values: Pick<Organization, 'name' | 'slug'>) => Promise<void>;
  showSubmitButton?: boolean;
  loading?: boolean;
  organization?: Organization | null;
}

export default function OrganizationForm(props: OrganizationFormProps) {
  const {
    form, onFinish, showSubmitButton, loading, organization,
  } = props;

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} initialValues={organization || {}}>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: 'Please input the organization name!' },
          { min: 3, message: 'Name must be at least 3 characters long!' },
          { max: 50, message: 'Name must be at most 50 characters long!' },
        ]}
      >
        <Input placeholder="Organization Name" />
      </Form.Item>

      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          { required: true, message: 'Please input the organization slug!' },
          { min: 3, message: 'Slug must be at least 3 characters long!' },
          { max: 50, message: 'Slug must be at most 50 characters long!' },
          {
            pattern: /^[a-z0-9][a-z0-9-]*[a-z0-9]$/,
            message: 'Only alphanumeric characters and dashes are allowed',
          },
        ]}
      >
        <Input placeholder="Organization Slug" disabled={Boolean(organization)} />
      </Form.Item>

      <Form.Item
        style={{ display: showSubmitButton ? 'block' : 'none' }}
      >
        <div className="d-flex flex-row justify-content-center">
          <Button htmlType="submit" loading={loading}>Save</Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export function CreateOrganizationModal(props: ModalProps) {
  const [form] = Form.useForm<Pick<Organization, 'name' | 'slug'>>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: Pick<Organization, 'name' | 'slug'>) => {
    setLoading(true);
    try {
      const organization = await api.app.service('organizations').create(values);
      navigate(`organizations/${organization.slug}`);
    } catch (e) {
      setFormErrorsFromServer(e, form);
    }
    setLoading(false);
  };

  return (
    <Modal
      title="Create Organization"
      onOk={form.submit}
      okText="Create Organization"
      okButtonProps={{
        loading,
      }}
      {...props}
    >
      <OrganizationForm form={form} onFinish={onFinish} />
    </Modal>
  );
}
