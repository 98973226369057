import {
  Col, Row, Tag,
} from 'antd';
import { ApplicationResource } from '@kernex/common';
import { API_URL } from '../../../../global/constants';

interface AppResourceGeneralInfoProps {
  service: ApplicationResource;
}

export default function AppResourceGeneralInfo(props: AppResourceGeneralInfoProps) {
  const { service } = props;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <p>{`Name: ${service.name}`}</p>
        <p>{`Slug: ${service.slug}`}</p>
        <p className="d-flex">
          REST Endpoint URL:
          <Tag color="red" className="ms-2">
            {`${API_URL}/api/v1/${service.applicationId}/resource/${service.slug}`}
          </Tag>
        </p>
      </Col>
    </Row>
  );
}
