import { User } from '@kernex/common';
import { CustomMethods, Service } from '@feathersjs/feathers';
import ServiceAPI from '../common/ServiceAPI';

type VerifyUser = {
  token: string;
};

type RequestPasswordReset = {
  email: string;
};

type ResetPassword = {
  token: string;
  password: string;
};

type ChangePassword = {
  password: string;
  currentPassword: string;
};

type StackAppSumoCodes = {
  organizationId: string;
  codes: string[];
};

export type UserServiceCustomMethods = CustomMethods<{
  verify: [VerifyUser, unknown];
  requestPasswordReset: [RequestPasswordReset, unknown];
  resetPassword: [ResetPassword, unknown];
  changePassword: [ChangePassword, unknown];
  stackAppSumoCodes: [StackAppSumoCodes, unknown];
}>;

export default class Users extends ServiceAPI<User, Partial<User>, UserServiceCustomMethods> {
  constructor(service: Service<User> & UserServiceCustomMethods) {
    super(service);
    // @ts-ignore
    this.service.methods('verify');
    // @ts-ignore
    this.service.methods('requestPasswordReset');
    // @ts-ignore
    this.service.methods('resetPassword');
    // @ts-ignore
    this.service.methods('changePassword');
    // @ts-ignore
    this.service.methods('stackAppSumoCodes');
  }

  /**
   * Verify user email
   * @param data
   */
  async verify(data: VerifyUser) {
    return this.service.verify(data);
  }

  /**
   * Send a forgot password request
   * @param data
   */
  async requestPasswordReset(data: RequestPasswordReset) {
    return this.service.requestPasswordReset(data);
  }

  /**
   * Reset user password
   * @param data
   */
  async resetPassword(data: ResetPassword) {
    return this.service.resetPassword(data);
  }

  /**
   * Change user password
   * @param data
   * @param data.password - new password
   * @param data.currentPassword - current password
   */
  async changePassword(data: ChangePassword) {
    return this.service.changePassword(data);
  }

  async stackAppSumoCodes(data: StackAppSumoCodes) {
    return this.service.stackAppSumoCodes(data);
  }
}
