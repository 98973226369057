import {
  AppAction, AppActionData, AppActionType, Application, HttpMethod,
} from '@kernex/common';
import {
  Col,
  Divider, Form, Input, Row, Select,
} from 'antd';
import { Rule } from 'rc-field-form/es/interface';
import DrawerForm, { DrawerFormProps } from '../../../common/components/DrawerForm';
import api from '../../../../api';
import JsonInput from '../../../common/components/JsonInput';

interface AppActionDrawerFormProps extends Omit<DrawerFormProps<AppActionData>, 'children'> {
  appId: Application['_id'];
  action?: AppAction;
  onSuccess?: (action: AppAction) => void;
}

const initialValues: Partial<AppActionData> = {
  type: AppActionType.REQUEST,
};

const jsonRule: Rule = {
  message: 'This is not a valid JSON object',
  validator: (rule, value) => {
    if (value) {
      try {
        const parsed = JSON.parse(value);

        if (typeof parsed !== 'object') {
          return Promise.reject();
        }
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    }

    return Promise.resolve();
  },
};

export default function AppActionDrawerForm(props: AppActionDrawerFormProps) {
  const {
    appId, action, onSuccess, ...drawerFormProps
  } = props;

  const onFinish = async (values: AppActionData) => {
    const data: AppActionData = {
      ...values,
      config: {
        ...values.config,
      },
    };

    if (values.config.data) {
      data.config.data = JSON.parse(String(values.config.data));
    }
    if (values.config.headers) {
      data.config.headers = JSON.parse(String(values.config.headers));
    }

    const response = await api.appActions.createOrPatch({
      ...data,
      appId,
    }, action?._id);

    if (onSuccess) {
      onSuccess(response);
    }
  };

  return (
    <DrawerForm
      title={action ? 'Edit Action' : 'Add Action'}
      submitButtonText={action ? 'Save' : 'Add Action'}
      initialValues={{
        ...initialValues,
        ...(action || {}),
      }}
      size="large"
      onFinish={onFinish}
      {...drawerFormProps}
    >
      {
        (form) => (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="type" label="Action Type" rules={[{ required: true }]}>
                <Select placeholder="Select an action type">
                  <Select.Option key={AppActionType.REQUEST}>Request</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Action Name"
                extra="For informational purposes"
                rules={[{ required: true, message: 'Please enter app name' }]}
              >
                <Input placeholder="Action name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="description"
                label="Action Description"
                rules={[{ required: true, message: 'Please enter app description' }]}
              >
                <Input placeholder="Action description" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="buttonText"
                label="Button Text"
                extra="The text of the button that will trigger this action"
                rules={[{ required: true, message: 'Please enter button text' }]}
              >
                <Input placeholder="Button Text" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item noStyle shouldUpdate>
                {
                  () => {
                    const type = form.getFieldValue('type');

                    if (type === AppActionType.REQUEST) {
                      return (
                        <>
                          <Divider>Request Config</Divider>
                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <Form.Item
                                name={['config', 'url']}
                                label="Request URL"
                                extra="The url that will be called"
                                rules={[{ required: true, message: 'Please enter request url' }]}
                              >
                                <Input placeholder="Request URL" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="Request Method"
                                name={['config', 'method']}
                                extra="HTTP Method that will be used to make the request"
                                rules={[{ required: true, message: 'Please enter request method' }]}
                              >
                                <Select placeholder="Request Method">
                                  {
                                    Object.values(HttpMethod).map((method) => (
                                      <Select.Option key={method}>{method}</Select.Option>
                                    ))
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label="Data"
                                name={['config', 'data']}
                                extra="JSON object"
                                rules={[jsonRule]}
                              >
                                <JsonInput rows={5} />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label="Headers"
                                name={['config', 'headers']}
                                extra="JSON object"
                                rules={[jsonRule]}
                              >
                                <JsonInput rows={5} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      );
                    }

                    return null;
                  }
                }
              </Form.Item>
            </Col>
          </Row>
        )
      }
    </DrawerForm>
  );
}
