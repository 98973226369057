import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import { GenerateConfig } from 'rc-picker/lib/generate';

const config: GenerateConfig<Date> = {
  ...dateFnsGenerateConfig,
  locale: {
    ...dateFnsGenerateConfig.locale,
    getWeekFirstDay: () => 1, // start weeks from Monday
  },
};

const DatePicker = generatePicker<Date>(config);

export default DatePicker;
