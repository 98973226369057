import { CustomMethods, Service } from '@feathersjs/feathers';
import { ApplicationResource, ApplicationResourceData } from '@kernex/common';
import { Field } from '@kernex/core';
import ServiceAPI from '../common/ServiceAPI';

export interface ImportData {
  connectionString: string;
}

export type ImportResponseEntry = {
  slug: string;
  fields: Pick<Field, 'name' | 'type' | 'required'>[];
};

export type ApplicationResourceServiceCustomMethods = CustomMethods<{
  import: [ImportData, any];
}>;

export default class ApplicationResourceService
  extends ServiceAPI<ApplicationResource, ApplicationResourceData, ApplicationResourceServiceCustomMethods> {
  constructor(service: Service<ApplicationResource> & ApplicationResourceServiceCustomMethods) {
    super(service);
    // @ts-ignore
    this.service.methods('import');
  }

  async import(data: ImportData): Promise<ImportResponseEntry[]> {
    return this.service.import(data);
  }
}
