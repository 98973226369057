import { Application } from '@kernex/common';
import {
  Card, Col, Progress, Row,
} from 'antd';
import useOrganizationPlan from '../../../organizations/hooks/useOrganizationPlan';
import { formatNumber } from '../../../../utils/numbers';
import useAppUsage from '../../hooks/useAppUsage';

interface AppUsageProps {
  app: Application;
}

export default function AppUsage(props: AppUsageProps) {
  const { app } = props;
  const { plan } = useOrganizationPlan();
  const {
    recordsPercent, recordsLimitClose, usersPercent, usersLimitClose, totalUsers,
  } = useAppUsage(app, plan);

  return (
    <Row gutter={[16, 16]}>
      <Col span={8} lg={6}>
        <Card>
          <h5 className="mb-3">Records</h5>
          <div>
            {
              plan?.maxRecordsPerApp ? (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    <Progress
                      percent={recordsPercent}
                      type="circle"
                      width={90}
                      status={recordsLimitClose ? 'exception' : 'normal'}
                      format={((percent) => `${formatNumber(percent || 0)}%`)}
                    />
                  </div>
                  <p className="text-center mt-3 mb-0">
                    {String(app.usage.recordsCount || 0)}
                    {' '}
                    out of
                    {' '}
                    {formatNumber(plan.maxRecordsPerApp)}
                    {' '}
                    records
                  </p>
                </>
              ) : (
                <p className="text-center">You can add unlimited records</p>
              )
            }
          </div>
        </Card>
      </Col>
      <Col span={8} lg={6}>
        <Card>
          <h5 className="mb-3">Users</h5>
          <div>
            {
              plan?.maxUsersPerApp ? (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    <Progress
                      percent={usersPercent}
                      type="circle"
                      width={90}
                      status={usersLimitClose ? 'exception' : 'normal'}
                      format={((percent) => `${formatNumber(percent || 0)}%`)}
                    />
                  </div>
                  <p className="text-center mt-3 mb-0">
                    {String(totalUsers)}
                    {' '}
                    out of
                    {' '}
                    {formatNumber(plan.maxUsersPerApp)}
                    {' '}
                    users
                  </p>
                </>
              ) : (
                <p className="text-center">You can add unlimited users</p>
              )
            }
          </div>
        </Card>
      </Col>
    </Row>
  );
}
