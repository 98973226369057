import {
  AppstoreOutlined, GroupOutlined, GlobalOutlined, DeleteOutlined, FontSizeOutlined, BorderOutlined, ContainerOutlined,
  DragOutlined, CopyOutlined, AppstoreAddOutlined, SolutionOutlined, PlusOutlined, ImportOutlined, LinkOutlined,
  UnorderedListOutlined, DownOutlined, SaveOutlined, BlockOutlined, EditOutlined, HddOutlined, FormOutlined,
  AimOutlined, LockOutlined, PictureOutlined, CheckOutlined, CloseOutlined, SettingOutlined, ApiOutlined,
} from '@ant-design/icons';

const icons = {
  app: AppstoreOutlined,
  group: GroupOutlined,
  web: GlobalOutlined,
  delete: DeleteOutlined,
  text: FontSizeOutlined,
  button: BorderOutlined,
  container: ContainerOutlined,
  drag: DragOutlined,
  copy: CopyOutlined,
  createApp: AppstoreAddOutlined,
  admin: SolutionOutlined,
  plus: PlusOutlined,
  import: ImportOutlined,
  link: LinkOutlined,
  unorderedList: UnorderedListOutlined,
  down: DownOutlined,
  save: SaveOutlined,
  block: BlockOutlined,
  edit: EditOutlined,
  resource: HddOutlined,
  content: FormOutlined,
  action: AimOutlined,
  lock: LockOutlined,
  picture: PictureOutlined,
  checkOutlined: CheckOutlined,
  closeOutlined: CloseOutlined,
  setting: SettingOutlined,
  api: ApiOutlined,
} as const;

type IconName = keyof typeof icons;

export interface IconProps {
  name: IconName;
  color?: string;
  size?: number;
  className?: string;
}

export default function Icon(props: IconProps) {
  const {
    name, color, size = 18, ...rest
  } = props;
  const Component = icons[name];
  const style = { fontSize: size, color };

  if (Component) {
    return <Component {...rest} style={style} />;
  }

  return null;
}
