import { format } from 'date-fns';

export type DateLike = Date | string;

export function forceDate(date: DateLike) {
  return date instanceof Date ? date : new Date(date);
}

export function formatDate(date: DateLike) {
  try {
    return format(forceDate(date), 'dd-MM-yyyy');
  } catch {
    return 'Invalid Date';
  }
}
