import React from 'react';
import ResourceListBlock from '../components/AppPageBuilderNew/blocks/ResourceListBlock';
import UserComponentBlock from '../components/AppPageBuilderNew/blocks/UserComponentBlock';
import baseBlockResolver from './baseBlockResolver';

const blockResolver: Record<string, React.ComponentType<any>> = {
  ...baseBlockResolver,
  ResourceListBlock,
  UserComponentBlock,
};

export default blockResolver;
