import { ReactNode } from 'react';
import { Col, Row } from 'antd';
import SelectBox from '../SelectBox';

type Option<T = unknown> = {
  label: ReactNode;
  value: T;
};

interface SelectBoxInputProps<T = unknown> {
  options: Option<T>[];
  value?: T;
  onChange?: (value: T) => void;
}

export default function SelectBoxInput<T = unknown>(props: SelectBoxInputProps<T>) {
  const {
    options, value, onChange,
  } = props;

  return (
    <Row gutter={[16, 16]}>
      {
        options.map((option) => (
          <Col span={24} lg={12} key={String(option.value)}>
            <SelectBox selected={option.value === value} onClick={() => onChange && onChange(option.value)}>
              {option.label}
            </SelectBox>
          </Col>
        ))
      }
    </Row>
  );
}
