import { useParams, Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { Spin } from 'antd';

import api from '../../../../api';
import appsAtom from '../../state';
import useAuth from '../../../authentication/hooks/useAuth';
import AppUsageNotice from '../../components/AppUsageNotice';

export default function AppPage() {
  const { user } = useAuth();
  const { appSlug } = useParams<{ appSlug: string }>();
  const [{ loading, app }, setAppsState] = useRecoilState(appsAtom);

  const getApplication = async () => {
    setAppsState((prevState) => ({ ...prevState, loading: true, appUser: null }));

    if (user?._id) {
      const response = await api.applications.find({
        query: {
          slug: appSlug,
          $limit: 1,
        },
      });

      const [newApp] = response.data;

      if (newApp) {
        const appUser = await api.appUsers.findOne({
          query: {
            userId: user._id,
            appId: newApp._id,
          },
        });

        setAppsState((prevState) => ({ ...prevState, app: newApp, appUser }));
      }

      setAppsState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  useEffect(() => {
    getApplication().then();
  }, [appSlug, user?._id]);

  if (loading) {
    return (
      <div className="h-100 v-100 d-flex align-items-center justify-content-center">
        <Spin size="large" />
      </div>
    );
  }

  if (!app) {
    return (
      <div className="h-100 v-100 d-flex align-items-center justify-content-center">
        <h1>App not found</h1>
      </div>
    );
  }

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppUsageNotice app={app} closable />
      <div style={{ flex: 1, overflow: 'auto' }}>
        <Outlet />
      </div>
    </div>
  );
}
