import { Select, SelectProps } from 'antd';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import debounce from 'just-debounce';
import { ApplicationResource } from '@kernex/common';
import api from '../../../../api';
import ApplicationResourceInstance from '../../../../api/models/ApplicationResourceInstance';

interface AppResourceEntrySelectProps extends Omit<SelectProps, 'onChange'> {
  resourceId: string;
  onChange?: (value: any) => void;
}

export default function AppResourceEntrySelect(props: AppResourceEntrySelectProps) {
  const {
    resourceId, value, onChange, ...rest
  } = props;
  const [resource, setResource] = useState<ApplicationResource | null>(null);
  const [entries, setEntries] = useState<ApplicationResourceInstance[]>([]);
  const [currentValueEntries, setCurrentValueEntries] = useState<ApplicationResourceInstance[]>([]);
  const [search, setSearch] = useState('');
  const [requiredFieldsCount, setRequiredFieldsCount] = useState(0);

  const { slug, applicationId, titleFieldName = '_id' } = resource || {};

  const loadResource = useCallback(async () => {
    const response = await api.applicationResources.get(resourceId, {
      query: {
        $select: ['slug', 'applicationId', 'titleFieldName'],
      },
    });
    setResource(response);

    const countResponse = await api.fields.find({
      query: {
        $limit: -1,
        resourceId,
        name: {
          $ne: response.titleFieldName,
        },
        autogenerated: {
          $ne: true,
        },
        required: true,
      },
    });
    setRequiredFieldsCount(countResponse.total);
  }, [resourceId]);

  const loadEntries = useCallback(async () => {
    if (slug && applicationId) {
      const query: Record<string, any> = {
        $select: ['_id', titleFieldName],
        $limit: 100, // TODO: add infinite loading
      };

      if (search && titleFieldName) {
        query[titleFieldName] = { $search: search };
      }
      const response = await api.appResourceInstance(applicationId, slug).find({ query });
      setEntries(response.data);
    }
  }, [slug, applicationId, titleFieldName, search]);

  const loadCurrentEntries = useCallback(async () => {
    if (value && slug && applicationId) {
      const query: Record<string, any> = {
        _id: {
          $in: Array.isArray(value) ? value : [value],
          $nin: entries.length > 0 ? entries.map((entry) => entry._id) : undefined,
        },
        $select: ['_id', titleFieldName],
        $limit: Array.isArray(value) ? value.length : 1,
      };

      const response = await api.appResourceInstance(applicationId, slug).find({ query });
      setCurrentValueEntries(response.data);
    }
  }, [slug, applicationId, titleFieldName, value, entries]);

  useEffect(() => {
    loadResource().then();
  }, [loadResource]);

  useEffect(() => {
    loadCurrentEntries().then();
  }, [loadCurrentEntries]);

  useEffect(() => {
    loadEntries().then();
  }, [loadEntries, search]);

  const onSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 500);

  const options = [...entries, ...currentValueEntries].map((entry) => ({
    label: String(entry[titleFieldName]),
    value: String(entry._id),
  }));

  const optionExists = options.find(({ label }) => label === search);

  if (search && !optionExists && requiredFieldsCount === 0) {
    options.unshift({ label: `+ Create entry "${search}"`, value: '' });
  }

  const createItem = async (res: ApplicationResource) => {
    const entry = await api.appResourceInstance(res.applicationId, res.slug).create({
      [titleFieldName]: search,
    });
    setEntries((prevState) => {
      const exists = prevState.find(({ _id }) => _id === entry._id);

      if (!exists) {
        return [entry, ...prevState];
      }

      return prevState;
    });
    return entry;
  };

  const change = (newValue: any) => {
    if (onChange) {
      onChange(newValue);
      setSearch('');
    }
  };

  const onSelectChange = (newValue: string | string[] | null) => {
    if (!Array.isArray(newValue)) {
      if (!newValue && resource) {
        // Value not found, create new entry and set it as value
        createItem(resource).then((entry) => {
          change(entry._id);
        }).catch(() => {
          change(newValue);
        });
      } else {
        change(newValue);
      }
    } else if (Array.isArray(newValue)) {
      const hasUnknownValue = newValue.some((v) => !v);

      if (hasUnknownValue && resource) {
        createItem(resource).then((entry) => {
          change(newValue.map((v) => v || entry._id));
        }).catch(() => {
          change(newValue);
        });
      } else {
        change(newValue);
      }
    } else {
      change(newValue);
    }
  };

  return (
    <Select
      options={options}
      onSearch={onSearch}
      filterOption={false}
      onSelect={() => {
        setSearch('');
      }}
      onBlur={() => {
        setSearch('');
      }}
      value={value}
      onChange={onSelectChange}
      showSearch
      {...rest}
    />
  );
}
