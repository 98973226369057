import {
  Card, Checkbox, Col, Form, message, notification, Row,
} from 'antd';
import { useState } from 'react';
import { Field } from '@kernex/core';

import api from '../../../../api';
import Button from '../../../common/components/Button';

interface AppResourceFieldAdminListSettingsProps {
  field: Field;
  onChange: (field: Field) => void;
}

export default function AppResourceFieldAdminListSettings(props: AppResourceFieldAdminListSettingsProps) {
  const { field, onChange } = props;
  const [loading, setLoading] = useState(false);

  const { adminListSettings = { hide: false } } = field;

  const onFinish = async (values: Field['adminListSettings']) => {
    setLoading(true);

    try {
      const response = await api.fields.patch(
        field._id,
        { adminListSettings: { ...adminListSettings, ...values } },
      );
      onChange(response);
      message.success('Field settings updated');
    } catch (e) {
      notification.error({ message: 'Error updating field settings' });
    }

    setLoading(false);
  };

  return (
    <Form
      initialValues={adminListSettings}
      onFinish={onFinish}
    >
      <Card size="small">
        <Row>
          <Col span={24} md={8} className="d-flex align-items-center">
            <p className="m-0">{field.displayName}</p>
          </Col>
          <Col span={24} md={8} className="d-flex justify-content-center">
            <Form.Item name="hide" valuePropName="checked" className="m-0">
              <Checkbox>Hide</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24} md={8} className="d-flex justify-content-end">
            <Form.Item className="m-0">
              <Button size="small" loading={loading} htmlType="submit">Save</Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
}
