import React from 'react';
import { Col, Row } from 'antd';
import Page from '../../layout/Page';
import styles from './index.module.css';
import logoLongBlack from '../../../assets/img/logo-long-black.png';

interface AuthLayoutProps {
  children: React.ReactNode;
}

export default function AuthLayout(props: AuthLayoutProps) {
  const { children } = props;

  return (
    <Page guestsOnly>
      <Row className="h-100" align="middle" justify="center">
        <Col
          xxl={6}
          xl={8}
          lg={10}
          md={12}
          sm={24}
        >
          <div className="d-flex justify-content-center mb-5">
            <img src={logoLongBlack} alt="logo" className={styles.logo} />
          </div>
          {children}
        </Col>
      </Row>
    </Page>
  );
}
