import { ReactNode } from 'react';

interface AppDocsPageProps {
  title: string;
  id?: string;
  children?: ReactNode | ReactNode[];
}

export default function AppDocsPage(props: AppDocsPageProps) {
  const { title, id, children } = props;

  return (
    <div className="d-flex flex-column">
      <h3 className="mb-4" id={id}>{title}</h3>
      {children}
      <div style={{ padding: '60px 0' }}><div style={{ borderBottom: '1px solid #e8e8e8' }} /></div>
    </div>
  );
}
