export function formatNumber(value: number) {
  return value.toLocaleString();
}

export function shortFormatNumber(count: number) {
  if (count >= 1000 && count < 1000000) {
    return `${Math.round(count / 1000)}K`;
  }

  if (count >= 1000000 && count < 1000000000) {
    return `${Math.round(count / 1000000)}M`;
  }

  if (count >= 1000000000) {
    return `${Math.round(count / 1000000000)}B`;
  }

  return count;
}
