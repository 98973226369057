import {
  Row, Col, Spin, Layout, Card, Avatar,
} from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Organization } from '@kernex/common';

import api from '../../../../api';
import Button from '../../../common/components/Button';
import { CreateOrganizationModal } from './OrganizationForm';
import useAuth from '../../../authentication/hooks/useAuth';

export default function OrganizationsPage() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const loadOrganizations = async () => {
    const response = await api.organizations.find({
      query: {
        users: auth.user?._id,
      },
    });
    setOrganizations(response.data);
    setLoading(false);
  };

  useEffect(() => {
    loadOrganizations().then();
  }, []);

  return (
    <Layout className="h-100 w-100">
      <Layout.Content className="p-5">
        {
          loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <Spin />
            </div>
          ) : (
            <>
              {
                organizations && organizations.length === 0 && (
                  <Row justify="center">
                    <Col>
                      <h2>You have no organizations. Start by creating one.</h2>
                    </Col>
                  </Row>
                )
              }
              <Row justify="center" gutter={[16, 16]}>
                {
                  organizations?.map((organization) => (
                    <Col span={24} sm={12} md={8} lg={6} xl={4} key={organization._id}>
                      <Link to={`organizations/${organization.slug}`}>
                        <Card className="h-100">
                          <div className="d-flex flex-column align-items-center">
                            <Avatar shape="square" size={50}>
                              {organization.name[0].toUpperCase()}
                            </Avatar>
                            <h4 className="m-0 text-center mt-3">
                              {organization.name}
                            </h4>
                          </div>
                        </Card>
                      </Link>
                    </Col>
                  ))
                }
              </Row>
              <Row justify="center" gutter={[32, 32]} className="mt-5 pt-5">
                <Col span={24} md={14} xl={8} xxl={4}>
                  <Card>
                    <h3 className="text-center">Create Organization</h3>
                    <Button
                      type="primary"
                      onClick={() => {
                        setCreateModalOpen(true);
                      }}
                      block
                    >
                      Create
                    </Button>
                  </Card>
                </Col>
              </Row>
            </>
          )
        }
      </Layout.Content>
      <CreateOrganizationModal
        open={createModalOpen}
        onCancel={() => {
          setCreateModalOpen(false);
        }}
      />
    </Layout>
  );
}
