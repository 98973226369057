/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { AnchorHTMLAttributes, HTMLAttributes } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import ContentEditable from 'react-contenteditable';
import BlockProps, { BlockPropType, BlockPropTypeType } from '../../components/BlockProps';

export function LinkBlockPreview(props: AnchorHTMLAttributes<HTMLAnchorElement> & { text: string }) {
  const { text, ...linkProps } = props;

  return (
    <a {...linkProps}>{text}</a>
  );
}

interface LinkBlockProps extends HTMLAttributes<HTMLAnchorElement> {
  text: string;
}

export default function LinkBlock(props: LinkBlockProps) {
  const { text, ...rest } = props;

  const {
    connectors: { connect },
    actions,
  } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  // @ts-ignore
  return (
    <ContentEditable
      innerRef={connect}
      html={text}
      disabled={!enabled}
      onChange={(e) => {
        actions.setProp((prop: { text: string }) => {
          // eslint-disable-next-line no-param-reassign
          prop.text = e.target.value;
        }, 500);
      }}
      tagName="a" // Use a custom HTML tag (uses a div by default)
      /*
      // @ts-ignore */
      onClick={(e) => {
        e.preventDefault();
      }}
      {...rest}
    />
  );
}

const linkPropTypes: BlockPropType[] = [
  {
    label: 'URL',
    name: 'href',
    type: BlockPropTypeType.STRING,
    placeholder: 'www.example.com',
  },
  {
    type: BlockPropTypeType.SELECT,
    label: 'Open in',
    options: [{ value: '_self', label: 'Same window' }, { value: '_blank', label: 'New window' }],
    name: 'target',
  },
];

LinkBlock.craft = {
  name: 'Link',
  related: {
    propsToolbar: () => <BlockProps blockPropsTypes={linkPropTypes} />,
  },
  props: {
    style: { padding: 10, display: 'block' },
  },
  custom: {
    styleSettings: {
      padding: {
        value: 10,
        unit: 'px',
      },
      display: 'block',
    },
  },
};
