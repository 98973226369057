import io from 'socket.io-client';
import feathers, { Application, Service } from '@feathersjs/feathers';
import socketIO from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';
import {
  Application as App,
  ApplicationResource,
  User,
  ApplicationPage,
  AppUpload,
  AppUploadData,
  AppUser,
  AppUserData,
  Organization,
  AppIntegration,
  AppIntegrationData,
  AppAction,
  AppActionData, Subscription, SubscriptionData,
} from '@kernex/common';
import {
  Field,
  FieldData,
} from '@kernex/core';

import ServiceAPI from './common/ServiceAPI';
import ApplicationResourceInstance, { ApplicationResourceInstanceData } from './models/ApplicationResourceInstance';
import ApplicationResourceService, {
  ApplicationResourceServiceCustomMethods,
} from './services/ApplicationResourceService';
import ApplicationResourceSettings from './models/ApplicationResourceSettings';
import NodeComponent from './models/NodeComponent';
import AppApiKey from './models/AppApiKey';
import Users, { UserServiceCustomMethods } from './services/Users';
import { API_URL } from '../global/constants';
import AppUploads from './services/AppUploads';
import AppActions, { AppActionsCustomMethods } from './services/AppActions';
import Subscriptions from './services/Subscriptions';

const socket = io(API_URL);
// @ts-ignore
const client = feathers();
client.configure(socketIO(socket));
client.configure(authentication({
  storage: window.localStorage,
}));

type Services = {
  'users': Service<User> & UserServiceCustomMethods;
  'organizations': Service<Organization>;
  'applications': Service<App>;
  'application-resources': Service<ApplicationResource> & ApplicationResourceServiceCustomMethods;
  'application-resource-fields': Service<Field>;
  'application-pages': Service<ApplicationPage>;
  'application-resource-settings': Service<ApplicationResourceSettings>;
  'node-components': Service<NodeComponent>;
  'app-api-keys': Service<AppApiKey>;
  'app-users': Service<AppUser, AppUserData>;
  'app-integrations': Service<AppIntegration, AppIntegrationData>;
  'app-actions': Service<AppAction, AppActionData> & AppActionsCustomMethods;
  'subscriptions': Service<Subscription, SubscriptionData>;
  [key: `api/v1/${string}/uploads`]: Service<AppUpload, AppUploadData>;
  [key: `api/v1/${string}/resource/${string}`]: Service<ApplicationResourceInstance, ApplicationResourceInstanceData>;
};

class Api {
  readonly app: Application<Services>;

  readonly organizations: ServiceAPI<Organization>;

  readonly applications: ServiceAPI<App>;

  readonly applicationResources: ApplicationResourceService;

  readonly fields: ServiceAPI<Field, FieldData>;

  readonly applicationPages: ServiceAPI<ApplicationPage>;

  readonly applicationServiceSettings: ServiceAPI<ApplicationResourceSettings>;

  readonly nodeComponents: ServiceAPI<NodeComponent, Partial<NodeComponent>>;

  readonly appApiKeys: ServiceAPI<AppApiKey>;

  readonly users: Users;

  readonly appUsers: ServiceAPI<AppUser, AppUserData>;

  readonly appIntegrations: ServiceAPI<AppIntegration, AppIntegrationData>;

  readonly appActions: AppActions;

  readonly subscriptions: Subscriptions;

  constructor() {
    this.app = client;
    this.organizations = new ServiceAPI<Organization>(this.app.service('organizations'));
    this.applications = new ServiceAPI<App>(this.app.service('applications'));
    this.applicationResources = new ApplicationResourceService(this.app.service('application-resources'));
    this.fields = new ServiceAPI<Field, FieldData>(
      this.app.service('application-resource-fields'),
    );
    this.applicationPages = new ServiceAPI<ApplicationPage>(this.app.service('application-pages'));
    this.applicationServiceSettings = new ServiceAPI<ApplicationResourceSettings>(
      this.app.service('application-resource-settings'),
    );
    this.nodeComponents = new ServiceAPI<NodeComponent>(this.app.service('node-components'));
    this.appApiKeys = new ServiceAPI<AppApiKey>(this.app.service('app-api-keys'));
    this.users = new Users(this.app.service('users'));
    this.appUsers = new ServiceAPI<AppUser, AppUserData>(this.app.service('app-users'));
    this.appIntegrations = new ServiceAPI<AppIntegration, AppIntegrationData>(this.app.service('app-integrations'));
    this.appActions = new AppActions(this.app.service('app-actions'));
    this.subscriptions = new Subscriptions(this.app.service('subscriptions'));
  }

  appResourceInstance = (
    applicationId: ApplicationResource['applicationId'],
    resourceSlug: ApplicationResource['slug'],
  ) => new ServiceAPI<ApplicationResourceInstance, ApplicationResourceInstanceData>(
    this.app.service(`api/v1/${applicationId}/resource/${resourceSlug}`),
  );

  appUploads = (appId: string) => new AppUploads(this.app.service(`api/v1/${appId}/uploads`));
}

const api = new Api();

export default api;

export type { User };
