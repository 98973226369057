import Spin from '../Spin';
import styles from './index.module.css';

interface LoadingResponseProps {
  loading: boolean;
  error?: boolean;
}

export default function LoadingResponse(props: LoadingResponseProps) {
  const { loading, error } = props;

  return (
    <div className={styles.container}>
      {
        loading && <Spin />
      }
      {
        error && <p>Something went wrong, please try again</p>
      }
    </div>
  );
}
