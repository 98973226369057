import styles from './index.module.css';

interface SidebarInputLabelProps {
  label: string;
}

export default function SidebarInputLabel(props: SidebarInputLabelProps) {
  const { label } = props;

  return (
    <div className={styles.container}>{label}</div>
  );
}
