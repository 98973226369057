import useOrganizationUser from '../../../organizations/hooks/useOrganizationUser';
import LoadingResponse from '../../../common/components/LoadingResponse';
import checkOrganizationPermissions from '../../utils/checkOrganizationPermissions';
import Permission from '../../utils/Permission';

interface OrganizationRoutePermissionsProps {
  permissions: Permission | Permission[];
  children?: () => JSX.Element;
}

export default function OrganizationRoutePermissions(props: OrganizationRoutePermissionsProps) {
  const { permissions, children = null } = props;
  const { loading, isOwner } = useOrganizationUser();

  if (loading) {
    return (<LoadingResponse loading={loading} />);
  }

  const hasPermissions = checkOrganizationPermissions(permissions, isOwner);

  if (!hasPermissions) {
    // TODO: redirect to some page that says you don't have permissions
    return null;
  }

  return children ? children() : null;
}
