import React from 'react';
import ContainerBlock from '../components/AppPageBuilderNew/blocks/ContainerBlock';
import TextBlock from '../components/AppPageBuilderNew/blocks/TextBlock';
import ButtonBlock from '../components/AppPageBuilderNew/blocks/ButtonBlock';
import LinkBlock from '../components/AppPageBuilderNew/blocks/LinkBlock';

const baseBlockResolver: Record<string, React.ComponentType<any>> = {
  ContainerBlock,
  TextBlock,
  ButtonBlock,
  LinkBlock,
};

export default baseBlockResolver;
