import {
  Alert, Card,
  Col, Drawer, Input, message, notification, Row,
} from 'antd';
import {
  Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Plan, Subscription } from '@kernex/common';
import { useRecoilState } from 'recoil';
import PlanCard from '../../billing/components/PlanCard';
import plans from '../../billing/utils/plans';
import { APP_URL, STRIPE_PUBLISH_KEY } from '../../../global/constants';
import api from '../../../api';
import Button from '../../common/components/Button';
import useOrganizationPlan from '../hooks/useOrganizationPlan';
import { formatDate } from '../../common/utils/format';
import OrganizationAddress from './OrganizationAddress';
import organizationsAtom from '../atoms';

// Make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);

interface CheckoutFormProps {
  returnUrl: string;
}

function CheckoutForm(props: CheckoutFormProps) {
  const { returnUrl } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  const onSubscribe = async () => {
    if (stripe && elements) {
      setSubscribing(true);
      const result = await stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        notification.error({
          message: result.error.message,
          type: 'error',
        });
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
      setSubscribing(false);
    }
  };

  return (
    <>
      <PaymentElement />
      <Button
        className="mt-2"
        type="primary"
        loading={subscribing}
        onClick={onSubscribe}
        block
      >
        Subscribe
      </Button>
    </>
  );
}

interface OrganizationBillingProps {
  paymentComplete?: boolean;
}

function getActivePlanMessage(plan: Plan, subscription?: Subscription) {
  let m = `Your current plan is ${plan?.name}${plan.isAppSumoPlan ? ' (AppSumo)' : ''}.`;

  if (subscription?.cancelAtPeriodEnd) {
    m += ` Your subscription will end on ${formatDate(subscription.expiresAt)}.`;
  }

  return m;
}

export default function OrganizationBilling(props: OrganizationBillingProps) {
  const { paymentComplete } = props;
  const [{ organization }, setOrganizationState] = useRecoilState(organizationsAtom);
  const [loadingPlanId, setLoadingPlanId] = useState<Plan['id']>();
  const [cancelingPlanId, setCancelingPlanId] = useState<Plan['id']>();
  const [resumingPlanId, setResumingPlanId] = useState<Plan['id']>();
  const [subscription, setSubscription] = useState<Subscription>();
  const [stackedCodes, setStackedCodes] = useState<string>('');
  const [stacking, setStacking] = useState<boolean>(false);
  const { plan: activePlan, subscription: activeSubscription, load: refreshPlan } = useOrganizationPlan();

  useEffect(() => {
    if (paymentComplete) {
      setSubscription(undefined);
    }
  }, [paymentComplete]);

  const onPlanSelect = async (selectedPlan: Plan) => {
    if (organization) {
      setLoadingPlanId(selectedPlan.id);

      const response = await api.subscriptions.create({
        organizationId: organization._id,
        planId: selectedPlan.id,
      });

      setSubscription(response);
      setLoadingPlanId(undefined);
    }
  };

  const onCancel = async (planId: Plan['id']) => {
    setCancelingPlanId(planId);

    try {
      if (activeSubscription) {
        await api.subscriptions.unsubscribe(activeSubscription._id);
        await refreshPlan();
      } else {
        notification.error({
          message: 'Error loading subscription',
          description: 'Please try again',
        });
      }
    } catch {
      notification.error({
        message: 'Error canceling subscription',
        description: 'Please try again',
      });
    }

    setCancelingPlanId(undefined);
  };

  const onResume = async (planId: Plan['id']) => {
    setResumingPlanId(planId);

    try {
      if (activeSubscription) {
        await api.subscriptions.resubscribe(activeSubscription._id);
        await refreshPlan();
      } else {
        notification.error({
          message: 'Error loading subscription',
          description: 'Please try again',
        });
      }
    } catch {
      notification.error({
        message: 'Error resuming subscription',
        description: 'Please try again',
      });
    }

    setResumingPlanId(undefined);
  };

  const onStackClick = async () => {
    if (!organization) {
      return message.error({
        content: 'Error loading organization',
      });
    }

    if (!stackedCodes.trim()) {
      return message.error({
        content: 'Please enter your AppSumo code(s)',
      });
    }

    setStacking(true);

    try {
      await api.users.stackAppSumoCodes({
        organizationId: organization._id,
        codes: stackedCodes.split(',').map((c) => c.trim()),
      });
      const newOrganization = await api.organizations.get(organization._id);
      setOrganizationState((state) => ({
        ...state,
        organization: newOrganization,
      }));
      await refreshPlan(newOrganization);
      notification.success({
        message: 'Success',
        description: 'Your AppSumo plan has been changed.',
      });
    } catch (e) {
      message.error({
        // @ts-ignore
        content: e?.message,
      });
    }

    setStacking(false);

    return undefined;
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Row gutter={[16, 16]}>
        {
          activePlan && (
            <Col span={24}>
              <Alert
                message={getActivePlanMessage(activePlan, activeSubscription)}
                type="info"
                closable
              />
            </Col>
          )
        }
        {
          activePlan?.isAppSumoPlan && (
            <Col span={24}>
              <Card>
                <h4>AppSumo Stacking</h4>
                <p className="text-muted">
                  Love Kernex? Get more apps and users by stacking your AppSumo codes.
                </p>
                <div className="d-flex">
                  <Input
                    placeholder="Enter your AppSumo code(s)"
                    className="me-2"
                    value={stackedCodes}
                    onChange={(e) => { setStackedCodes(e.target.value); }}
                  />
                  <Button
                    type="primary"
                    onClick={onStackClick}
                    loading={stacking}
                  >
                    Stack
                  </Button>
                </div>
                <small>
                  Separate multiple codes with a comma, e.g
                  {' '}
                  <b>CODE1,CODE2</b>
                  .
                  You can stack up to 4 codes.
                </small>
              </Card>
            </Col>
          )
        }
        {
          organization && (
            <Col span={24}>
              <Card>
                <OrganizationAddress
                  organization={organization}
                  onSaveSuccess={(updatedOrganization) => {
                    setOrganizationState((state) => ({
                      ...state,
                      organization: updatedOrganization,
                    }));
                  }}
                />
              </Card>
            </Col>
          )
        }
        {
          paymentComplete ? (
            <Col span={24}>
              <h3 className="text-center my-4">
                Your payment is complete and your subscription is active. Thank you for using Kernex.
              </h3>
            </Col>
          ) : (
            <>
              {
                plans.plans.map((plan) => (
                  <Col span={24} md={6} key={plan.id}>
                    <PlanCard
                      name={plan.name}
                      price={plan.price}
                      stripePriceId={plan.stripePriceId}
                      appCount={plan.appCount}
                      maxUsersPerApp={plan.maxUsersPerApp}
                      maxRecordsPerApp={plan.maxRecordsPerApp}
                      premiumSupport={plan.premiumSupport}
                      allowsIntegrations={plan.allowsIntegrations}
                      fileQuota={plan.fileQuota}
                      onSubscribeClick={() => { onPlanSelect(plan).then(); }}
                      onCancelClick={() => { onCancel(plan.id).then(); }}
                      onResumeClick={() => { onResume(plan.id).then(); }}
                      loading={loadingPlanId === plan.id}
                      cancelling={cancelingPlanId === plan.id}
                      resuming={resumingPlanId === plan.id}
                      isCancelingAtPeriodEnd={
                        activeSubscription?.planId === plan.id
                        && activeSubscription.cancelAtPeriodEnd
                      }
                      isActive={plan.id === activePlan?.id}
                      disabled={activePlan?.isAppSumoPlan}
                    />
                  </Col>
                ))
              }
              <Col span={24} md={6}>
                <PlanCard
                  name="Enterprise"
                  price="Contact Us"
                  stripePriceId=""
                  appCount="Unlimited"
                  fileQuota={Infinity}
                  allowsIntegrations
                  premiumSupport
                />
              </Col>
            </>
          )
        }
        <Drawer
          open={Boolean(subscription)}
          onClose={() => { setSubscription(undefined); }}
          title="Subscribe"
        >
          {
            subscription && (
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: subscription.stripeClientSecret }}
              >
                <CheckoutForm
                  returnUrl={`${APP_URL}/app/organizations/${organization?.slug}/billing/payment-complete`}
                />
              </Elements>
            )
          }
        </Drawer>
      </Row>
    </div>
  );
}
