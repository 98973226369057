import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Descriptions, Popconfirm } from 'antd';
import { Field } from '@kernex/core';

import styles from './index.module.css';
import ApplicationResourceInstance from '../../../../api/models/ApplicationResourceInstance';
import api from '../../../../api';
import appsAtom from '../../../apps/state';
import PageContent from '../../../layout/PageContent';
import appAdminAtom from '../../state';
import Button from '../../../common/components/Button';
import { singular } from '../../../../utils';
import { useFieldTypes } from '../../../../plugins';
import FieldValueRenderer from '../../../fields/components/FieldValueRenderer';

export default function AppAdminResourceDetailsPage() {
  const { app } = useRecoilValue(appsAtom);
  const { resourceId } = useParams<{ resourceId: string }>();
  const { service } = useRecoilValue(appAdminAtom);
  const [data, setData] = useState<ApplicationResourceInstance>();
  const [fields, setFields] = useState<Field[]>();
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const fieldTypes = useFieldTypes();

  const appId = app?._id;

  const loadData = async () => {
    if (appId && service && resourceId) {
      setLoading(true);
      const response = await api.appResourceInstance(appId, service.slug).get(resourceId);
      const fieldsResponse = await api.fields.find({
        query: {
          resourceId: service._id,
          $limit: 100,
        },
      });
      setFields(fieldsResponse.data);
      setData(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData().then();
  }, [appId, service?.slug, resourceId]);

  const onDeleteClick = async () => {
    setDeleting(true);

    if (appId && service && resourceId) {
      await api.appResourceInstance(appId, service.slug).remove(resourceId);
      navigate('../..');
    }

    setDeleting(false);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <PageContent
      title={service ? `${singular(service.name)} Details` : 'Details'}
      actions={(
        <>
          <Link to="edit">
            <Button>Edit</Button>
          </Link>
          <Popconfirm
            title="Are you sure?"
            onConfirm={onDeleteClick}
            okText="Delete"
            className="ms-2"
          >
            <Button danger loading={deleting}>Delete</Button>
          </Popconfirm>
        </>
      )}
      disableCardWrapper
    >
      {
        data && fields && (
          <Descriptions style={{ backgroundColor: 'white' }} size="small" layout="horizontal" column={1} bordered>
            {
              fields.map((field) => {
                const { name: propertyName, displayName } = field;
                const propertyValue = data[propertyName];
                return (
                  <Descriptions.Item
                    key={propertyName}
                    label={displayName}
                    className={styles.descriptionItem}
                  >
                    <FieldValueRenderer
                      value={propertyValue}
                      field={field}
                      fieldType={fieldTypes.find((ft) => ft.type === field.type)}
                    />
                  </Descriptions.Item>
                );
              })
            }
          </Descriptions>
        )
      }
    </PageContent>
  );
}
