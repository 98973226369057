import {
  Col, Row,
} from 'antd';
import { ApplicationResource } from '@kernex/common';
import AppResourceForm from '../AppResourceForm';

interface AppResourceEditProps {
  service: ApplicationResource;
}

export default function AppResourceEdit(props: AppResourceEditProps) {
  const { service } = props;

  return (
    <Row>
      <Col span={24}>
        <AppResourceForm appId={service.applicationId} service={service} />
      </Col>
    </Row>
  );
}
