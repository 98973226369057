import React from 'react';
import { AppUserRole } from '@kernex/common';
import useAppUser from '../../../apps/hooks/useAppUser';
import LoadingResponse from '../../../common/components/LoadingResponse';
import checkAppRole from '../../utils/checkAppRole';

interface AppRoleProps {
  roles: AppUserRole | AppUserRole[];
  children?: () => React.ReactElement;
  renderRestricted?: () => React.ReactElement;
}

export default function AppRoles(props: AppRoleProps) {
  const { roles, renderRestricted, children = null } = props;
  const { user, loading } = useAppUser();

  if (loading) {
    return (<LoadingResponse loading={loading} />);
  }

  const hasPermissions = checkAppRole(roles, user);

  if (!hasPermissions) {
    // TODO: Show something else
    return renderRestricted ? renderRestricted() : null;
  }

  return children ? children() : null;
}
