import { NodeId, SerializedNode } from '@craftjs/core';

export enum NodeComponentType {
  PAGE = 'PAGE',
  COMPONENT = 'COMPONENT',
}

export default interface NodeComponent {
  _id: string;
  type: NodeComponentType;
  applicationId?: string;
  pageId?: string;
  displayName?: string;
  nodes: Record<NodeId, SerializedNode>;
}
