import { Spin } from 'antd';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export default function HomePage() {
  const auth = useAuth();

  if (auth.loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  return (<Navigate to={auth.user ? '/app' : '/sign-in'} replace />);
}
