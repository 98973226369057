import { SelectHTMLAttributes } from 'react';
import classnames from 'classnames';
import styles from './index.module.css';

export type SidebarSelectInputValue = {
  value: string;
  label?: string;
};

interface SidebarSelectInputProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  onChange: (value: SidebarSelectInputValue['value']) => void;
  options: SidebarSelectInputValue[];
}

export default function SidebarSelectInput(props: SidebarSelectInputProps) {
  const {
    onChange, options, className, ...rest
  } = props;

  return (
    <select
      className={classnames(className, styles.select)}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      {...rest}
    >
      {
        options.map((option) => (
          <option key={option.value} value={option.value}>{option.label || option.value}</option>
        ))
      }
    </select>
  );
}
