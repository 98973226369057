import { Col, Row, Tabs } from 'antd';
import PageContent from '../../../layout/PageContent';
import UserAccountForm from '../../components/UserAccountForm';
import UserSecurityForm from '../../components/UserSecurityForm';

export default function UserAccountPage() {
  return (
    <PageContent title="Your Account">
      <Tabs>
        <Tabs.TabPane tab="Account" key="account">
          <Row>
            <Col span={24} md={12} lg={10} xl={8} xxl={6}>
              <UserAccountForm />
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Security" key="security">
          <Row>
            <Col span={24} md={12} lg={10} xl={8} xxl={6}>
              <UserSecurityForm />
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </PageContent>
  );
}
