import {
  Card, Col, Input as AntInput, notification, Row,
} from 'antd';
import { useEffect, useState } from 'react';
import { ApplicationResource } from '@kernex/common';
import { FieldTypeInputProps } from '@kernex/core';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import DatePicker from '../../../common/components/DatePicker';
import Button from '../../../common/components/Button';
import api from '../../../../api';
import { ApplicationResourceInstanceData } from '../../../../api/models/ApplicationResourceInstance';
import { API_URL } from '../../../../global/constants';
import LoadingResponse from '../../../common/components/LoadingResponse';
import AppResourceEntrySelect from '../../../app-resources/components/AppResourceEntrySelect';

interface TitleLink {
  agencyId: string | null;
  url: string;
  title: string;
  timestamp: Date | null;
  agency: any;
  description?: string;
}

export default function Input(props: FieldTypeInputProps) {
  const {
    field, value, onChange,
  } = props;

  const agenciesResourceId = field.relationResourceId;

  const [resource, setResource] = useState<ApplicationResource | null>(null);
  const [loading, setLoading] = useState<Record<number, boolean>>({});

  const { slug, applicationId } = resource || {};

  const loadResource = async () => {
    if (agenciesResourceId) {
      const response = await api.applicationResources.get(agenciesResourceId, {
        query: {
          $select: ['slug', 'applicationId', 'titleFieldName'],
        },
      });
      setResource(response);
    }
  };

  const updateEntry = (entry: Partial<TitleLink>, index: number) => {
    if (onChange && value) {
      onChange(value.map((item: any, i: number) => (i === index ? { ...item, ...entry } : item)));
    }
  };

  function getValue<T extends keyof TitleLink>(fieldName: T, index: number): TitleLink[T] | undefined {
    return value && value[index] ? value[index][fieldName] : undefined;
  }

  const onFieldChange = async (fieldValue: string | any, fieldName: string, index: number) => {
    let agency = getValue('agency', index);
    if (applicationId && slug && fieldName === 'agencyId') {
      try {
        agency = await api.appResourceInstance(applicationId, slug).get(fieldValue);
      } catch {
        // Ignore
      }
    }

    updateEntry({ [fieldName]: fieldValue, agency }, index);
  };

  useEffect(() => {
    loadResource().then();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {
          value && (
            <Row gutter={[16, 16]}>
              {
                value.map((item: any, index: number) => {
                  let timestampValue = getValue('timestamp', index) || null;

                  if (timestampValue && !(timestampValue instanceof Date)) {
                    timestampValue = new Date(timestampValue);
                  }

                  return (
                    <Col span={24}>
                      <Card size="small">
                        <Row gutter={[16, 16]} style={{ position: 'relative' }}>
                          {
                            loading[index] && (
                              <div
                                style={{
                                  position: 'absolute',
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                  zIndex: 1,
                                }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <LoadingResponse loading={loading[index]} />
                              </div>
                            )
                          }
                          <Col span={24} md={6}>
                            <AntInput
                              placeholder="URL"
                              value={getValue('url', index)}
                              onChange={(e) => onFieldChange(e.target.value, 'url', index)}
                              onPaste={async (e) => {
                                setLoading((prevState) => ({ ...prevState, [index]: true }));
                                const url = e.clipboardData.getData('text');
                                try {
                                  const response = await fetch(`${API_URL}/extract?url=${url}`);

                                  let agencyId: string | null = null;

                                  let { host } = new URL(url);

                                  let agency: undefined | ApplicationResourceInstanceData;

                                  if (applicationId && slug) {
                                    if (host.includes('www.')) {
                                      host = host.replace('www.', '');
                                    }

                                    try {
                                      agency = await api.appResourceInstance(applicationId, slug).findOne({
                                        query: {
                                          website: {
                                            $in: [
                                              host,
                                              `www.${host}`,
                                              `http://${host}`,
                                              `https://${host}`,
                                              `http://www.${host}`,
                                              `https://www.${host}`],
                                          },
                                          $select: [
                                            'title',
                                            'image.url',
                                            'image.title',
                                            'image.description',
                                          ],
                                        },
                                      });
                                      if (agency) {
                                        agencyId = String(agency._id);
                                      }
                                    } catch {
                                      // Ignore
                                    }
                                  }

                                  const { metadata } = await response.json() as {
                                    metadata: {
                                      title: string;
                                      description: string;
                                      date?: string;
                                      publishDate?: string;
                                    };
                                  };
                                  const date = metadata.date || metadata.publishDate;
                                  updateEntry({
                                    title: metadata.title,
                                    description: metadata.description,
                                    timestamp: date ? new Date(date) : null,
                                    url,
                                    agencyId,
                                    agency,
                                  }, index);
                                } catch {
                                  notification.error({
                                    message: 'There was an error while fetching the url',
                                    description: 'Please try again',
                                  });
                                }
                                setLoading((prevState) => ({ ...prevState, [index]: false }));
                              }}
                            />
                          </Col>
                          <Col span={24} md={6}>
                            <AppResourceEntrySelect
                              resourceId={String(agenciesResourceId)}
                              value={getValue('agencyId', index) || null}
                              onChange={(agency) => onFieldChange(agency, 'agencyId', index)}
                              placeholder="Agency"
                            />
                          </Col>
                          <Col span={24} md={6}>
                            <AntInput
                              placeholder="Title"
                              onChange={(e) => onFieldChange(e.target.value, 'title', index)}
                              value={getValue('title', index)}
                            />
                          </Col>
                          <Col span={24} md={6}>
                            <DatePicker
                              placeholder="Timestamp"
                              style={{ width: '100%' }}
                              onChange={(date) => onFieldChange(date, 'timestamp', index)}
                              value={timestampValue}
                              showTime
                            />
                          </Col>
                          <Col span={24}>
                            <AntInput.TextArea
                              rows={3}
                              placeholder="Description"
                              onChange={(e) => onFieldChange(e.target.value, 'description', index)}
                              value={getValue('description', index)}
                            />
                          </Col>
                          <Col span={24}>
                            <div className="d-flex justify-content-center">
                              <Button
                                onClick={() => {
                                  if (onChange) {
                                    onChange(value.filter((_: any, i: number) => i !== index));
                                  }
                                }}
                                type="ghost"
                                size="small"
                                danger
                              >
                                Delete
                              </Button>
                              {
                                index < value.length - 1 && (
                                  <Button
                                    className="ms-2"
                                    size="small"
                                    onClick={() => {
                                      if (onChange) {
                                        const arr = [...value];
                                        arr.splice(index + 1, 0, arr.splice(index, 1)[0]);
                                        onChange(arr);
                                      }
                                    }}
                                  >
                                    <ArrowDownOutlined />
                                  </Button>
                                )
                              }
                              {
                                index > 0 && (
                                  <Button
                                    className="ms-2"
                                    size="small"
                                    onClick={() => {
                                      if (onChange) {
                                        const arr = [...value];
                                        arr.splice(index + -1, 0, arr.splice(index, 1)[0]);
                                        onChange(arr);
                                      }
                                    }}
                                  >
                                    <ArrowUpOutlined />
                                  </Button>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })
              }
            </Row>
          )
        }
      </Col>
      <Col span={24}>
        <Button
          type="default"
          size="small"
          onClick={() => {
            if (onChange) {
              onChange([...(value || []), {
                agencyId: null,
                url: '',
                title: '',
                timestamp: null,
                agency: null,
              }]);
            }
          }}
        >
          Add Row
        </Button>
      </Col>
    </Row>
  );
}
