import {
  Card, notification, Upload, Row, Col, Form, Drawer, Input as AntInput,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldTypeInputProps } from '@kernex/core';
import { DndContext, UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { arraySwap, SortableContext, useSortable } from '@dnd-kit/sortable';
import { AppUpload } from '@kernex/common';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities/useSyntheticListeners';
import debounce from 'just-debounce';
import api from '../../../../api';
import AppMediaLibraryDrawer from '../../../app-media-library/components/AppMediaLibraryDrawer';
import Spin from '../../../common/components/Spin';
import ImageCard from '../../../app-media-library/components/ImageCard';
import Button from '../../../common/components/Button';
import useFormSubmit from '../../../common/hooks/useFormSubmit';
import DetailedImageCard from '../../../app-media-library/components/DetailedImageCard';

function makeArray<T>(item: T | T[]) {
  if (!item) {
    return [];
  }

  return Array.isArray(item) ? item : [item];
}

interface DraggableImageContainerProps {
  fileId: AppUpload['_id'];
  children: (listeners: SyntheticListenerMap | undefined) => React.ReactNode;
}

function DraggableImageContainer(props: DraggableImageContainerProps) {
  const { fileId, children } = props;
  const {
    attributes, listeners, setNodeRef, transform,
  } = useSortable({
    id: fileId,
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  return (
    <Col span={24} md={6} ref={setNodeRef} style={style} {...attributes}>
      {children(listeners)}
    </Col>
  );
}

function DroppableContainer(props: { children: React.ReactNode | React.ReactNode[] }) {
  const { children } = props;
  const { isOver, setNodeRef } = useDroppable({
    id: 'droppable',
  });
  const style = {
    color: isOver ? 'green' : undefined,
  };

  return (
    <Row ref={setNodeRef} gutter={[16, 16]} style={style}>
      {children}
    </Row>
  );
}

export default function Input(props: FieldTypeInputProps) {
  const {
    appId, onChange, value, field,
  } = props;
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<AppUpload[]>(makeArray(value));
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [editImage, setEditImage] = useState<AppUpload>();
  const { isArray } = field;
  useEffect(() => {
    setFiles(makeArray(value));
  }, [value]);

  const onFileUpload = (file: any) => {
    if (onChange) {
      if (isArray) {
        setFiles((prev) => {
          const newFiles = [...prev, file];
          onChange(newFiles);
          return newFiles;
        });
      } else {
        onChange(file);
      }
    }
  };

  const onDelete = (file: any) => {
    if (onChange) {
      if (isArray) {
        onChange(files.filter((item) => item._id !== file._id));
      } else {
        onChange(undefined);
      }
    }
  };

  const items = files.map((file) => file._id);
  const getIndex = (id: UniqueIdentifier) => items.indexOf(String(id));

  const activeIndex = activeId ? getIndex(activeId) : -1;

  const updateLocalImage = (image: AppUpload, values: Partial<AppUpload>) => {
    setFiles((prev) => {
      const newFiles = prev.map((upload) => (upload._id === image._id ? { ...upload, ...values } : upload));
      if (onChange) {
        if (isArray) {
          onChange(newFiles);
        } else {
          onChange({ ...image, ...values });
        }
      }
      return newFiles;
    });
  };

  const updateImage = async (image: AppUpload, values: Partial<AppUpload>) => {
    updateLocalImage(image, values);
    await api.appUploads(appId).patch(image._id, values);
  };

  const updateImageDebounce = useCallback(debounce(async (image: AppUpload, values: Partial<AppUpload>) => {
    await api.appUploads(appId).patch(image._id, values);
  }, 500), []);

  const [onEdit, editing] = useFormSubmit(async (values: Partial<AppUpload>) => {
    if (editImage) {
      await updateImage(editImage, values);
    }
  }, {
    onSuccess: () => {
      setEditImage(undefined);
    },
  });

  return (
    <DndContext
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }

        setActiveId(active.id);
      }}
      onDragEnd={({ over }) => {
        if (over) {
          const overIndex = getIndex(over.id);
          if (activeIndex !== overIndex) {
            setFiles((prev) => {
              const newItems = arraySwap(prev.map((file) => file._id), activeIndex, overIndex);
              const newFiles: AppUpload[] = [];
              newItems.forEach((id) => {
                const file = files.find((item) => item._id === id);

                if (file) {
                  newFiles.push(file);
                }
              });
              if (onChange) {
                onChange(newFiles);
              }
              return newFiles;
            });
          }
        }
      }}
    >
      <div>
        <Card className="mb-2" title={field.displayName} size="small">
          {
            isArray ? (
              <SortableContext items={items}>
                <DroppableContainer>
                  {
                    files.map((file) => (
                      <DraggableImageContainer fileId={file._id}>
                        {(listeners) => (
                          <ImageCard
                            url={file.url}
                            title={file.title}
                            createdAt={file.createdAt}
                            onDelete={() => { onDelete(file); }}
                            handleProps={listeners}
                            onEdit={() => { setEditImage(file); }}
                          />
                        )}
                      </DraggableImageContainer>
                    ))
                  }
                </DroppableContainer>
              </SortableContext>
            ) : (
              <div>
                {
                  files.map((file) => (
                    <DetailedImageCard
                      url={file.url}
                      title={file.title}
                      description={file.description}
                      onDelete={() => { onDelete(file); }}
                      onEdit={(values: Pick<AppUpload, 'title' | 'description'>) => {
                        updateLocalImage(file, values);
                        updateImageDebounce(file, values);
                      }}
                    />
                  ))
                }
              </div>
            )
          }
          <Upload.Dragger
            multiple={field.isArray}
            className="mt-2"
            style={{ width: '100%' }}
            accept="image/*"
            customRequest={(request) => {
              if (request.file instanceof Blob) {
                setLoading(true);
                api.appUploads(appId).create({
                  file: request.file,
                }).then((response) => {
                  if (request.onSuccess) {
                    request.onSuccess(response);
                  }
                  onFileUpload(response);
                  setLoading(false);
                }).catch((error) => {
                  if (request.onError) {
                    request.onError(error);
                  }
                  notification.error({
                    message: 'Something went wrong',
                    description: 'Please try again',
                  });
                  setLoading(false);
                });
              } else {
                notification.error({
                  message: 'Something went wrong',
                  description: 'Please try again',
                });
              }
            }}
            showUploadList={false}
            disabled={loading}
          >
            <div className="d-flex flex-column justify-content-center">
              {
                !loading ? (
                  <>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="ant-upload-drag-icon">
                      <Spin />
                    </p>
                    <p className="ant-upload-hint">Uploading files, please wait</p>
                  </>
                )
              }
            </div>
          </Upload.Dragger>
          <p className="text-center mt-2">or select from Media Library</p>
          <div className="d-flex justify-content-center mt-2">
            <AppMediaLibraryDrawer
              buttonText="Media Library"
              onSelect={(upload) => {
                if (onChange) {
                  onChange(upload);
                }
              }}
              disabled={loading}
              multiple={isArray}
              onSelectMultiple={isArray ? (selected) => {
                if (onChange) {
                  setFiles((prev) => {
                    const newFiles = [...prev, ...selected];
                    onChange(newFiles);
                    return newFiles;
                  });
                }
              } : undefined}
            />
          </div>
        </Card>
      </div>

      <Drawer
        title="Edit Image"
        open={Boolean(editImage)}
        onClose={() => { setEditImage(undefined); }}
      >
        {
          editImage && (
            <Form
              initialValues={editImage}
              onFinish={onEdit}
              layout="vertical"
            >
              <Form.Item label="Title" name="title">
                <AntInput />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <AntInput />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" loading={editing}>Save</Button>
              </Form.Item>
            </Form>
          )
        }
      </Drawer>
    </DndContext>
  );
}
