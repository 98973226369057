import { Col, Row } from 'antd';
import templates from './templates';
import AppTemplate from '../../definitions/AppTemplate';
import AppTemplateCard from '../AppTemplateCard';

interface AppTemplateSelectProps {
  template: AppTemplate | null;
  onChange: (template: AppTemplate | null) => void;
}

export default function AppTemplateSelect(props: AppTemplateSelectProps) {
  const { template, onChange } = props;

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={12} xl={8} xxl={6}>
        <AppTemplateCard
          name="No template"
          description="Customize your own app and start from scratch without any templates"
          selected={!template}
          onClick={() => { onChange(null); }}
        />
      </Col>
      {
        templates.map((item) => (
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <AppTemplateCard
              key={item.value}
              name={item.name}
              description={item.description}
              selected={template?.value === item.value}
              onClick={() => {
                onChange(item);
              }}
            />
          </Col>
        ))
      }
    </Row>
  );
}
