import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import SignInPage from '../modules/authentication/pages/SignInPage';
import SignUpPage from '../modules/authentication/pages/SignUpPage';
import UserNavigator from './UserNavigator';
import AppPreviewPage from '../modules/app-preview/pages/AppPreviewPage';
import HomePage from '../modules/authentication/pages/HomePage';
import useAuth from '../modules/authentication/hooks/useAuth';
import VerifyEmailPage from '../modules/authentication/pages/VerifyEmailPage';
import ForgotPasswordPage from '../modules/authentication/pages/ForgotPasswordPage';
import ResetPasswordPage from '../modules/authentication/pages/ResetPasswordPage';
import { useInitializePlugins } from '../plugins';
import AppSumoSignUpPage from '../modules/authentication/pages/AppSumoSignUpPage';
import useScript from '../modules/common/hooks/useScript';

export default function Navigation() {
  useScript('https://app.zupport.ai/widget.js');
  useInitializePlugins();
  const auth = useAuth();
  const { user } = auth;

  useEffect(() => {
    auth.authenticate().then();
  }, []);

  useEffect(() => {
    const [firstName = 'Unknown', lastName = 'Unknown'] = user?.name?.split(' ') || [];
    // @ts-ignore
    window.zupportSettings = {
      baseAPI: 'https://api.zupport.ai',
      websiteUid: 'ZqVof8scNtEpGS2upHCUTjVk',
      userUid: user?._id,
      userEmail: user?.email,
      userSecret: user?._id,
      userFirstName: firstName,
      userLastName: lastName,
    };
  }, [user]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="sign-in" element={<SignInPage />} />
      <Route path="sign-up" element={<SignUpPage />} />
      <Route path="verify-email" element={<VerifyEmailPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="appsumo/:code" element={<AppSumoSignUpPage />} />
      <Route path="/app/*" element={<UserNavigator />} />
      <Route path=":appSlug/*" element={<AppPreviewPage />} />
    </Routes>
  );
}
