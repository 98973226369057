import React from 'react';
import { LinkBlockPreview } from '../components/AppPageBuilderNew/blocks/LinkBlock';
import { TextBlockPreview } from '../components/AppPageBuilderNew/blocks/TextBlock';
import { ContainerBlockPreview } from '../components/AppPageBuilderNew/blocks/ContainerBlock';
import { ButtonBlockPreview } from '../components/AppPageBuilderNew/blocks/ButtonBlock';

const blockPreviewResolverBase: Record<string, React.ComponentType<any>> = {
  LinkBlock: LinkBlockPreview,
  ButtonBlock: ButtonBlockPreview,
  ContainerBlock: ContainerBlockPreview,
  TextBlock: TextBlockPreview,
} as const;

export default blockPreviewResolverBase;
