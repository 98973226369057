import { Form, Input, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Application, ApplicationData } from '@kernex/common';

import Button from '../../../common/components/Button';
import api from '../../../../api';
import appsAtom from '../../state';
import organizationsAtom from '../../../organizations/atoms';
import { setFormErrorsFromServer } from '../../../common/utils/errors';

interface AppFormProps {
  organizationId: string;
  app?: Application;
  onSuccess?: (app: Application) => void;
}

export default function AppForm(props: AppFormProps) {
  const { organizationId, app, onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const [, setAppsState] = useRecoilState(appsAtom);
  const [, setOrganizationsState] = useRecoilState(organizationsAtom);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    // Reset the new apps count when the user creates a new app
    setOrganizationsState((state) => ({
      ...state,
      newAppsCount: 0,
    }));
  }, []);

  const onFinish = async (data: ApplicationData) => {
    setLoading(true);

    try {
      if (!app) {
        const newApp = await api.app.service('applications').create({ ...data, organizationId });
        setOrganizationsState((state) => ({
          ...state,
          apps: [...state.apps, newApp],
          newAppsCount: state.newAppsCount + 1,
        }));
        setAppsState((state) => ({
          ...state,
          app: newApp,
        }));

        if (onSuccess) {
          onSuccess(newApp);
        } else {
          navigate(`../${newApp.slug}`);
        }
      } else {
        await api.app.service('applications').patch(app._id, data);
        notification.success({ message: 'Application updated' });
        window.location.reload();
      }
    } catch (e) {
      setFormErrorsFromServer(e, form);
    }

    setLoading(false);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} initialValues={app}>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: 'Please set a name for your app' },
        ]}
      >
        <Input placeholder="App Name" />
      </Form.Item>

      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          { required: true, message: 'Please input the app slug!' },
          { min: 3, message: 'Slug must be at least 3 characters long!' },
          { max: 50, message: 'Slug must be at most 50 characters long!' },
          {
            pattern: /^[a-z0-9][a-z0-9-]*[a-z0-9]$/,
            message: 'Only alphanumeric characters and dashes are allowed',
          },
        ]}
      >
        <Input placeholder="App Slug" disabled={Boolean(app)} />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input placeholder="App Description" />
      </Form.Item>

      <div className="d-flex justify-content-center">
        <Form.Item>
          <Button
            htmlType="submit"
            className="btn btn-primary"
            type="primary"
            loading={loading}
          >
            {!app ? 'Create App' : 'Save'}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
