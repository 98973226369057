import { useRecoilValue } from 'recoil';
import useAuth from '../../authentication/hooks/useAuth';
import organizationsAtom from '../atoms';

export default function useOrganizationUser() {
  const { user, loading } = useAuth();
  const { organization } = useRecoilValue(organizationsAtom);

  return {
    user,
    loading,
    isOwner: Boolean(user && user?._id === organization?.userId),
  };
}
