import { Card, CardProps } from 'antd';
import classnames from 'classnames';
import styles from './index.module.css';

interface SelectBoxProps extends CardProps {
  selected: boolean;
}

export default function SelectBox(props: SelectBoxProps) {
  const { selected, className, ...rest } = props;

  return (
    <Card size="small" className={classnames(className, styles.box, selected && styles.selected)} {...rest} />
  );
}
