import { Route, Routes } from 'react-router-dom';
import OrganizationsPage from '../modules/organizations/pages/OrganizationsPage';
import OrganizationPage from '../modules/organizations/pages/OrganizationPage';
import AppPage from '../modules/apps/pages/AppPage';
import CreateAppResourcePage from '../modules/app-resources/pages/CreateAppResourcePage';
import AppResourcePage from '../modules/app-resources/pages/AppResourcePage';
import CreateAppPagePage from '../modules/app-pages/pages/CreateAppPagePage';
import AppPagePage from '../modules/app-pages/pages/AppPagePage';
import AppPageBuilderPage from '../modules/app-pages/pages/AppPageBuilderPage';
import AppAdminResourcePage from '../modules/app-admin/pages/AppAdminResourcePage';
import AppAdminResourceListPage from '../modules/app-admin/pages/AppAdminResourceListPage';
import AppAdminResourceNewPage from '../modules/app-admin/pages/AppAdminResourceNewPage';
import AppAdminResourceDetailsPage from '../modules/app-admin/pages/AppAdminResourceDetailsPage';
import AppAdminResourceEditPage from '../modules/app-admin/pages/AppAdminResourceEditPage';
import AppResourceImportPage from '../modules/app-resources/pages/AppResourcesImportPage';
import OrganizationIndexPage from '../modules/organizations/pages/OrganizationIndexPage';
import AppIndexPage from '../modules/apps/pages/AppIndexPage';
import Page from '../modules/layout/Page';
import AppLayout from '../modules/layout/AppLayout';
import UserAccountPage from '../modules/users/pages/UserAccountPage';
import { SHOW_PAGES } from '../global/constants';
import OrganizationRoutePermissions from '../modules/permissions/components/OrganizationRoutePermissions';
import Permission from '../modules/permissions/utils/Permission';
import CreateAppPage from '../modules/apps/pages/CreateApp';
import AppResources from '../modules/app-resources/pages/AppResources';
import AppActionsPage from '../modules/app-actions/pages/AppActionsPage';
import AppDocumentationPage from '../modules/apps/pages/AppDocumentationPage';

export default function UserNavigator() {
  return (
    <Page usersOnly>
      <AppLayout>
        <Routes>
          <Route path="/" element={<OrganizationsPage />} />
          <Route path="account" element={<UserAccountPage />} />
          <Route path="organizations/:organizationSlug" element={<OrganizationPage />}>
            <Route index element={<OrganizationIndexPage activeKey="info" />} />
            <Route path="edit" element={<OrganizationIndexPage activeKey="edit" />} />
            <Route path="billing" element={<OrganizationIndexPage activeKey="billing" />} />
            <Route
              path="billing/payment-complete"
              element={<OrganizationIndexPage activeKey="billing" paymentComplete />}
            />
            <Route path="apps">
              <Route
                path="new"
                element={(
                  <OrganizationRoutePermissions permissions={Permission.APP_CREATE}>
                    {() => <CreateAppPage />}
                  </OrganizationRoutePermissions>
                )}
              />
              <Route path=":appSlug" element={<AppPage />}>
                <Route index element={<AppIndexPage activeKey="info" />} />
                <Route path="edit" element={<AppIndexPage activeKey="edit" />} />
                <Route path="code" element={<AppIndexPage activeKey="code" />} />
                <Route path="api-keys" element={<AppIndexPage activeKey="api-keys" />} />
                <Route path="users" element={<AppIndexPage activeKey="users" />} />
                <Route path="usage" element={<AppIndexPage activeKey="usage" />} />
                <Route path="settings" element={<AppIndexPage activeKey="settings" />} />
                <Route path="content">
                  <Route path=":serviceSlug" element={<AppAdminResourcePage />}>
                    <Route index element={<AppAdminResourceListPage />} />
                    <Route path="new" element={<AppAdminResourceNewPage />} />
                    <Route path=":resourceId">
                      <Route index element={<AppAdminResourceDetailsPage />} />
                      <Route path="edit" element={<AppAdminResourceEditPage />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="resources" element={<AppResources />}>
                  <Route path=":appServiceSlug" element={<AppResourcePage />} />
                  <Route path="new" element={<CreateAppResourcePage />} />
                  <Route path="import" element={<AppResourceImportPage />} />
                </Route>
                <Route path="actions" element={<AppActionsPage />} />
                <Route path="documentation" element={<AppDocumentationPage />} />
                {
                  SHOW_PAGES && (
                    <Route path="pages">
                      <Route path="new" element={<CreateAppPagePage />} />
                      <Route path=":appPageId" element={<AppPagePage />} />
                      <Route path=":appPageId/builder" element={<AppPageBuilderPage />} />
                    </Route>
                  )
                }
              </Route>
            </Route>
          </Route>
        </Routes>
      </AppLayout>
    </Page>
  );
}
