import { ReactNode } from 'react';

interface AppDocsPageSectionProps {
  title: string;
  children?: ReactNode | ReactNode[];
  id?: string;
  isFirstChild?: boolean;
}

export default function AppDocsPageSection(props: AppDocsPageSectionProps) {
  const {
    title, children, id, isFirstChild,
  } = props;

  return (
    <div className={`d-flex flex-column gap-3 py-5 ${!isFirstChild ? ' border-top' : ''}`}>
      <h4 id={id} className="mb-4">{title}</h4>
      {children}
    </div>
  );
}
