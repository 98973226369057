import Permission from './Permission';

const nonOwnerPermissions: Permission[] = [];

export default function checkOrganizationPermissions(permission: Permission | Permission[], isOwner: boolean) {
  if (isOwner) {
    return true;
  }

  if (Array.isArray(permission)) {
    return permission.every((p) => nonOwnerPermissions.includes(p));
  }

  return nonOwnerPermissions.includes(permission);
}
