import { Alert, Form, Input } from 'antd';
import AuthLayout from '../../layout/AuthLayout';
import Button from '../../../common/components/Button';
import useFormSubmit from '../../../common/hooks/useFormSubmit';
import api from '../../../../api';

export default function ForgotPasswordPage() {
  const [form] = Form.useForm();

  const [onFinish, loading, response] = useFormSubmit(async (data) => api.users.requestPasswordReset(data), { form });

  return (
    <AuthLayout>
      <h1>Recover password</h1>

      {
        !response ? (
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please enter your email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item>
              <div className="d-flex justify-content-center">
                <Button type="primary" htmlType="submit" loading={loading}>Recover Password</Button>
              </div>
            </Form.Item>
          </Form>
        ) : (
          <Alert
            type="success"
            message="Please check your email to recover your password"
          />
        )
      }
    </AuthLayout>
  );
}
