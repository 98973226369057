import {
  FieldType, FieldTypeType, App, KernexPlugin,
} from '@kernex/core';
import Input from './Input';
import DetailsRenderer from './DetailsRenderer';

const relationField: FieldType = {
  type: FieldTypeType.RELATION,
  name: 'Relation',
  description: 'A reference to another resource',
  typescriptType: 'string',
  Input,
  DetailsRenderer,
};

export default class RelationFieldPlugin implements KernexPlugin {
  public id = 'relation-field-type';

  public name = 'relation-field-type';

  public description = 'Relation field type';

  // eslint-disable-next-line class-methods-use-this
  initialize(app: App) {
    app.registerFieldType(relationField);
  }
}
