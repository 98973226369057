import { useRecoilValue } from 'recoil';

import { Form } from 'antd';
import PageContent from '../../../layout/PageContent';
import appAdminAtom from '../../state';
import AppResourceUserForm from '../../../app-resources/components/AppResourceUserForm';
import { singular } from '../../../../utils';
import useAppUsage from '../../../apps/hooks/useAppUsage';
import useApp from '../../../apps/hooks/useApp';
import useOrganizationPlan from '../../../organizations/hooks/useOrganizationPlan';
import AppUsageNotice from '../../../apps/components/AppUsageNotice';
import Button from '../../../common/components/Button';

export default function AppAdminResourceNewPage() {
  const { service, fields } = useRecoilValue(appAdminAtom);
  const app = useApp();
  const { plan } = useOrganizationPlan();
  const { recordsLimitReached } = useAppUsage(app || undefined, plan);

  const [form] = Form.useForm();

  if (!service) {
    return <p>Resource not found</p>;
  }

  return (
    <PageContent
      title={`New ${singular(service?.name)}`}
      actions={[
        <Button
          htmlType="submit"
          onClick={form.submit}
        >
          Save
        </Button>,
      ]}
    >
      <div>
        <div style={{ maxWidth: 1000, margin: 'auto ' }}>
          {
            !recordsLimitReached ? (
              <AppResourceUserForm
                applicationId={service.applicationId}
                serviceSlug={service.slug}
                fields={fields}
                form={form}
              />
            ) : (
              <AppUsageNotice
                app={app || undefined}
                description="Please upgrade your plan to add new records."
                closable={false}
              />
            )
          }
        </div>
      </div>
    </PageContent>
  );
}
