import { useEffect, useState } from 'react';
import { Plan, Subscription } from '@kernex/common';
import useOrganization from './useOrganization';
import api from '../../../api';
import plans from '../../billing/utils/plans';

export default function useOrganizationPlan() {
  const organization = useOrganization();
  const [plan, setPlan] = useState<Plan>();
  const [subscription, setSubscription] = useState<Subscription>();
  const [loading, setLoading] = useState(true);

  const load = async (org = organization) => {
    setLoading(true);

    if (org?._id) {
      try {
        const response = await api.subscriptions.find({
          query: {
            organizationId: org._id,
            $limit: 1,
            isActive: true,
          },
        });

        setPlan(plans.getOrganizationPlan(org, response.data[0]));
        setSubscription(response.data[0]);
      } catch {
        // Ignore
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    load().then();
  }, [organization?._id]);

  return {
    plan,
    loading,
    subscription,
    load,
  };
}
