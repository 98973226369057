/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useEditor, useNode, Element, Node,
} from '@craftjs/core';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { ApplicationResource } from '@kernex/common';
import AppResourceSelect from '../../../../../app-resources/components/AppResourceSelect';
import ContainerBlock from '../ContainerBlock';
import blockPreviewResolverBase from '../../../../constants/blockPreviewResolverBase';
import TextBlock, { TextBlockPreview } from '../TextBlock';
import api from '../../../../../../api';

const itemContainerId = 'item-container';

interface ResourceListBlockProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  resourceId?: ApplicationResource['_id'];
}

interface ResourceListBlockSetupProps extends ResourceListBlockProps {
  onChange: (resourceId: ApplicationResource['_id']) => void;
}

function ResourceListBlockSetup(props: ResourceListBlockSetupProps) {
  const {
    resourceId, onChange,
  } = props;

  return (
    <div>
      <AppResourceSelect
        size="small"
        value={resourceId}
        onChange={onChange}
      />
      <div className="text-center mt-2 mb-0">
        Drop components here to define how items are rendered
        <br />
        Tip: use handlebars to render a field from the item. Example:
        {' '}
        <pre>{'{{item.name}}'}</pre>
      </div>
      {/*
      // @ts-ignore */}
      <Element is={ContainerBlock} id={itemContainerId} canvas />
    </div>
  );
}

type ResourceListBlockPreviewConfiguredProps = {
  resourceId: ApplicationResource['_id'];
};

function ResourceListBlockPreviewConfigured(props: ResourceListBlockPreviewConfiguredProps) {
  const { resourceId } = props;
  const [service, setService] = useState<ApplicationResource>();
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(true);
  const [page] = useState(1);

  const loadService = async () => {
    const serviceResponse = await api.applicationResources.get(resourceId);
    setService(serviceResponse);
  };

  const loadData = async () => {
    if (service) {
      setLoading(true);
      const response = await api.appResourceInstance(service.applicationId, service.slug).find({
        query: {
          $limit: 10,
          $skip: (page - 1) * 10,
        },
      });
      setData(response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadService().then();
  }, [resourceId]);

  useEffect(() => {
    loadData().then();
  }, [Boolean(service), page]);

  const { nodeId } = useNode((node) => ({
    nodeId: node.id,
  }));

  const { query } = useEditor();

  const serviceNode = query.node(nodeId).get();
  const itemContainerNodeId = serviceNode.data.linkedNodes[itemContainerId];
  const itemContainer = query.node(itemContainerNodeId).get();

  const renderNode = (node: Node, item: Record<string, any>) => {
    const NodeRenderer: React.ComponentType<any> = blockPreviewResolverBase[node.data.name];
    if (!NodeRenderer) {
      return null;
    }

    if (node.data.name === 'TextBlock') {
      // @ts-ignore
      return (<TextBlockPreview key={node.id} item={item} {...node.data.props} />);
    }

    const { children, ...nodeProps } = node.data.props;

    return (
      <NodeRenderer
        {...nodeProps}
        key={node.id}
      >
        {node.data.nodes.map((childNodeId) => {
          const childNode = query.node(childNodeId).get();
          return renderNode(childNode, item);
        })}
      </NodeRenderer>
    );
  };

  if (loading) {
    return (<div className="d-flex justify-content-center"><Spin /></div>);
  }

  return (
    <>
      {
        data.map((item) => renderNode(itemContainer, item))
      }
    </>
  );
}

export function ResourceListBlockPreview(props: ResourceListBlockProps) {
  const { resourceId, ...rest } = props;

  if (!resourceId) {
    return null;
  }

  return (
    <div {...rest}>
      <ResourceListBlockPreviewConfigured {...props} resourceId={resourceId} />
    </div>
  );
}

export default function ResourceListBlock(props: ResourceListBlockProps) {
  const { resourceId, ...rest } = props;
  const { connectors: { connect, drag }, actions: { setProp } } = useNode((node) => ({
    nodeId: node.id,
  }));

  return (
    <div
      /*
     // @ts-ignore */
      ref={(ref) => connect(drag(ref))}
      {...rest}
    >
      <ResourceListBlockSetup
        resourceId={resourceId}
        onChange={(newServiceId) => {
          setProp((prop: { resourceId: string; }) => {
            // eslint-disable-next-line no-param-reassign
            prop.resourceId = newServiceId;
          });
        }}
      />
    </div>
  );
}
