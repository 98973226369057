/* eslint-disable arrow-body-style */

import React, { HTMLAttributes } from 'react';
import ContentEditable from 'react-contenteditable';
import { useEditor, useNode } from '@craftjs/core';

interface ButtonBlockProps extends HTMLAttributes<HTMLButtonElement> {
  text: string;
}

export function ButtonBlockPreview(props: ButtonBlockProps) {
  const { text, ...rest } = props;

  return (<button type="button" {...rest}>{text}</button>);
}

export default function ButtonBlock(props: ButtonBlockProps) {
  const { text, ...rest } = props;

  const {
    connectors: { connect, drag },
    actions,
  } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <button
      /*
      // @ts-ignore */
      ref={(ref) => connect(drag(ref))}
      {...rest}
      type="button"
    >
      <ContentEditable
        html={text}
        disabled={!enabled}
        onChange={(e) => {
          actions.setProp((prop: { text: string }) => {
            // eslint-disable-next-line no-param-reassign
            prop.text = e.target.value;
          }, 500);
        }}
        style={{ outline: 'none' }}
        tagName="span" // Use a custom HTML tag (uses a div by default)
      />
    </button>
  );
}

ButtonBlock.craft = {
  name: 'Button',
};

ButtonBlockPreview.craft = ButtonBlock.craft;
