import {
  FieldType, FieldTypeType, App, KernexPlugin,
} from '@kernex/core';
import Input from './Input';
import DetailsRenderer from './DetailsRenderer';

const imageField: FieldType = {
  type: FieldTypeType.IMAGE,
  name: 'Image',
  description: 'Images, photos',
  typescriptType: `{
    url: string;
    title?: string;
    description?: string;
  }`,
  supportsArray: true,
  Input,
  DetailsRenderer,
};

export default class ImageFieldPlugin implements KernexPlugin {
  public id = 'image-field-type';

  public name = 'image-field-type';

  public description = 'Image field type';

  // eslint-disable-next-line class-methods-use-this
  initialize(app: App) {
    app.registerFieldType(imageField);
  }
}
