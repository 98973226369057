import { useCallback, useState } from 'react';
import {
  Card,
  Col, Modal, notification, Popconfirm, Row, Tag, Typography,
} from 'antd';
import useQuery from '../../../common/hooks/useQuery';
import api from '../../../../api';
import LoadingResponse from '../../../common/components/LoadingResponse';
import Button from '../../../common/components/Button';
import AppApiKeyForm from '../AppApiKeyForm';
import AppApiKey from '../../../../api/models/AppApiKey';
import Icon from '../../../common/components/Icon';
import { capitalize } from '../../../../utils';

interface AppApiKeysProps {
  applicationId: string;
}

interface ApiKeyItemProps extends Pick<AppApiKey, 'name' | 'permissions' | 'origins'> {
  onDelete: () => void;
}

function ApiKeyItem(props: ApiKeyItemProps) {
  const {
    name, permissions, onDelete, origins,
  } = props;

  return (
    <Card size="small">
      <Row>
        <Col span={24} md={8} className="d-flex align-items-center">
          <div>
            <p className="m-0">{name}</p>
            <div>
              {
                origins ? (
                  <div>
                    {
                      origins.map((origin) => (
                        <Tag key={origin} color="default">{origin}</Tag>
                      ))
                    }
                  </div>
                ) : <div className="text-muted">Origins: *</div>
              }
            </div>
          </div>
        </Col>
        <Col span={24} md={8} className="d-flex justify-content-center align-items-center">
          {
            permissions.map((permission) => (
              <Tag key={permission} color="blue">{capitalize(permission.toLowerCase())}</Tag>
            ))
          }
          {
            permissions.length === 0 && (
              <p className="m-0">No permissions</p>
            )
          }
        </Col>
        <Col span={24} md={8} className="d-flex justify-content-end align-items-center">
          <Popconfirm title={`Are you sure you want to delete ${name}?`} onConfirm={onDelete}>
            <Button type="default" size="small" danger>
              <Icon name="delete" />
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Card>
  );
}

export default function AppApiKeys(props: AppApiKeysProps) {
  const { applicationId } = props;
  const [showNewKeyForm, setShowNewKeyForm] = useState(false);
  const [newKey, setNewKey] = useState<AppApiKey | null>(null);

  const loadKeys = useCallback(async () => {
    const response = await api.appApiKeys.find({
      query: {
        applicationId,
        $limit: 100,
        $select: ['name', 'permissions', 'origins'],
      },
    });

    return response.data;
  }, [applicationId]);

  const [keys, loading, error, setKeys] = useQuery(loadKeys);

  const deleteKey = async (id: AppApiKey['_id']) => {
    try {
      await api.appApiKeys.remove(id);
      setKeys((prev) => (prev || []).filter((key) => key._id !== id));
      notification.success({
        message: 'API Key Removed',
      });
    } catch (e) {
      notification.error({
        message: 'Something went wrong',
        description: 'Please try again',
      });
    }
  };

  if (!keys || loading || error) {
    return (
      <LoadingResponse loading={loading} error={error} />
    );
  }

  return (
    <Row gutter={[16, 16]}>
      {
        keys.map((key) => (
          <Col span={24} key={key._id}>
            <ApiKeyItem
              name={key.name}
              permissions={key.permissions}
              onDelete={() => {
                deleteKey(key._id).then();
              }}
              origins={key.origins}
            />
          </Col>
        ))
      }
      {
        keys.length === 0 && (
          <Col span={24}>
            <p>You have no API Keys</p>
          </Col>
        )
      }
      <Col span={24} lg={12}>
        {
          showNewKeyForm ? (
            <AppApiKeyForm
              applicationId={applicationId}
              onSuccess={(key) => {
                setNewKey(key);
                setKeys((prevState) => [...(prevState || []), key]);
                setShowNewKeyForm(false);
              }}
              onCancel={() => {
                setShowNewKeyForm(false);
              }}
            />
          ) : (
            <Button
              type="primary"
              onClick={() => setShowNewKeyForm(true)}
            >
              New API Key
            </Button>
          )
        }
      </Col>
      <Modal
        title="Your new API Key"
        open={!!newKey}
        onCancel={() => {
          setNewKey(null);
        }}
        onOk={() => {
          setNewKey(null);
        }}
      >
        {
          newKey && (
            <>
              <p>
                This is your new API Key, please save it and keep it safe.
              </p>
              <p>
                <b>
                  We will not show you this key again, you will have to create a new one if you loose it
                </b>
              </p>
              <Typography.Paragraph strong copyable>
                {newKey.key}
              </Typography.Paragraph>
            </>
          )
        }
      </Modal>
    </Row>
  );
}
