import {
  Select, Form, Input, notification,
} from 'antd';
import AppApiKey, { AppApiKeyPermission } from '../../../../api/models/AppApiKey';
import api from '../../../../api';
import { capitalize } from '../../../../utils';
import useAsyncCall from '../../../common/hooks/useAsyncCall';
import Button from '../../../common/components/Button';
import { setFormErrorsFromServer } from '../../../common/utils/errors';

interface AppApiKeyFormProps {
  applicationId: string;
  onSuccess?: (apiKey: AppApiKey) => void;
  onCancel?: () => void;
}

const permissionsOptions = Object.values(AppApiKeyPermission).map((permission) => ({
  label: capitalize(permission.toLowerCase()),
  value: permission,
}));

export default function AppApiKeyForm(props: AppApiKeyFormProps) {
  const { applicationId, onSuccess, onCancel } = props;
  const [form] = Form.useForm();

  const [onFinish, loading] = useAsyncCall(async (values: Omit<AppApiKey, '_id'>) => api.appApiKeys.create({
    ...values,
    applicationId,
  }), {
    onError: (e) => {
      setFormErrorsFromServer(e, form);
    },
    onSuccess: (apikey) => {
      notification.success({
        message: 'Api key created',
      });
      if (onSuccess) {
        onSuccess(apikey);
      }
    },
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        permissions: [],
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter API Key name' }]}
      >
        <Input placeholder="API Key Name" />
      </Form.Item>
      <Form.Item label="Permissions" name="permissions">
        <Select
          mode="multiple"
          options={permissionsOptions}
          placeholder="Select one or more permissions"
        />
      </Form.Item>
      <Form.Item label="Allowed Origins" name="origins">
        <Select
          mode="tags"
          placeholder="Enter one or more allowed origins, you can use * as wildcard"
          style={{ width: '100%' }}
          options={[]}
          popupClassName="d-none"
        />
      </Form.Item>
      <Form.Item noStyle>
        <div className="d-flex justify-content-center">
          <Button className="me-2" onClick={onCancel}>Cancel</Button>
          <Button loading={loading} type="primary" htmlType="submit">Save</Button>
        </div>
      </Form.Item>
    </Form>
  );
}
