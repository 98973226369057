import { CodeBlock, monokai as theme } from 'react-code-blocks';

export interface AppDocsCodeBlockProps {
  language: 'bash' | 'javascript' | 'typescript' | 'python' | 'php' | 'json';
  code: string;
}

export default function AppDocsCodeBlock(props: AppDocsCodeBlockProps) {
  const { language, code } = props;

  return (
    <CodeBlock
      text={code}
      language={language}
      theme={theme}
      customStyle={{
        minHeight: '40px',
        fontFamily: '"Roboto Mono",sfmono-regular,consolas,liberation mono,menlo,courier,monospace',
        overflow: 'auto',
        width: '100%',
        borderRadius: '4px',
      }}
      showLineNumbers={language !== 'json'}
    />
  );
}
