import { Form, Input } from 'antd';
import { User } from '@kernex/common';
import useAuth from '../../../authentication/hooks/useAuth';
import Button from '../../../common/components/Button';
import useFormSubmit from '../../../common/hooks/useFormSubmit';
import api from '../../../../api';

export default function UserAccountForm() {
  const { user, authenticate } = useAuth();
  const [form] = Form.useForm();

  const [onFinish, loading] = useFormSubmit(async (values: Pick<User, 'name'>) => {
    if (user?._id) {
      await api.users.patch(user._id, values);
      await authenticate(true);
    }
  }, { form, successMessage: 'Account updated' });

  return (
    <Form
      layout="vertical"
      initialValues={{
        name: user?.name,
        email: user?.email,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please input your email!' },
          { type: 'email', message: 'Please input a valid email!' },
        ]}
      >
        <Input placeholder="Your email" />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: 'Please input your name' },
        ]}
      >
        <Input placeholder="Your name" />
      </Form.Item>
      <Form.Item noStyle>
        <Button htmlType="submit" type="primary" loading={loading}>Save</Button>
      </Form.Item>
    </Form>
  );
}
