import { useState } from 'react';

export type UseAsyncCall<Response, T extends any[]> = [
  (...args: T) => Promise<Response>,
  boolean,
  Response | null,
];

export type UseAsyncCallOptions<Response> = {
  onSuccess?: (response: Response) => void;
  onError?: (error: unknown) => void;
};

export default function useAsyncCall<Response, T extends any[]>(
  fn: (...args: T) => Promise<Response>,
  options: UseAsyncCallOptions<Response> = {},
): UseAsyncCall<Response, T> {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Response | null>(null);

  const call = async (...args: T): Promise<Response> => {
    setLoading(true);
    setResponse(null);
    const { onSuccess, onError } = options;

    try {
      const result = await fn(...args);
      setLoading(false);
      if (onSuccess) onSuccess(result);
      setResponse(result);
      return result;
    } catch (error) {
      if (onError) onError(error);
      setLoading(false);
      throw error;
    }
  };

  return [call, loading, response];
}
