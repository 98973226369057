import {
  FieldType, FieldTypeType, App, KernexPlugin, FieldValidator,
} from '@kernex/core';
import Input from './Input';
import DetailsRenderer from './DetailsRenderer';

const linkValidator: FieldValidator = {
  fieldName: 'link',
  validate: (value: any) => {
    if (!Array.isArray(value)) {
      throw new Error('Value is not an array');
    } else {
      value.forEach((link: any, index) => {
        if (!link.url) {
          throw new Error(`Link ${index} has no url`);
        } else if (!link.title) {
          throw new Error(`Link ${index} has no title`);
        } else if (!link.agencyId) {
          throw new Error(`Link ${index} has no agency`);
        }
      });
    }
  },
  Input: () => null,
};

const titlesLinkField: FieldType = {
  type: FieldTypeType.TITLES_LINK,
  name: 'Titlurile.ro Links',
  description: 'A custom links field for titlurile.ro project',
  applicationId: '62b18c10fbdaa250aeb46057',
  typescriptType: `{
    agencyId: string;
    agency: {
      title: string;
      image: {
        url: string;
        title?: string;
        description?: string;
      };
    };
    url: string;
    title: string;
    timestamp: Date;
    description?: string;
  }[]`,
  Input,
  DetailsRenderer,
  validators: [linkValidator],
};

export default class TitlesLinkFieldPlugin implements KernexPlugin {
  public id = 'titles-link-field-type';

  public name = 'titles-link-field-type';

  public description = 'Titlurile.ro Links field type';

  // eslint-disable-next-line class-methods-use-this
  initialize(app: App) {
    app.registerFieldType(titlesLinkField);
  }
}
