import { useRecoilValue } from 'recoil';
import PageContent from '../../../layout/PageContent';
import AppActions from '../../components/AppActions';
import appsAtom from '../../../apps/state';

export default function AppActionsPage() {
  const { app } = useRecoilValue(appsAtom);

  return (
    <PageContent title="App Actions">
      {
        app && (
          <AppActions appId={app._id} />
        )
      }
    </PageContent>
  );
}
