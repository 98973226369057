// A function to return the singular form of a word
export const singular = (word: string): string => {
  const lowerPlural = word.toLowerCase();
  if (lowerPlural.endsWith('ies')) {
    return `${word.substring(0, word.length - 3)}y`;
  }
  if (lowerPlural.endsWith('s')) {
    return word.substring(0, word.length - 1);
  }
  return word;
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

// A function to convert a string from snake_case, kebab-case and camelCase to sentence case
export function sentenceCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .replace(/[-_\s]+/g, ' ')
    .replace(/^\w/, (c) => c.toUpperCase());
}

export function camelCase(str: string) {
  return str.replace(
    /(?:^\w|[A-Z]|\b\w)/g,
    (word: string, index: number) => (index === 0 ? word.toLowerCase() : word.toUpperCase()),
  ).replace(/\s+/g, '');
}

export function truncate(s?: string, maxCharacters = 14) {
  if (!s || s.length <= maxCharacters) {
    return s;
  }

  return `${s.slice(0, maxCharacters)}...`;
}
