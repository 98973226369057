import {
  NodeId, NodeTree, useEditor, useNode,
} from '@craftjs/core';
import { useEffect } from 'react';
import NodeComponent from '../../../../../../api/models/NodeComponent';

interface UserComponentBlockProps {
  nodeComponent: NodeComponent;
}

export default function UserComponentBlock(props: UserComponentBlockProps) {
  const { nodeComponent } = props;
  const { query, actions } = useEditor();
  const { id } = useNode();

  useEffect(() => {
    if (nodeComponent) {
      const parentId = query.node(id).get().data.parent;

      const { nodes } = nodeComponent;
      const parentNode = query.node(parentId).get();
      const indexToAdd = parentNode.data.nodes.indexOf(id);

      const rootNodeId = Object.keys(nodes)
        .find((nodeId) => nodes[nodeId].custom.isNodeComponentRoot);

      if (rootNodeId) {
        const tree: NodeTree = {
          rootNodeId,
          nodes: {},
        };

        const newIdsMap: Record<NodeId, NodeId> = {};

        Object.keys(nodes).forEach((nodeId) => {
          const serializedNode = { ...nodes[nodeId] };
          if (nodeId === rootNodeId) {
            serializedNode.parent = parentId;
          }
          const node = query.parseSerializedNode(serializedNode).toNode();
          newIdsMap[nodeId] = node.id;
          tree.nodes[node.id] = node;
        });

        const treeNodes: NodeTree['nodes'] = {};
        // Replace old node ids with new ones
        // otherwise when adding this component to the editor, the nodes will we duplicated and it will result
        // in an error
        Object.values(tree.nodes).forEach((node) => {
          const newNode = { ...node };
          const { data } = newNode;

          if (data.parent) {
            data.parent = newIdsMap[data.parent] || data.parent;
          }

          if (data.nodes) {
            data.nodes = data.nodes.map((nodeId) => newIdsMap[nodeId] || nodeId);
          }

          newNode.data = data;
          treeNodes[newNode.id] = newNode;
        });

        tree.nodes = treeNodes;
        tree.rootNodeId = newIdsMap[tree.rootNodeId] || tree.rootNodeId;

        actions.addNodeTree(tree, parentId, indexToAdd);
        actions.delete(id); // Delete the node as we no longer need it
      }
    }
  }, []);

  return null;
}

export const createUserComponentBlock = (nodeComponent: NodeComponent) => (
  <UserComponentBlock
    nodeComponent={nodeComponent}
  />
);
