import { Outlet, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Spin } from 'antd';
import api from '../../../../api';
import appAdminAtom from '../../state';
import appsAtom from '../../../apps/state';

export default function AppAdminResourcePage() {
  const [{ loading, service }, setState] = useRecoilState(appAdminAtom);
  const { app } = useRecoilValue(appsAtom);
  const { serviceSlug } = useParams<{ serviceSlug: string }>();

  const appId = app?._id;

  const loadService = async () => {
    if (appId) {
      setState((state) => ({ ...state, loading: true }));

      try {
        const response = await api.applicationResources.findOne({ query: { slug: serviceSlug, applicationId: appId } });
        const fields = await api.fields.find({ query: { resourceId: response._id, $limit: 100 } });
        setState((state) => ({
          ...state,
          loading: false,
          service: response,
          fields: fields.data,
        }));
      } catch (e) {
        // Ignore
      }

      setState((state) => ({ ...state, loading: false }));
    }
  };

  useEffect(() => {
    loadService().then();
  }, [serviceSlug, appId]);

  if (loading || !service) {
    return (
      <div className="h-100 v-100 d-flex align-items-center justify-content-center">
        {
          loading ? <Spin size="large" /> : <h1>Resource not found</h1>
        }
      </div>
    );
  }

  return (
    <Outlet />
  );
}
