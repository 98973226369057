import { Layout, Menu, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { Application, ApplicationResource, Organization } from '@kernex/common';
import { Link } from 'react-router-dom';
import { Field, FieldRelationType, FieldTypeType } from '@kernex/core';
import {
  randNumber, randParagraph, randUuid, randWord,
} from '@ngneat/falso';
import { CurlGenerator } from 'curl-generator';
import { API_URL } from '../../../../global/constants';
import AppDocsPage from './AppDocsPage';
import AppDocsPageSection from './AppDocsPageSection';
import AppDocsCodeBlockTabs from './AppDocsCodeBlockTabs';
import Button from '../../../common/components/Button';
import AppDocsCodeBlock from './AppDocsCodeBlock';
import styles from './styles.module.css';
import api from '../../../../api';

interface AppDocsProps {
  organization: Organization;
  app: Application;
  resources: ApplicationResource[];
}

type ResourceActionParam = {
  name: string;
  type: 'string' | 'number' | 'object' | 'string[]';
  required?: boolean;
  description?: string;
};

type ResourceAction = {
  label: string;
  key: string;
  method: 'CREATE' | 'GET' | 'FIND' | 'PATCH' | 'DELETE';
  httpMethod: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  url: string;
  description: string;
  hasData?: boolean;
  dataParamsOptional?: boolean;
  params?: ResourceActionParam[];
  urlParams?: ResourceActionParam[];
};

const resourceActions: ResourceAction[] = [
  {
    label: 'Create',
    key: 'create',
    method: 'CREATE',
    httpMethod: 'POST',
    url: '',
    description: 'Create a new resource entry',
    hasData: true,
  },
  {
    label: 'Get',
    key: 'get',
    method: 'GET',
    httpMethod: 'GET',
    url: '/:resourceId',
    description: 'Get a single resource entry',
    urlParams: [
      {
        name: 'resourceId',
        type: 'string',
        required: true,
        description: 'The ID of the resource entry',
      },
    ],
  },
  {
    label: 'Find (list)',
    key: 'find',
    method: 'FIND',
    httpMethod: 'GET',
    url: '',
    description: 'Find (list) resource entries',
    params: [
      {
        name: '$limit',
        required: false,
        description: 'The number of results to return. Default: 10',
        type: 'number',
      },
      {
        name: '$skip',
        required: false,
        description: 'The number of results to skip. Default: 0',
        type: 'number',
      },
      {
        name: '$sort',
        required: false,
        description: 'An object with the field to sort by. Example: {createdAt: -1}',
        type: 'object',
      },
      {
        name: '$select',
        required: false,
        description: 'An array of fields to select. Example: ["name", "createdAt"]',
        type: 'string[]',
      },
    ],
  },
  {
    label: 'Patch',
    key: 'patch',
    method: 'PATCH',
    httpMethod: 'PATCH',
    url: '/:resourceId',
    description: 'Patch (update) a resource entry',
    hasData: true,
    dataParamsOptional: true,
    urlParams: [
      {
        name: 'resourceId',
        type: 'string',
        required: true,
        description: 'The ID of the resource entry',
      },
    ],
  },
  {
    label: 'Delete',
    key: 'delete',
    method: 'DELETE',
    httpMethod: 'DELETE',
    url: '/:resourceId',
    description: 'Delete a resource entry',
    urlParams: [
      {
        name: 'resourceId',
        type: 'string',
        required: true,
        description: 'The ID of the resource entry',
      },
    ],
  },
];

type FieldTypeMeta = {
  baseType: 'string' | 'number' | 'boolean' | 'array' | 'object';
  generateExample: () => any;
};

const fieldTypeMeta: Record<FieldTypeType, FieldTypeMeta> = {
  [FieldTypeType.TEXT]: {
    baseType: 'string',
    generateExample: () => randWord(),
  },
  [FieldTypeType.RICH_TEXT]: {
    baseType: 'string',
    generateExample: () => randParagraph({ length: 2 }).join('\n'),
  },
  [FieldTypeType.NUMBER]: {
    baseType: 'number',
    generateExample: () => Math.floor(Math.random() * 100),
  },
  [FieldTypeType.BOOLEAN]: {
    baseType: 'boolean',
    generateExample: () => Math.random() > 0.5,
  },
  [FieldTypeType.DATE]: {
    baseType: 'string',
    generateExample: () => new Date().toISOString(),
  },
  [FieldTypeType.RELATION]: {
    baseType: 'string',
    generateExample: () => 'relatedResourceId',
  },
  [FieldTypeType.IMAGE]: {
    baseType: 'string',
    generateExample: () => 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQC',
  },
  [FieldTypeType.COLOR]: {
    baseType: 'string',
    generateExample: () => '#000000',
  },
  [FieldTypeType.TIMESTAMP]: {
    baseType: 'string',
    generateExample: () => new Date().toISOString(),
  },
  [FieldTypeType.SLUG]: {
    baseType: 'string',
    generateExample: () => randWord(),
  },
  [FieldTypeType.TITLES_LINK]: {
    baseType: 'string',
    generateExample: () => 'https://example.com',
  },
};

interface ResourceActionCodeBlocksProps {
  resource: ApplicationResource;
  fields: Field[];
  action: ResourceAction;
  url: string;
}

function generateObject(fields: Field[], includeProtected = false) {
  const body: Record<string, any> = {};

  fields.forEach((field) => {
    if ((!field.isProtected && !field.autogenerated) || includeProtected) {
      const fieldMeta = fieldTypeMeta[field.type];
      const isArray = field.isArray || field.relationType === FieldRelationType.ONE_TO_MANY;

      if (field.name === '_id' || field.type === FieldTypeType.RELATION) {
        if (isArray) {
          body[field.name] = randUuid({ length: randNumber({ min: 1, max: 5 }) });
        } else {
          body[field.name] = randUuid();
        }
      } else if (isArray) {
        body[field.name] = [
          fieldMeta.generateExample(),
          fieldMeta.generateExample(),
          fieldMeta.generateExample(),
          fieldMeta.generateExample(),
          fieldMeta.generateExample(),
        ];
      } else {
        body[field.name] = fieldMeta.generateExample();
      }
    }
  });

  return body;
}

function getCurlCode(
  resource: ApplicationResource,
  url: string,
  fields: Field[],
  action: ResourceAction,
) {
  const params: Record<string, any> = {
    url,
    method: action.httpMethod,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'x-api-key': 'your-api-key',
    },
  };

  if (action.hasData) {
    params.body = generateObject(fields);
  }

  // @ts-ignore
  return CurlGenerator(params);
}

function getJavascriptCode(
  resource: ApplicationResource,
  fields: Field[],
  action: ResourceAction,
) {
  const res = `client.resource('${resource.slug}')`;
  const body = JSON.stringify(generateObject(fields), null, 2);

  if (action.method === 'CREATE') {
    return `${res}.create(${body});`;
  }

  if (action.method === 'GET') {
    return `${res}.get('resourceId');`;
  }

  if (action.method === 'FIND') {
    const findQuery = {
      $limit: 10,
      $skip: 0,
      $sort: { createdAt: -1 },
      $select: fields.map((field) => field.name),
    };
    return `${res}.find(${JSON.stringify(findQuery, null, 2)}});`;
  }

  if (action.method === 'PATCH') {
    return `${res}.patch('resourceId', ${body});`;
  }

  if (action.method === 'DELETE') {
    return `${res}.delete('resourceId');`;
  }

  return '// Coming Soon';
}

function ResourceActionCodeBlocks(props: ResourceActionCodeBlocksProps) {
  const {
    resource, fields, action, url,
  } = props;

  const curl = getCurlCode(resource, `${url}${action.url}`, fields, action);
  const javascript = getJavascriptCode(resource, fields, action);

  return (
    <AppDocsCodeBlockTabs
      tabs={[
        {
          title: 'cURL',
          language: 'bash',
          code: curl,
        },
        {
          title: 'JavaScript',
          language: 'javascript',
          code: javascript,
        },
        // {
        //   title: 'PHP',
        //   language: 'php',
        //   code: '// Coming Soon',
        // },
        // {
        //   title: 'Python',
        //   language: 'python',
        //   code: '# Coming Soon',
        // },
      ]}
    />
  );
}

interface ParamsSectionProps {
  title: string;
  params: {
    name: string;
    type: string;
    // tags: Pick<TagProps, 'title' | 'color'>[];
    required?: boolean;
    description?: string;
  }[];
}

function ParamsSection(props: ParamsSectionProps) {
  const { title, params } = props;

  return (
    <div className="mt-5">
      <h5 className="border-bottom pb-3">{title}</h5>
      {
        params.map((param) => (
          <div key={param.name} className="border-bottom py-2">
            <div className="d-flex align-items-center mb-1 gap-2">
              <p style={{ fontWeight: 600 }}>{param.name}</p>
              <Tag color="green">
                {param.type}
              </Tag>
              {
                param.required && (
                  <Tag color="magenta">
                    required
                  </Tag>
                )
              }
            </div>
            {
              param.description && (
                <p style={{ fontSize: 13 }}>{param.description}</p>
              )
            }
          </div>
        ))
      }
    </div>
  );
}

function getActionResponseCode(action: ResourceAction, fields: Field[]) {
  const { key } = action;

  if (key === 'find') {
    return {
      total: 100,
      limit: 10,
      skip: 0,
      data: [
        generateObject(fields, true),
        generateObject(fields, true),
      ],
    };
  }

  return generateObject(fields, true);
}

export default function AppDocs(props: AppDocsProps) {
  const { organization, app, resources } = props;
  const [openKeys, setOpenKeys] = useState<string[]>(['introduction']);
  const [fields, setFields] = useState<Record<ApplicationResource['_id'], Field[]>>({});

  const resourceIds = resources.map((resource) => resource._id);

  useEffect(() => {
    api.fields.find({
      query: {
        $limit: 100,
        resourceId: { $in: resourceIds },
      },
    }).then((response) => {
      const fieldMap: Record<ApplicationResource['_id'], Field[]> = {};

      response.data.forEach((field) => {
        if (!fieldMap[field.resourceId]) {
          fieldMap[field.resourceId] = [];
        }

        fieldMap[field.resourceId].push(field);
      });

      setFields(fieldMap);
    });
  }, [resourceIds.join('')]);

  const generateApiKeyLink = `/app/organizations/${organization.slug}/apps/${app.slug}/api-keys`;
  const baseUrl = `${API_URL}/api/v1/${app?._id}`;

  return (
    <Layout className="h-100">
      <Layout.Sider width={200} style={{ backgroundColor: 'white', height: '100%' }}>
        <Menu
          items={[
            {
              label: <a href="#introduction">Introduction</a>,
              key: 'introduction',
            },
            {
              label: <a href="#authorization">Authorization</a>,
              key: 'Authorization',
            },
            { type: 'divider' },
            {
              type: 'group',
              key: 'resources',
              label: 'Resources',
              children: resources.map((resource) => ({
                key: `resource-${resource.slug}`,
                label: resource.name,
                children: resourceActions.map((action) => ({
                  key: `resource-${resource.slug}-${action.key}`,
                  label: (
                    <a href={`#resource/${resource.slug}/${action.key}`}>
                      {action.label}
                      {' '}
                      {resource.name}
                    </a>),
                })),
              })),
            },
          ]}
          style={{ height: '100%' }}
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
          mode="inline"
        />
      </Layout.Sider>
      <Layout.Content
        style={{ height: '100vh', overflow: 'auto', gap: 10 }}
        className={`d-flex flex-column p-4 ${styles.root}`}
      >
        <AppDocsPage title="Introduction" id="introduction">
          <AppDocsPageSection title="Overview" isFirstChild>
            <p>
              This is the documentation for your app API.
              {' '}
              It is valid only for this app and is not shared with other apps.
            </p>
          </AppDocsPageSection>
          <AppDocsPageSection title="Using the API">
            <p>
              You can write, read, update, and delete data using either the admin panel or the API.
            </p>
            <p>
              You can use either REST API or one of our SDKs to access the API.
            </p>
          </AppDocsPageSection>
          <AppDocsPageSection title="Installation">
            <p>
              In order to install the SDK, you need to run the following command:
            </p>
            <AppDocsCodeBlockTabs
              tabs={[
                {
                  title: 'npm',
                  language: 'bash',
                  code: 'npm install @kernex/client',
                },
                {
                  title: 'yarn',
                  language: 'bash',
                  code: 'yarn add @kernex/client',
                },
              ]}
            />
          </AppDocsPageSection>
        </AppDocsPage>
        <AppDocsPage title="Authorization" id="authorization">
          <AppDocsPageSection title="Overview" isFirstChild>
            <p>
              First of all, you will need an API Key to access the API.
              {' '}
              Press the button below to generate a new API Key.
            </p>
            <p>
              <Link to={generateApiKeyLink} target="_blank">
                <Button>Generate API Key</Button>
              </Link>
            </p>
            <p>
              Once you have the API Key, you can use it to access the API.
            </p>
          </AppDocsPageSection>
          <AppDocsPageSection title="Rest API Authorization">
            <p>
              All your rest API request should include the api key in the
              {' '}
              <b>x-api-key</b>
              {' '}
              header.
            </p>
            <AppDocsCodeBlock language="bash" code="x-api-key: your-api-key" />
          </AppDocsPageSection>
          <AppDocsPageSection title="Javascript Client Authorization">
            <p>
              Authorization is done automatically when you initialize the client.
            </p>
            <AppDocsCodeBlockTabs
              tabs={[
                {
                  title: 'Javascript',
                  language: 'javascript',
                  code: `import kernex from '@kernex/client';
const client = kernex({
  appUrl: '${baseUrl}',
  appApiKey: 'your-api-key', // Replace this with your api key
});`,
                },
                {
                  title: 'Typescript',
                  language: 'typescript',
                  code: `import kernex from '@kernex/client';
const client = kernex<any>({
  appUrl: '${baseUrl}',
  appApiKey: 'your-api-key', // Replace this with your api key
});`,
                },
              ]}
            />
          </AppDocsPageSection>
        </AppDocsPage>
        {
          resources.map((resource) => {
            const resourceEndpoint = `${baseUrl}/resource/${resource.slug}`;
            const resourceFields = fields[resource._id] || [];

            return (
              <AppDocsPage
                key={resource._id}
                title={resource.name}
                id={`resource/${resource.slug}`}
              >
                <AppDocsPageSection title="Overview" isFirstChild>
                  <p>Overview</p>
                </AppDocsPageSection>
                {
                  resourceActions.map((action) => (
                    <AppDocsPageSection
                      key={action.key}
                      title={`${action.label} ${resource.name}`}
                      id={`resource/${resource.slug}/${action.key}`}
                    >
                      <div className="d-flex justify-content-between">
                        <div style={{ flex: 1, overflow: 'hidden' }} className="d-flex flex-column gap-3 me-5">
                          <span>
                            <Tag
                              color="blue"
                              style={{ whiteSpace: 'pre-wrap', maxWidth: '100%', wordWrap: 'break-word' }}
                            >
                              {`${action.method} ${resourceEndpoint}${action.url}`}
                            </Tag>
                          </span>
                          <p>{action.description}</p>
                          {
                            action.urlParams && (
                              <ParamsSection title="URL Params" params={action.urlParams} />
                            )
                          }
                          {
                            action.hasData && (
                              <ParamsSection
                                title="Data"
                                params={resourceFields.filter((field) => !field.isProtected).map((field) => {
                                  const fieldMeta = fieldTypeMeta[field.type];

                                  let type = fieldMeta.baseType;
                                  if (field.isArray || field.relationType === FieldRelationType.ONE_TO_MANY) {
                                    type += '[]';
                                  }

                                  return {
                                    name: field.name,
                                    type,
                                    required: field.required && !action.dataParamsOptional,
                                    description: field.description,
                                  };
                                })}
                              />
                            )
                          }
                          {
                            action.params && (
                              <ParamsSection title="Params" params={action.params} />
                            )
                          }
                        </div>
                        <div className={styles.codeBlockContainer}>
                          <ResourceActionCodeBlocks
                            resource={resource}
                            fields={resourceFields}
                            action={action}
                            url={resourceEndpoint}
                          />

                          <p className="mt-5 mb-2">Response Example</p>
                          <AppDocsCodeBlock
                            language="json"
                            code={JSON.stringify(getActionResponseCode(action, resourceFields), null, 2)}
                          />
                        </div>
                      </div>
                    </AppDocsPageSection>
                  ))
                }
              </AppDocsPage>
            );
          })
        }
      </Layout.Content>
    </Layout>
  );
}
