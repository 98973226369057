import { useEffect, useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import {
  Application,
  ApplicationResource,
} from '@kernex/common';
import { Field, FieldType } from '@kernex/core';
import api from '../../../../api';
import { camelCase, capitalize, singular } from '../../../../utils';
import SelectBoxInput from '../../../common/components/SelectBoxInput';
import { API_URL, APP_URL } from '../../../../global/constants';
import { fieldHelper } from '../../../field-types/utils/FieldHelper';
import { useFieldTypes } from '../../../../plugins';

interface AppCodeProps {
  app: Application;
  organizationSlug: string;
}

function getTypescriptInterface(
  resourceName: string,
  fields: Field[],
  fieldTypes: FieldType[],
) {
  const pieces = [`export interface ${resourceName} {`];

  pieces.push(...fields.map((field) => fieldHelper(field, fields, fieldTypes).getFieldInterfaceCode()));

  pieces.push('}');

  return pieces.join('\n');
}

function getCode(
  resources: ApplicationResource[],
  fields: Field[],
  language: string,
  apiKeyUrl: string,
  appApiUrl: string,
  fieldTypes: FieldType[],
) {
  if (language === 'javascript') {
    return `import kernexClient from '@kernex/client';
    
// Get your app api key from ${apiKeyUrl}
const KERNEX_APP_API_KEY = 'your_api_key';
const client = kernexClient({
  appUrl: '${appApiUrl}',
  appApiKey: KERNEX_APP_API_KEY,
});

`;
  }

  return `import kernexClient from '@kernex/client';

${resources.map((resource) => {
    const resourceName = capitalize(camelCase(singular(resource.name)));
    const resourceFields = fields.filter((field) => field.resourceId === resource._id);
    return getTypescriptInterface(resourceName, resourceFields, fieldTypes);
  }).join('\n\n')
}

type Resources = {
  ${resources.map((resource) => {
    const resourceName = capitalize(camelCase(singular(resource.name)));
    const { slug } = resource;
    return `${/^[a-z0-9]+$/i.test(slug) ? slug : `'${slug}'`}: ${resourceName};`;
  }).join('\n  ')}
};

// Get your app api key from ${apiKeyUrl}
const KERNEX_APP_API_KEY = 'your_api_key';
const client = kernexClient<Resources>({
  appUrl: '${appApiUrl}',
  appApiKey: KERNEX_APP_API_KEY,
});

`;
}

const languages = [
  { label: 'Typescript', value: 'typescript' },
  { label: 'Javascript', value: 'javascript' },
];

export default function AppCode(props: AppCodeProps) {
  const { app, organizationSlug } = props;
  const [language, setLanguage] = useState(languages[0].value);
  const fieldTypes = useFieldTypes();

  const [resources, setResources] = useState<ApplicationResource[]>([]);
  const [fields, setFields] = useState<Field[]>([]);

  const loadData = async () => {
    const resourcesResponse = await api.applicationResources.find({
      query: {
        $select: ['_id', 'name', 'slug'],
        $limit: 100,
        applicationId: app._id,
      },
    });

    const fieldsResponse = await api.fields.find({
      query: {
        resourceId: { $in: resourcesResponse.data.map((resource) => resource._id) },
        $limit: 100,
        $select: [
          'name', 'slug', 'resourceId', 'required', 'type', 'autogenerated', 'relationType', 'relatedFieldId',
          'isArray',
        ],
      },
    });

    setResources(resourcesResponse.data);
    setFields(fieldsResponse.data);
  };

  useEffect(() => {
    loadData().then();
  }, [app._id]);

  const apiKeyUrl = `${APP_URL}/app/organizations/${organizationSlug}/apps/${app.slug}`;
  const appApiUrl = `${API_URL}/api/v1/${app._id}`;

  return (
    <>
      <div className="mb-3 d-flex align-items-start">
        <SelectBoxInput
          options={languages}
          value={language}
          onChange={setLanguage}
        />
      </div>
      <CopyBlock
        text={getCode(
          resources,
          fields,
          language,
          apiKeyUrl,
          appApiUrl,
          fieldTypes,
        )}
        language={language}
        theme={dracula}
        wrapLines
      />
    </>
  );
}
