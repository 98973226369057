import { Application, Plan } from '@kernex/common';
import { useEffect, useState } from 'react';
import api from '../../../api';

const RECORDS_LIMIT_THRESHOLD = 95;
const USERS_LIMIT_THRESHOLD = 80;

export default function useAppUsage(app?: Application, plan?: Plan) {
  const [totalUsers, setTotalUsers] = useState<number>();

  useEffect(() => {
    if (app) {
      const loadUsers = async () => {
        const response = await api.appUsers.count({
          query: {
            appId: app._id,
          },
        });

        setTotalUsers(response);
      };

      loadUsers().then();
    }
  }, [app?._id]);

  let recordsPercent = 0;
  let usersPercent = 0;

  if (app) {
    if (plan && plan.maxRecordsPerApp) {
      recordsPercent = (app.usage.recordsCount / plan.maxRecordsPerApp) * 100;
    }
  }

  if (totalUsers !== undefined && plan && plan.maxUsersPerApp) {
    usersPercent = (totalUsers / plan.maxUsersPerApp) * 100;
  }

  return {
    recordsPercent,
    recordsLimitClose: recordsPercent >= RECORDS_LIMIT_THRESHOLD,
    recordsLimitReached: plan && app && plan.maxRecordsPerApp && app.usage.recordsCount >= plan.maxRecordsPerApp,
    totalUsers: totalUsers || 0,
    usersPercent,
    usersLimitClose: usersPercent >= USERS_LIMIT_THRESHOLD,
    usersLimitReached: plan && plan.maxUsersPerApp && totalUsers && totalUsers >= plan.maxUsersPerApp,
  };
}
