import { ReactElement } from 'react';
import { Spin } from 'antd';
import { Navigate } from 'react-router-dom';
import useAuth from '../../authentication/hooks/useAuth';

interface PageProps {
  children: ReactElement;
  usersOnly?: boolean;
  guestsOnly?: boolean;
}

export default function Page(props: PageProps) {
  const { children = null, usersOnly, guestsOnly } = props;
  const { user, loading } = useAuth();

  if (usersOnly || guestsOnly) {
    if (loading) {
      return (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      );
    }

    if (usersOnly && !user) {
      return (<Navigate to="/sign-in" replace />);
    }

    if (guestsOnly && user) {
      return (<Navigate to="/app" replace />);
    }
  }

  return children;
}
