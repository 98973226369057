import {
  Col, Modal, notification, Row, Tabs,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { ApplicationResource } from '@kernex/common';
import api from '../../../../api';
import AppResourceGeneralInfo from '../../components/AppResourceGeneralInfo';
import AppResourceFields from '../AppResourceFields';
import AppResourceEdit from '../../components/AppResourceEdit';
import PageContent from '../../../layout/PageContent';
import Button from '../../../common/components/Button';
import appsAtom from '../../../apps/state';
import AppResourceMethods from '../../components/AppResourceMethods';
import AppResourceIntegrations from '../../components/AppResourceIntegrations';

export default function AppResourcePage() {
  const { appServiceSlug } = useParams<{ appServiceSlug: string }>();
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState<ApplicationResource | null>(null);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [{ app }, setState] = useRecoilState(appsAtom);
  const navigate = useNavigate();

  const appId = app?._id;

  const getService = async () => {
    if (appId) {
      setLoading(true);

      const response = await api.applicationResources.findOne({
        query: {
          applicationId: appId,
          slug: appServiceSlug,
        },
      });
      setService(response);

      setLoading(false);
    }
  };

  useEffect(() => {
    getService().then();
  }, [appServiceSlug, appId]);

  const onDelete = async () => {
    if (service?._id) {
      setDeleting(true);
      try {
        await api.applicationResources.remove(service?._id);
        notification.success({
          message: 'Resource removed',
        });
        navigate('../..', { replace: true });
        setShowDeleteModal(true);
        setState((previous) => ({
          ...previous,
          services: previous.services.filter((s) => s._id !== service._id),
        }));
      } catch (e) {
        notification.error({
          message: 'Something went wrong',
          description: 'Please try again',
        });
      }
      setDeleting(false);
    }
  };

  return (
    <PageContent
      title={loading ? 'Loading...' : (service?.name || '')}
      description={service?.description}
    >
      {
        service && (
          <Tabs
            tabBarExtraContent={(
              <Button
                type="default"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
                danger
              >
                Delete
              </Button>
            )}
          >
            <Tabs.TabPane tab="Info" key="info">
              <AppResourceGeneralInfo service={service} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Edit" key="edit">
              <Row>
                <Col span={24} md={20} lg={18} xl={16} xxl={14}>
                  <AppResourceEdit service={service} />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Fields" key="fields">
              <AppResourceFields resourceId={service._id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Methods" key="methods">
              <Row>
                <Col span={24} md={20} lg={18} xl={16} xxl={14}>
                  <AppResourceMethods resourceId={service._id} />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Integrations" key="integrations">
              <Row>
                <Col span={24} md={20} lg={18} xl={16} xxl={14}>
                  <AppResourceIntegrations resourceId={service._id} />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        )
      }
      <Modal
        title="Are you sure?"
        open={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        okButtonProps={{
          type: 'primary',
          danger: true,
          loading: deleting,
        }}
        okText="Delete Resource"
        onOk={onDelete}
      >
        If you delete this resource, it will no longer be accessible.
      </Modal>
    </PageContent>
  );
}
