import SidebarInputLabel from '../../../../components/SidebarInputLabel';
import { capitalize } from '../../../../../../../../utils';
import StyleSettingColorInput from '../StyleSettingColorInput';
import SidebarSelectInput from '../../../../components/SidebarSelectInput';
import SidebarInput from '../../../../components/SidebarInput';
import SidebarComponentContainer from '../../../../components/SidebarComponentContainer';

const borderStyles = [
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
  'initial',
  'inherit',
];

const borderSelectOptions = borderStyles.map((style) => ({
  label: capitalize(style),
  value: style,
}));

const units = ['px', 'em', 'rem', 'vh', 'vw', '%'];

const unitsOptions = units.map((unit) => ({
  label: unit,
  value: unit,
}));

type BorderValue = {
  width?: string | number;
  widthUnit?: string;
  style?: string;
  color?: string;
};

interface StyleBorderProps {
  value?: BorderValue;
  onChange?: (value: BorderValue, style: string) => void;
}

function getStyle(value: BorderValue) {
  const {
    width, widthUnit, style, color = 'black',
  } = value;
  return `${width}${widthUnit} ${style} ${color}`;
}

export default function StyleBorder(props: StyleBorderProps) {
  const { value = { width: '' }, onChange } = props;

  const changeValue = (newValue: BorderValue) => {
    const updatedValue: BorderValue = { style: 'solid', ...value, ...newValue };

    if (onChange) onChange(updatedValue, getStyle(updatedValue));
  };

  return (
    <SidebarComponentContainer>
      <SidebarInputLabel label="Border" />
      <div className="d-flex flex-row">
        <SidebarInput
          type="number"
          value={value.width}
          onChange={(e) => {
            changeValue({ width: e.target.valueAsNumber, widthUnit: 'px' });
          }}
        />
        <SidebarSelectInput
          value={value.widthUnit || ''}
          onChange={(widthUnit) => {
            changeValue({ widthUnit });
          }}
          options={unitsOptions}
          style={{ width: 'auto' }}
        />
        <SidebarSelectInput
          value={value.style || ''}
          onChange={(borderStyle) => {
            changeValue({ style: borderStyle });
          }}
          options={borderSelectOptions}
          style={{ width: 'auto' }}
        />
        <StyleSettingColorInput
          value={value.color}
          onChange={(color) => {
            changeValue({ color });
          }}
          style={{ minWidth: 50 }}
          hideValueText
        />
      </div>
    </SidebarComponentContainer>
  );
}
