import { Organization } from '@kernex/common';
import {
  Form, Input, notification, Select,
} from 'antd';
import countries from '../../../utils/countries';
import Button from '../../common/components/Button';
import useAsyncCall from '../../common/hooks/useAsyncCall';
import api from '../../../api';

export interface OrganizationAddressFormProps {
  organization: Organization;
  onSaveSuccess: (organization: Organization) => void;
}

const countriesOptions = countries.map((c) => ({ label: c.name, value: c.code }));

export default function OrganizationAddressForm(props: OrganizationAddressFormProps) {
  const { organization, onSaveSuccess } = props;
  const { address } = organization;

  const [onFinish, loading] = useAsyncCall(async (values: Partial<Organization['address']>) => {
    const response = await api.organizations.patch(organization._id, {
      address: values,
    });

    if (onSaveSuccess) {
      onSaveSuccess(response);
    }

    notification.success({
      message: 'Organization address updated',
    });
  }, {
    onError: () => {
      notification.error({
        message: 'Error updating address',
        description: 'Please try again',
      });
    },
  });

  return (
    <Form
      initialValues={address}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        name="line1"
        label="Address Line 1"
      >
        <Input placeholder="Address line 1" />
      </Form.Item>
      <Form.Item
        name="line2"
        label="Address Line 2"
      >
        <Input placeholder="Address line 2" />
      </Form.Item>
      <Form.Item
        name="city"
        label="City"
      >
        <Input placeholder="City" />
      </Form.Item>
      <Form.Item
        name="state"
        label="State"
      >
        <Input placeholder="State" />
      </Form.Item>
      <Form.Item
        name="postalCode"
        label="Postal Code"
      >
        <Input placeholder="Postal Code" />
      </Form.Item>
      <Form.Item
        name="country"
        label="Country"
      >
        <Select
          options={countriesOptions}
          optionFilterProp="label"
          showSearch
        />
      </Form.Item>
      <Form.Item className="d-flex justify-content-center">
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
