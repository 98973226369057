import { Tabs } from 'antd';
import AppDocsCodeBlock, { AppDocsCodeBlockProps } from '../AppDocsCodeBlock';

interface Tab extends Pick<AppDocsCodeBlockProps, 'language' | 'code'> {
  title?: string;
}

interface AppDocsCodeBlockTabsProps {
  tabs: Tab[];
}

export default function AppDocsCodeBlockTabs(props: AppDocsCodeBlockTabsProps) {
  const { tabs } = props;

  return (
    <div className="d-flex flex-column gap-4">
      <Tabs
        items={tabs.map((tab) => ({
          key: tab.title || tab.language,
          label: tab.title,
          children: (
            <AppDocsCodeBlock
              language={tab.language}
              code={tab.code}
            />
          ),
        }))}
      />
    </div>
  );
}
