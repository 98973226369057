import React from 'react';
import { Element } from '@craftjs/core';

import Icon from '../../../../common/components/Icon';
import TextBlock from './TextBlock';
import ButtonBlock from './ButtonBlock';
import ContainerBlock from './ContainerBlock';
import LinkBlock from './LinkBlock';
import ResourceListBlock from './ResourceListBlock';

export enum BlockType {
  TEXT = 'TEXT',
  CONTAINER = 'CONTAINER',
  BUTTON = 'BUTTON',
  LINK = 'LINK',
  RESOURCE_LIST = 'RESOURCE_LIST',
  USER_COMPONENT = 'USER_COMPONENT',
}

export interface IBlock {
  name: string;
  type: BlockType;
  Icon: React.FC<{ color: string; size: number; }>;
  createElement: () => React.ReactNode;
}

const components: IBlock[] = [
  {
    name: 'Text',
    type: BlockType.TEXT,
    Icon: (props) => <Icon name="text" {...props} />,
    createElement: () => <TextBlock text="Click here to edit" />,
  },
  {
    name: 'Button',
    type: BlockType.BUTTON,
    Icon: (props) => <Icon name="button" {...props} />,
    createElement: () => (<ButtonBlock text="Button" />),
  },
  {
    name: 'Link',
    type: BlockType.LINK,
    Icon: (props) => <Icon name="link" {...props} />,
    createElement: () => (
      /*
        // @ts-ignore */
      <LinkBlock text="Link" />
    ),
  },
  {
    name: 'Container',
    type: BlockType.CONTAINER,
    Icon: (props) => <Icon name="container" {...props} />,
    createElement: () => (
      <Element
        /*
        // @ts-ignore */
        is={ContainerBlock}
        canvas
      />
    ),
  },
];

const serviceComponents: IBlock[] = [
  {
    name: 'ResourceList',
    type: BlockType.RESOURCE_LIST,
    Icon: (props) => <Icon name="unorderedList" {...props} />,
    createElement: () => (<ResourceListBlock />),
  },
];

const blocks = [
  {
    title: 'Components',
    blocks: components,
  },
  {
    title: 'Services',
    blocks: serviceComponents,
  },
];

export default blocks;
