import React, { HTMLAttributes } from 'react';
import ContentEditable, { Props as ContentEditableProps } from 'react-contenteditable';
import { useEditor, useNode } from '@craftjs/core';

export type TextBlockPreviewProps = {
  text: string;
  fontSize?: string;
  textAlign?: string;
  fontWeight?: string;
  shadow?: number;
  item?: Record<string, any>;
} & Omit<Partial<ContentEditableProps>, 'ref'>;

function getUsedProperties(text: string) {
  const match = text.match(/{{item\.\w+}}/g);

  if (match) {
    return match.map((item) => item.replace('{{item.', '').replace('}}', ''));
  }

  return [];
}

export function TextBlockPreview(props: TextBlockPreviewProps) {
  const { text, item, ...rest } = props;

  function getText() {
    if (item) {
      const usedProperties = getUsedProperties(text);
      const itemProperties = Object.keys(item);
      const properties = usedProperties.filter((property) => itemProperties.includes(property));

      if (properties.length) {
        return properties.reduce((acc, property) => {
          const value = item[property];

          if (value) {
            return acc.replace(`{{item.${property}}}`, value);
          }

          return acc;
        }, text);
      }
    }

    return text;
  }

  return (
    <p {...rest}>{getText()}</p>
  );
}

interface TextBlockProps extends HTMLAttributes<HTMLParagraphElement> {
  text: string;
}

export default function TextBlock(props: TextBlockProps) {
  const { text, ...rest } = props;

  const {
    connectors: { connect },
    actions,
  } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <ContentEditable
      innerRef={connect}
      html={text}
      disabled={!enabled}
      onChange={(e) => {
        actions.setProp((prop: { text: string }) => {
          // eslint-disable-next-line no-param-reassign
          prop.text = e.target.value;
        }, 500);
      }}
      tagName="p" // Use a custom HTML tag (uses a div by default)
      {...rest}
    />
  );
}

TextBlock.craft = {
  displayName: 'TextBlock',
  props: {
    text: 'Click here to start editing',
    outline: 'none',
    style: {
      margin: 0,
      outline: 'none',
    },
  },
  custom: {
    styleSettings: {
      outline: 'none',
      margin: {
        value: 0,
        unit: 'px',
      },
    },
  },
};

TextBlockPreview.craft = TextBlock.craft;
