import {
  Form, Input, Button,
} from 'antd';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import AuthLayout from '../../layout/AuthLayout';

export default function SignInPage() {
  const auth = useAuth();

  return (
    <AuthLayout>
      <h1>Sign In</h1>

      <Form
        layout="vertical"
        className="hp-mt-sm-16 hp-mt-32"
        onFinish={auth.signIn}
      >
        <Form.Item
          label="Email"
          name="email"
          className="hp-mb-16"
          rules={[
            { required: true, message: 'Please input your email' },
            { type: 'email', message: 'Please enter a valid email' },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          className="hp-mb-8"
          rules={[
            { required: true, message: 'Please input your password' },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <p className="text-end">
          <Link to="/forgot-password">Forgot your password?</Link>
        </p>

        <Form.Item className="hp-mt-16 hp-mb-8">
          <Button block type="primary" htmlType="submit">
            Sign in
          </Button>
        </Form.Item>
        <p>
          Don&apos;t have an account?
          &nbsp;
          <Link to="/sign-up">Create an account</Link>
          &nbsp;
        </p>
      </Form>
    </AuthLayout>
  );
}
