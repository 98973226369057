import { atom } from 'recoil';
import { Organization } from '@kernex/common';
import Application from '../../../api/models/Application';

interface OrganizationsState {
  loading: boolean;
  organization: Organization | null;
  apps: Application[];
  newAppsCount: number,
}

const organizationsAtom = atom<OrganizationsState>({
  key: 'organizationsState',
  default: {
    loading: true,
    organization: null,
    apps: [],
    newAppsCount: 0,
  },
});

export default organizationsAtom;
