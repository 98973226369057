import { Form, Input } from 'antd';
import Button from '../../../common/components/Button';
import useFormSubmit from '../../../common/hooks/useFormSubmit';
import api from '../../../../api';

export default function UserSecurityForm() {
  const [form] = Form.useForm();
  const [onFinish, loading] = useFormSubmit(
    async (values: { password: string; currentPassword: string }) => {
      await api.users.changePassword(values);
    },
    { form, successMessage: 'Your password has been changed' },
  );

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Form.Item label="Current Password" name="currentPassword" rules={[{ required: true }]}>
        <Input.Password placeholder="Current password" />
      </Form.Item>

      <Form.Item
        label="New Password"
        name="password"
        rules={[
          { required: true, message: 'Please input your password' },
          { min: 8, message: 'Password should contain at least 8 characters' },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        name="passwordConfirm"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm Password" />
      </Form.Item>

      <Form.Item noStyle>
        <Button htmlType="submit" type="primary" loading={loading}>Save</Button>
      </Form.Item>
    </Form>
  );
}
