import {
  Col, Drawer, Form, Input, Pagination, Row,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Application, AppUpload } from '@kernex/common';
import debounce from 'just-debounce';
import useQuery from '../../../common/hooks/useQuery';
import api from '../../../../api';
import styles from './index.module.css';
import useFormSubmit from '../../../common/hooks/useFormSubmit';
import Button from '../../../common/components/Button';
import ImageCard from '../ImageCard';

export interface AppMediaLibraryProps {
  appId: Application['_id'];
  onSelect?: (upload: AppUpload) => void;
  newUploads?: AppUpload[];
  onPageChange?: () => void;
  multiple?: boolean;
  onSelectMultiple?: (upload: AppUpload[]) => void;
}

const perPage = 12;

export default function AppMediaLibrary(props: AppMediaLibraryProps) {
  const {
    appId, onSelect, newUploads = [], onPageChange, multiple, onSelectMultiple,
  } = props;
  const [page, setPage] = useState(1);
  const [editImage, setEditImage] = useState<AppUpload>();
  const [selected, setSelected] = useState<AppUpload[]>([]);
  const [search, setSearch] = useState('');

  const loadUploads = useCallback(() => api.appUploads(appId).find({
    query: {
      $select: ['url', 'title', 'description', 'createdAt'],
      $sort: { createdAt: -1 },
      $limit: perPage,
      $skip: (page - 1) * perPage,
      title: search ? { $search: search } : undefined,
    },
  }), [appId, page, search]);

  const [uploads, , , setUploads] = useQuery(loadUploads);

  const [onEdit, editing] = useFormSubmit(async (values: Partial<AppUpload>) => {
    if (editImage) {
      await api.appUploads(appId).patch(editImage._id, values);

      setUploads((prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          data: prev.data.map((upload) => (upload._id === editImage._id ? { ...upload, ...values } : upload)),
        };
      });
    }
  }, {
    onSuccess: () => {
      setEditImage(undefined);
    },
  });

  useEffect(() => {
    if (onPageChange) {
      onPageChange();
    }
  }, [page, Boolean(onPageChange)]);

  useEffect(() => {
    setPage(1);
  }, [selected]);

  const data = [
    ...newUploads,
    ...(uploads?.data || []),
  ];

  const onSearch = useCallback(debounce((newValue) => {
    setSearch(newValue);
    setPage(1);
  }, 500), [page]);

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            placeholder="Search by title"
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </Col>
      </Row>

      <div className="h-100 overflow-auto my-2" style={{ paddingLeft: 8, paddingRight: 8 }}>
        <Row gutter={[16, 16]}>
          {
            data.map((upload) => {
              const isSelected = selected.some((item) => item._id === upload._id);

              return (
                <Col
                  key={upload._id}
                  span={24}
                  sm={12}
                  lg={8}
                  className={styles.imageContainer}
                >
                  <ImageCard
                    url={upload.url}
                    title={upload.title}
                    createdAt={upload.createdAt}
                    onSelect={!multiple && onSelect ? () => {
                      onSelect(upload);
                    } : undefined}
                    onEdit={() => { setEditImage(upload); }}
                    onCheck={multiple ? () => {
                      if (isSelected) {
                        setSelected((prev) => prev.filter((item) => item._id !== upload._id));
                      } else {
                        setSelected((prev) => [...prev, upload]);
                      }
                    } : undefined}
                    checked={Boolean(multiple && isSelected)}
                  />
                </Col>
              );
            })
          }
        </Row>
      </div>

      <Row justify="space-between" gutter={[16, 16]}>
        <div className="d-flex justify-content-center">
          {
            multiple && (
              <Button
                onClick={() => {
                  if (onSelectMultiple) {
                    onSelectMultiple(selected);
                  }
                  setSelected([]);
                }}
              >
                {`Select ${selected.length} files`}
              </Button>
            )
          }
        </div>
        <Col>
          {
            uploads && (
              <div className="d-flex justify-content-center">
                <Pagination
                  pageSize={perPage}
                  current={page}
                  onChange={setPage}
                  total={uploads.total}
                  showSizeChanger={false}
                />
              </div>
            )
          }
        </Col>
      </Row>

      <Drawer title="Edit Image" open={Boolean(editImage)} onClose={() => { setEditImage(undefined); }}>
        {
          editImage && (
            <Form
              initialValues={editImage}
              onFinish={onEdit}
              layout="vertical"
            >
              <Form.Item label="Title" name="title">
                <Input />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" loading={editing}>Save</Button>
              </Form.Item>
            </Form>
          )
        }
      </Drawer>
    </div>
  );
}
