import { useRecoilState } from 'recoil';
import {
  Col, Descriptions, Modal, notification, Row, Tabs, Tag, Typography,
} from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AppUserRole } from '@kernex/common';
import PageContent from '../../../layout/PageContent';
import appsAtom from '../../state';
import api from '../../../../api';
import organizationsAtom from '../../../organizations/atoms';
import AppRoles from '../../../permissions/components/AppRoles';
import Button from '../../../common/components/Button';
import { API_URL } from '../../../../global/constants';
import AppForm from '../../components/AppForm';
import AppCode from '../../components/AppCode';
import AppApiKeys from '../../components/AppApiKeysList';
import AppUsers from '../../components/AppUsers';
import AppUsage from '../../components/AppUsage';
import AppSettings from '../../components/AppSettings';

interface AppIndexPageProps {
  activeKey: 'info' | 'edit' | 'code' | 'api-keys' | 'users' | 'usage' | 'settings';
}

export default function AppIndexPage(props: AppIndexPageProps) {
  const { activeKey } = props;
  const [{ organization }, setOrganizationState] = useRecoilState(organizationsAtom);
  const [{ app }, setAppsState] = useRecoilState(appsAtom);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();

  const onDelete = async () => {
    if (app) {
      setDeleting(true);

      try {
        await api.applications.remove(app._id);
        notification.success({
          message: 'App removed',
        });
        setShowDeleteModal(false);
        navigate('../../../');
        setOrganizationState((state) => ({
          ...state,
          apps: state.apps.filter((a) => a._id !== app._id),
        }));
        setAppsState((state) => ({
          ...state,
          app: null,
        }));
      } catch (e) {
        notification.error({
          message: 'Error removing app',
          description: 'Please try again',
        });
      }

      setDeleting(false);
    }
  };

  const appUrl = `/app/organizations/${organization?.slug}/apps/${app?.slug}`;

  const tabs = [
    {
      label: <Link to={appUrl} className="link-unstyled">Info</Link>,
      key: 'info',
      children: (
        <Row>
          <Col span={24} lg={18} xl={16} xxl={10}>
            <Descriptions layout="horizontal" bordered size="small" column={1}>
              {/* <Descriptions.Item label="Preview"> */}
              {/*  <a href={`/${app?.slug}`} target="_blank" rel="noreferrer"> */}
              {/*    Live Preview */}
              {/*  </a> */}
              {/* </Descriptions.Item> */}
              <Descriptions.Item label="Name">{app?.name}</Descriptions.Item>
              <Descriptions.Item label="Slug">{app?.slug}</Descriptions.Item>
              <Descriptions.Item label="App ID">
                <Tag color="red">
                  {app?._id}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Base Endpoint">
                <Typography.Text copyable>
                  {`${API_URL}/api/v1/${app?._id}`}
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Description">{app?.description}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      ),
    },
    {
      label: <Link to={`${appUrl}/edit`} className="link-unstyled">Edit</Link>,
      key: 'edit',
      children: app && (
        <Row>
          <Col span={24} md={12} lg={10} xl={8} xxl={6}>
            <AppForm organizationId={app.organizationId} app={app} />
          </Col>
        </Row>
      ),
    },
    {
      label: <Link to={`${appUrl}/code`} className="link-unstyled">Code</Link>,
      key: 'code',
      children: organization && app && (
        <AppCode
          app={app}
          organizationSlug={organization.slug}
        />
      ),
    },
    {
      label: <Link to={`${appUrl}/api-keys`} className="link-unstyled">API Keys</Link>,
      key: 'api-keys',
      children: app && (
        <AppApiKeys applicationId={app?._id} />
      ),
    },
    {
      label: <Link to={`${appUrl}/users`} className="link-unstyled">Users</Link>,
      key: 'users',
      children: app && (<AppUsers appId={app._id} />),
    },
    {
      label: <Link to={`${appUrl}/usage`} className="link-unstyled">Usage</Link>,
      key: 'usage',
      children: app && (<AppUsage app={app} />),
    },
    {
      label: <Link to={`${appUrl}/settings`} className="link-unstyled">Settings</Link>,
      key: 'settings',
      children: app && (
        <AppSettings
          app={app}
          onUpdate={(newApp) => {
            setAppsState((prev) => ({
              ...prev,
              app: newApp,
            }));
          }}
        />
      ),
    },
  ];

  return (
    <PageContent
      title={app?.name || 'App'}
      description={app?.description}
    >
      <AppRoles
        roles={[AppUserRole.DEVELOPER, AppUserRole.OWNER]}
        renderRestricted={() => (
          <p className="text-center">Select a content type from the sidebar</p>
        )}
      >
        {
          () => (
            <>
              <Tabs
                tabBarExtraContent={(
                  <Button
                    type="default"
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                    danger
                  >
                    Delete
                  </Button>
                )}
                activeKey={activeKey}
                items={tabs}
              />
              <Modal
                title="Are you sure?"
                open={showDeleteModal}
                onCancel={() => {
                  setShowDeleteModal(false);
                }}
                okButtonProps={{
                  danger: true,
                  loading: deleting,
                }}
                onOk={onDelete}
                okText="Delete App"
              >
                This action is irreversible
              </Modal>
            </>
          )
        }
      </AppRoles>
    </PageContent>
  );
}
