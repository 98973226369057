import { Select, SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { Field } from '@kernex/core';
import api from '../../../../api';

interface AppResourceFieldSelectProps extends SelectProps {
  resourceId: string;
}

export default function AppResourceFieldSelect(props: AppResourceFieldSelectProps) {
  const { resourceId, ...selectProps } = props;
  const [fields, setFields] = useState<Field[]>([]);

  const loadFields = async () => {
    const response = await api.fields.find({
      query: {
        resourceId,
        $limit: 100,
      },
    });
    setFields(response.data);
  };

  useEffect(() => {
    loadFields();
  }, [resourceId]);

  return (
    <Select
      options={fields.map((field) => ({
        value: field._id,
        label: field.name,
      }))}
      {...selectProps}
    />
  );
}
