import { atom } from 'recoil';
import {
  Application, ApplicationResource, ApplicationPage, AppUser,
} from '@kernex/common';
import api from '../../../api';

interface ApplicationState {
  loading: boolean;
  app: Application | null;
  services: ApplicationResource[];
  pages: ApplicationPage[];
  appUser: AppUser | null,
}

const appsAtom = atom<ApplicationState>({
  key: 'apps',
  default: {
    loading: false,
    app: null,
    services: [],
    pages: [],
    appUser: null,
  },
  effects: [
    ({ onSet, setSelf }) => {
      onSet(async (state) => {
        if (state.app) {
          const [services, pages] = await Promise.all([
            await api.applicationResources.find({
              query: {
                applicationId: state.app._id,
                $limit: 100,
              },
            }),
            await api.applicationPages.find({
              query: {
                applicationId: state.app._id,
                $limit: 100,
              },
            }),
          ]);

          setSelf({
            ...state,
            services: services.data,
            pages: pages.data,
          });
        }
      });
    },
  ],
});

export default appsAtom;
