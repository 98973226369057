import {
  Paginated, Params, Service,
} from '@feathersjs/feathers';

export default class ServiceAPI<S, CreateData = Partial<S>, SCM = {}> {
  readonly service: Service<S> & SCM;

  constructor(service: Service<S, CreateData> & SCM) {
    this.service = service;
  }

  async create<CD = CreateData>(data: CD, params?: Params): Promise<S> {
    return this.service.create(data, params);
  }

  async get(id: string, params?: Params): Promise<S> {
    return this.service.get(id, params);
  }

  async find(params?: Params): Promise<Paginated<S>> {
    const response = await this.service.find(params);

    const skip = params?.query?.$skip || 0;
    const limit = params?.query?.$limit || 0;

    if (!('data' in response)) {
      if (Array.isArray(response)) {
        return {
          data: response,
          total: response.length,
          skip,
          limit,
        };
      }

      return {
        total: 1,
        data: [response],
        skip,
        limit,
      };
    }

    return response;
  }

  async findOne(params: Params): Promise<S> {
    const response = await this.find({
      query: {
        ...(params?.query || {}),
        $limit: 1,
      } as Params['query'],
    });

    const value = response.data[0];

    if (!value) {
      throw new Error('Not Found');
    }

    return value;
  }

  async count(params?: Params): Promise<number> {
    const { total } = await this.find({
      query: {
        ...(params?.query || {}),
        $limit: 0, // This will do a count query
      },
      ...params,
    });

    return total;
  }

  async patch(id: string, data: Partial<S>, params?: Params): Promise<S> {
    // @ts-ignore
    return this.service.patch(id, data, params);
  }

  async update(id: string, data: Partial<S>, params?: Params): Promise<S> {
    // @ts-ignore
    return this.service.update(id, data, params);
  }

  async createOrPatch(data: Partial<S> | CreateData, id?: string): Promise<S> {
    if (id) {
      return this.patch(id, data);
    }

    return this.create(data);
  }

  async remove(id: string, params?: Params): Promise<S> {
    // @ts-ignore
    return this.service.remove(id, params);
  }
}
