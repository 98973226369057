import { Plan } from '@kernex/common';
import { Card } from 'antd';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import { shortFormatNumber } from '../../../utils/numbers';

interface PlanCardProps
  extends Pick<
  Plan,
  'name' | 'stripePriceId'
  | 'premiumSupport' | 'maxUsersPerApp' | 'maxRecordsPerApp' | 'allowsIntegrations' | 'fileQuota'
  > {
  price: Plan['price'] | 'Contact Us';
  appCount: Plan['appCount'] | 'Unlimited';
  onSubscribeClick?: () => void;
  onCancelClick?: () => void;
  onResumeClick?: () => void;
  loading?: boolean;
  isActive?: boolean;
  isCancelingAtPeriodEnd?: boolean;
  cancelling?: boolean;
  resuming?: boolean;
  disabled?: boolean;
}

interface PlanFeatureProps {
  enabled?: boolean;
  text: string;
}

function PlanFeature(props: PlanFeatureProps) {
  const { enabled, text } = props;

  return (
    <div className="d-flex align-items-center gap-1">
      <Icon
        name={enabled ? 'checkOutlined' : 'closeOutlined'}
        color={enabled ? 'rgba(1,196,130,0.85)' : 'red'}
      />
      <p className="mb-1">{text}</p>
    </div>
  );
}

function getFileQuota(fileQuota: number) {
  if (fileQuota === Infinity) {
    return 'Unlimited File Quota per App';
  }

  if (fileQuota < 1000) {
    return `${fileQuota} MB File Quota per App`;
  }

  return `${Math.round(fileQuota / 1000)} GB File Quota per App`;
}

export default function PlanCard(props: PlanCardProps) {
  const {
    name,
    price,
    stripePriceId,
    appCount,
    premiumSupport,
    onSubscribeClick,
    loading,
    isActive,
    cancelling,
    onCancelClick,
    isCancelingAtPeriodEnd,
    resuming,
    onResumeClick,
    maxUsersPerApp,
    maxRecordsPerApp,
    allowsIntegrations,
    fileQuota,
    disabled,
  } = props;

  const renderActionButton = () => {
    if (price === 0 || disabled) {
      return <div style={{ height: 40 }} />;
    }

    if (isActive) {
      if (isCancelingAtPeriodEnd) {
        return (
          <Button type="primary" loading={resuming} onClick={onResumeClick} block>Resume</Button>
        );
      }
      return (
        <Button
          loading={cancelling}
          onClick={onCancelClick}
          block
        >
          Cancel
        </Button>
      );
    }

    if (stripePriceId) {
      return (
        <Button
          type="primary"
          onClick={onSubscribeClick}
          loading={loading}
          block
        >
          Subscribe
        </Button>
      );
    }

    return (<Button block>Contact Us</Button>);
  };

  return (
    <Card>
      <h4>{name}</h4>
      <h2>{typeof price !== 'string' ? `$${price} / mo` : price}</h2>
      <div className="d-flex flex-column gap-2 my-5">
        <PlanFeature
          enabled
          text={`${appCount} ${appCount !== 1 ? 'Apps' : 'App'}`}
        />
        <PlanFeature
          enabled
          text={`${maxUsersPerApp ? `Up to ${maxUsersPerApp}` : 'Unlimited'} users per App`}
        />
        <PlanFeature
          enabled
          text={`${maxRecordsPerApp ? `Up to ${shortFormatNumber(maxRecordsPerApp)}` : 'Unlimited'} records per App`}
        />
        <PlanFeature
          enabled
          text={getFileQuota(fileQuota)}
        />
        <PlanFeature enabled text="Unlimited Content Types (Resources)" />
        <PlanFeature enabled text="Built-in CDN" />
        <PlanFeature
          enabled
          text="Media Library"
        />
        <PlanFeature
          enabled={allowsIntegrations}
          text="Integrations"
        />
        <PlanFeature enabled={premiumSupport} text="Premium Support" />
      </div>
      {renderActionButton()}
    </Card>
  );
}
