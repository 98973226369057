import { AppUpload } from '@kernex/common';
import {
  Card, Form, Image, Input,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import Button from '../../../common/components/Button';

interface DetailedImageCardProps extends Pick<AppUpload, 'url' | 'title' | 'description'> {
  onDelete?: () => void;
  onEdit?: (values: Pick<AppUpload, 'title' | 'description'>) => void;
}

export default function DetailedImageCard(props: DetailedImageCardProps) {
  const {
    url, title, description, onDelete, onEdit,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ title, description });
  }, [title, description]);

  const onValuesChange = (defaultValues: any, allValues: Pick<AppUpload, 'title' | 'description'>) => {
    if (onEdit) {
      onEdit(allValues);
    }
  };

  return (
    <Card size="small">
      <div className="d-flex align-items-center gap-2">
        <Image src={url} style={{ maxHeight: 200 }} />
        <div className="flex-grow-1">
          <Form
            initialValues={{ title, description }}
            form={form}
            onValuesChange={onValuesChange}
          >
            <Form.Item label="Title" name="title">
              <Input value={title} placeholder="Image Title" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input value={description} placeholder="Image Description" />
            </Form.Item>
            <div className="d-flex justify-content-end">
              <Button size="small" onClick={onDelete} danger>
                <DeleteOutlined />
                Delete
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Card>
  );
}
