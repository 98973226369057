import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Editor, Frame } from '@craftjs/core';

import api from '../../../../api';
import ApplicationPage from '../../../../api/models/ApplicationPage';
import NodeComponent from '../../../../api/models/NodeComponent';
import blockPreviewResolver from '../../../app-pages/constants/blockPreviewResolver';

export default function AppPreviewPage() {
  const { appSlug, '*': pagePath } = useParams<{ appSlug: string, '*': string }>();
  const [page, setPage] = useState<ApplicationPage>();
  const [nodeComponent, setNodeComponent] = useState<NodeComponent>();
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    setLoading(true);
    try {
      const app = await api.applications.findOne({ query: { slug: appSlug } });
      const pageResponse = await api.applicationPages.findOne({
        query: {
          applicationId: app._id,
          slug: pagePath !== '' ? pagePath : '/',
        },
      });
      const nodeComponentResponse = await api.nodeComponents.findOne({
        query: {
          applicationId: app._id,
          pageId: pageResponse._id,
        },
      });
      setPage(pageResponse);
      setNodeComponent(nodeComponentResponse);
    } catch (e) {
      // Ignore
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData().then();
  }, [appSlug, pagePath]);

  if (loading) {
    return null;
  }

  if (!page) {
    return (
      <div
        style={{
          height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
      >
        <h4>Page Not Found</h4>
      </div>
    );
  }

  if (!nodeComponent) {
    return null;
  }

  return (
    <Editor
      key="disabled-editor"
      resolver={blockPreviewResolver}
      enabled={false}
    >
      <Frame data={nodeComponent.nodes} />
    </Editor>
  );
}
