export enum AppApiKeyPermission {
  READ = 'read',
  WRITE = 'write',
  DELETE = 'delete',
}

export default interface AppApiKey {
  _id: string;
  name: string;

  /**
   * Api key will be visible only on create. After that it won't be returned by the server.
   */
  key: string;
  applicationId: string;
  permissions: AppApiKeyPermission[];

  /**
   * List of origins that can use this api key.
   */
  origins?: string[];
}
