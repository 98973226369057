import { AppUser, AppUserRole } from '@kernex/common';
import { useCallback, useState } from 'react';
import {
  Card, Col, notification, Popconfirm, Row, Tag,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../common/hooks/useQuery';
import api from '../../../../api';
import Button from '../../../common/components/Button';
import LoadingResponse from '../../../common/components/LoadingResponse';
import AppUserDrawerForm from '../AppUserDrawerForm';
import Icon from '../../../common/components/Icon';
import useOrganizationPlan from '../../../organizations/hooks/useOrganizationPlan';
import useOrganization from '../../../organizations/hooks/useOrganization';

interface AppUsersProps {
  appId: AppUser['appId'];
}

const mapRoleToDisplayName = {
  [AppUserRole.OWNER]: 'Owner',
  [AppUserRole.EDITOR]: 'Editor',
  [AppUserRole.DEVELOPER]: 'Developer',
};

export default function AppUsers(props: AppUsersProps) {
  const { appId } = props;
  const organization = useOrganization();
  const [showAddUserDrawer, setShowAddUserDrawer] = useState(false);
  const { plan } = useOrganizationPlan();
  const navigate = useNavigate();

  const loadUsers = useCallback(async () => {
    const response = await api.appUsers.find({
      query: {
        appId,
        $limit: 100,
      },
    });

    return response.data;
  }, [appId]);

  const [users, loading, , setUsers] = useQuery(loadUsers);

  const onDelete = async (user: AppUser) => {
    await api.appUsers.remove(user._id);
    setUsers((prevState) => (prevState || []).filter((u) => u._id !== user._id));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {
          loading && (<LoadingResponse loading={loading} />)
        }
        {
          users && (
            <Row>
              {
                users.map((user, index) => (
                  <Col span={24} className={index > 0 ? 'mt-2' : undefined}>
                    <Card size="small">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0" style={{ flex: 1 }}>{`${user.name} (${user.email})`}</p>
                        <div className="d-flex justify-content-center" style={{ flex: 2 }}>
                          <Tag color="blue">{mapRoleToDisplayName[user.role]}</Tag>
                        </div>
                        <div className="d-flex justify-content-end" style={{ flex: 1 }}>
                          <Popconfirm
                            title="Are you sure you want to delete this user?"
                            placement="left"
                            onConfirm={() => {
                              onDelete(user).then();
                            }}
                            okText="Delete User"
                          >
                            <Button
                              type="default"
                              size="small"
                              danger
                              disabled={user.role === AppUserRole.OWNER}
                            >
                              <Icon name="delete" />
                            </Button>
                          </Popconfirm>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))
              }
              {
                users && users.length === 0 && (<p>No app users</p>)
              }
            </Row>
          )
        }
      </Col>
      <Col span={24}>
        <Button
          type="primary"
          onClick={() => {
            if (users && plan && users.length >= (plan.maxUsersPerApp || 0)) {
              notification.error({
                message: 'You have reached the maximum number of users for your plan',
                description: 'Please upgrade your plan to add more users',
                duration: 10,
                btn: (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      navigate(`/app/organizations/${organization?.slug}/billing`);
                    }}
                  >
                    Upgrade
                  </Button>
                ),
              });
            } else {
              setShowAddUserDrawer(true);
            }
          }}
        >
          Add User
        </Button>
      </Col>
      <AppUserDrawerForm
        appId={appId}
        open={showAddUserDrawer}
        onClose={() => {
          setShowAddUserDrawer(false);
        }}
        submitButtonText="Add User"
        onSuccess={(newUser) => {
          setUsers((prev) => [...(prev || []), newUser]);
        }}
      />
    </Row>
  );
}
