import NumberFieldTypePlugin from '@kernex/number-field-type-plugin';
import BooleanFieldTypePlugin from '@kernex/boolean-field-type-plugin';
import ColorFieldTypePlugin from '@kernex/color-field-type-plugin';
import 'react-color-palette/lib/css/styles.css';
import DateFieldTypePlugin from '@kernex/date-field-type-plugin';
import DateAndTimeFieldTypePlugin from '@kernex/date-and-time-field-type-plugin';
import 'antd/es/date-picker/style/index';
import TextFieldTypePlugin from '@kernex/text-field-type-plugin';
import RichTextFieldTypePlugin from '@kernex/rich-text-field-type-plugin';
import SlugFieldTypePlugin from '@kernex/slug-field-type-plugin';
import {
  App, FieldType, KernexPlugin,
} from '@kernex/core';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import ImageFieldPlugin from './modules/field-types/types/image-field';
import RelationFieldPlugin from './modules/field-types/types/relation-field';
import TitlesLinkFieldPlugin from './modules/field-types/types/titles-link-field';

const plugins: KernexPlugin[] = [
  new TextFieldTypePlugin(),
  new RichTextFieldTypePlugin(),
  new NumberFieldTypePlugin(),
  new BooleanFieldTypePlugin(),
  new SlugFieldTypePlugin(),
  new ImageFieldPlugin(),
  new DateFieldTypePlugin(),
  new DateAndTimeFieldTypePlugin(),
  new RelationFieldPlugin(),
  new ColorFieldTypePlugin(),
  new TitlesLinkFieldPlugin(),
];

interface PluginsAtom {
  plugins: KernexPlugin[];
  fieldTypes: FieldType[];
}

const pluginsAtom = atom<PluginsAtom>({
  key: 'plugins',
  default: {
    plugins: [],
    fieldTypes: [],
  },
});

export function useInitializePlugins() {
  const [, setPlugins] = useRecoilState(pluginsAtom);

  const app: App = {
    registerFieldType: (fieldType: FieldType) => {
      setPlugins((prev) => ({
        ...prev,
        fieldTypes: [...prev.fieldTypes, fieldType],
      }));
    },
  };

  useEffect(() => {
    plugins.forEach((plugin) => {
      plugin.initialize(app);
      setPlugins((prev) => ({
        ...prev,
        plugins: [...prev.plugins, plugin],
      }));
    });
  }, []);
}

export function useFieldTypes() {
  const [state] = useRecoilState(pluginsAtom);

  return state.fieldTypes;
}
