import { Tag, Tooltip } from 'antd';
import Icon from '../Icon';

export default function EncryptedValueInfo() {
  return (
    <Tooltip title="This value is encrypted and stored securely">
      <Tag color="green">
        <span className="d-flex align-items-center justify-content-center">
          <Icon name="lock" color="green" />
          &nbsp;
          <span>encrypted</span>
        </span>
      </Tag>
    </Tooltip>
  );
}
