import { useNode } from '@craftjs/core';
import styles from './index.module.css';
import SidebarInput from '../SidebarInput';
import SidebarInputLabel from '../SidebarInputLabel';
import SidebarSelectInput, { SidebarSelectInputValue } from '../SidebarSelectInput';

export enum BlockPropTypeType {
  STRING = 'string',
  SELECT = 'select',
}

interface BlockPropTypeBase {
  type: BlockPropTypeType;
  name: string;
  label?: string;
  placeholder?: string;
}

interface StringBlockPropType extends BlockPropTypeBase {
  type: BlockPropTypeType.STRING;
}

interface SelectBlockPropType extends BlockPropTypeBase {
  type: BlockPropTypeType.SELECT;
  options: SidebarSelectInputValue[];
}

export type BlockPropType = SelectBlockPropType | StringBlockPropType;

interface BlockPropsProps {
  blockPropsTypes: BlockPropType[];
}

export default function BlockProps(props: BlockPropsProps) {
  const { blockPropsTypes } = props;

  const { actions: { setProp }, blockProps } = useNode((node) => {
    const { props: nodeProps } = node.data;
    return {
      blockProps: blockPropsTypes.reduce((acc, { name }) => ({
        ...acc,
        [name]: nodeProps[name],
      }), {}) as Record<BlockPropType['name'], any>,
    };
  });

  const setPropValue = (propName: BlockPropType['name'], value: any) => {
    setProp((currentProps: { [x: string]: any; }) => {
      // eslint-disable-next-line no-param-reassign
      currentProps[propName] = value;
    });
  };

  const getOnChange = (name: BlockPropType['name']) => (value: unknown) => {
    setPropValue(name, value);
  };

  const renderBlockProp = (propType: BlockPropType) => {
    if (!blockProps) {
      return null;
    }

    switch (propType.type) {
      case BlockPropTypeType.STRING:
        return (
          <SidebarInput
            value={blockProps[propType.name]}
            onChange={(e) => {
              setPropValue(propType.name, e.target.value);
            }}
            placeholder={propType.placeholder}
          />
        );
      case BlockPropTypeType.SELECT:
        return (
          <SidebarSelectInput
            value={blockProps[propType.name]}
            onChange={getOnChange(propType.name)}
            options={propType.options}
            placeholder={propType.placeholder}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {blockPropsTypes.map((propType) => (
        <div key={propType.name} className={styles.blockContainer}>
          {
            propType.label && <SidebarInputLabel label={propType.label} />
          }
          {renderBlockProp(propType)}
        </div>
      ))}
    </div>
  );
}
