import { Col, Row } from 'antd';
import { AppIntegration, ApplicationResource } from '@kernex/common';
import { useCallback, useEffect, useState } from 'react';
import Button from '../../../common/components/Button';
import AppResourceIntegrationDrawerForm from '../AppResourceIntegrationDrawerForm';
import useToggle from '../../../common/hooks/useToggle';
import useQuery from '../../../common/hooks/useQuery';
import api from '../../../../api';
import LoadingResponse from '../../../common/components/LoadingResponse';
import AppResourceIntegrationCard from '../AppResourceIntegrationCard';

interface AppResourceIntegrationsProps {
  resourceId: ApplicationResource['_id'];
}

export default function AppResourceIntegrations(props: AppResourceIntegrationsProps) {
  const { resourceId } = props;
  const [showAddIntegration, toggle] = useToggle(false);
  const [integrationToEdit, setIntegrationToEdit] = useState<AppIntegration>();

  const isEdit = Boolean(integrationToEdit);

  useEffect(() => {
    if (isEdit) {
      toggle.on();
    }
  }, [isEdit]);

  useEffect(() => {
    if (!showAddIntegration) {
      setIntegrationToEdit(undefined);
    }
  }, [showAddIntegration]);

  const loadIntegrations = useCallback(async () => {
    const response = await api.appIntegrations.find({
      query: {
        resourceId,
        $limit: 100,
      },
    });

    return response.data;
  }, [resourceId]);

  const [integrations, loading, error, setIntegrations] = useQuery<AppIntegration[]>(loadIntegrations);

  const onSuccess = (integration: AppIntegration) => {
    setIntegrations((previous) => {
      const previousIntegrations = previous || [];
      const exists = previousIntegrations.find((i) => i._id === integration._id);

      if (!exists) {
        return [integration, ...previousIntegrations];
      }

      return previousIntegrations.map((i) => (i._id === integration._id ? integration : i));
    });
  };

  const onDelete = async (integration: AppIntegration) => {
    await api.appIntegrations.remove(integration._id);
    setIntegrations((prevState) => (prevState || []).filter((i) => i._id !== integration._id));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {
          (loading || error) && <LoadingResponse loading={loading} error={error} />
        }
        {
          integrations?.map((integration) => (
            <AppResourceIntegrationCard
              key={integration._id}
              enabled={integration.enabled}
              service={integration.service}
              onEdit={() => {
                setIntegrationToEdit(integration);
              }}
              onDelete={() => {
                onDelete(integration).then();
              }}
            />
          ))
        }
      </Col>
      <Col span={24}>
        <Button
          type="primary"
          onClick={toggle.on}
        >
          Add Integration
        </Button>
      </Col>
      <AppResourceIntegrationDrawerForm
        onClose={toggle.off}
        open={showAddIntegration}
        resourceId={resourceId}
        integration={integrationToEdit}
        onSuccess={onSuccess}
      />
    </Row>
  );
}
