import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';

type UseQuery<T> = [
  T | null,
  boolean,
  boolean,
  Dispatch<SetStateAction<T | null>>,
];

export default function useQuery<T>(fn: () => Promise<T>): UseQuery<T> {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<null | T>(null);

  const call = async () => {
    setLoading(true);
    setError(false);

    try {
      const response = await fn();
      setData(response);
    } catch (e) {
      setError(true);
      // Ignore
    }

    setLoading(false);
  };

  useEffect(() => {
    call().then();
  }, [fn]);

  return [data, loading, error, setData];
}
