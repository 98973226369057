import { useEffect, useState } from 'react';
import {
  Card, Checkbox, Form, notification,
} from 'antd';
import { ApplicationResource } from '@kernex/common';
import api from '../../../../api';
import ApplicationResourceSettings from '../../../../api/models/ApplicationResourceSettings';
import Button from '../../../common/components/Button';

interface AppResourceMethodsProps {
  resourceId: ApplicationResource['_id'];
}

const methods = ['create', 'get', 'find', 'patch', 'update', 'remove'];

export default function AppResourceMethods(props: AppResourceMethodsProps) {
  const { resourceId } = props;
  const [settings, setSettings] = useState<ApplicationResourceSettings>();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const loadSettings = async () => {
    setLoading(true);

    try {
      const response = await api.applicationServiceSettings.findOne({ query: { resourceId } });
      setSettings(response);
    } catch (e) {
      // Ignore
    }

    setLoading(false);
  };

  useEffect(() => {
    loadSettings().then();
  }, [resourceId]);

  const onFinish = async (values: ApplicationResourceSettings) => {
    if (settings) {
      setUpdating(true);
      try {
        await api.applicationServiceSettings.patch(settings._id, values);
        notification.success({
          message: 'Resource updated',
        });
      } catch (e) {
        notification.error({
          message: 'Error updating resource',
        });
      }
      setUpdating(false);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Form layout="vertical" initialValues={settings} onFinish={onFinish}>
      {
        methods.map((method) => (
          <Card key={method} size="small" className="mb-2">
            <div className="d-flex justify-content-between">
              <span>{method}</span>
              <Form.Item name={[method, 'allowed']} valuePropName="checked" noStyle>
                <Checkbox>allowed</Checkbox>
              </Form.Item>
            </div>
          </Card>
        ))
      }
      <div className="d-flex justify-content-center">
        <Form.Item noStyle>
          <Button htmlType="submit" loading={updating}>Save</Button>
        </Form.Item>
      </div>
    </Form>
  );
}
