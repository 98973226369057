import { FieldTypeDetailsRendererProps } from '@kernex/core';
import {
  Card, Col, Image, Row,
} from 'antd';

interface Item {
  title: string;
  url: string;
  description?: string;
  agency: {
    title: string;
    image?: {
      url: string;
    };
  };
}

export default function DetailsRenderer(props: FieldTypeDetailsRendererProps) {
  const { value } = props;

  if (!Array.isArray(value)) {
    return null;
  }

  return (
    <Row gutter={[16, 16]}>
      {
        value.map((item: Item) => (
          <Col key={item.title}>
            <Card>
              <div className="d-flex">
                {
                  item.agency.image && item.agency.image.url && (
                    <Image
                      src={item.agency.image.url}
                      width={50}
                      height={50}
                      style={{
                        minWidth: 50,
                        minHeight: 50,
                      }}
                    />
                  )
                }
                <a href={item.url} target="_blank" className="ms-2" rel="noreferrer">
                  {item.title}
                </a>
              </div>
              {
                item.description && (
                  <div className="mt-2" dangerouslySetInnerHTML={{ __html: item.description }} />
                )
              }
            </Card>
          </Col>
        ))
      }
    </Row>
  );
}
