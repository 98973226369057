import { AppAction, AppActionData } from '@kernex/common';
import { CustomMethods, Service } from '@feathersjs/feathers';
import ServiceAPI from '../common/ServiceAPI';

export interface TriggerData {
  actionId: AppAction['_id'];
}

export type AppActionsCustomMethods = CustomMethods<{
  trigger: [TriggerData, any];
}>;

export default class AppActions extends ServiceAPI<AppAction, AppActionData, AppActionsCustomMethods> {
  constructor(service: Service<AppAction, AppActionData> & AppActionsCustomMethods) {
    super(service);

    // @ts-ignore
    this.service.methods('trigger');
  }

  /**
   * Trigger this action
   * @param actionId
   */
  async trigger(actionId: AppAction['_id']): Promise<{ status: 'success' }> {
    return this.service.trigger({ actionId });
  }
}
