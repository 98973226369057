import { FieldTypeDetailsRendererProps } from '@kernex/core';
import { Image } from 'antd';

export default function DetailsRenderer(props: FieldTypeDetailsRendererProps) {
  const { value } = props;

  if (Array.isArray(value)) {
    return (
      <div className="d-flex align-items-center justify-content-center gap-2">
        {
          value.map((image) => (
            <Image src={image.url} style={{ maxHeight: 150 }} alt="Thumbnail" />
          ))
        }
      </div>
    );
  }

  if (!value || !value.url) {
    return null;
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      {/*
          // @ts-ignore */}
      <Image src={value.url} style={{ maxHeight: 150 }} alt="Thumbnail" />
    </div>
  );
}
