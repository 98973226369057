import { Col, notification, Row } from 'antd';
import {
  AppAction, Application, AppUserRole,
} from '@kernex/common';
import { useCallback, useEffect, useState } from 'react';
import AppRoles from '../../../permissions/components/AppRoles';
import Button from '../../../common/components/Button';
import useToggle from '../../../common/hooks/useToggle';
import AppActionDrawerForm from '../AppActionDrawerForm';
import api from '../../../../api';
import useQuery from '../../../common/hooks/useQuery';
import LoadingResponse from '../../../common/components/LoadingResponse';
import AppActionCard from '../AppActionCard';

interface AppActionsProps {
  appId: Application['_id'];
}

export default function AppActions(props: AppActionsProps) {
  const { appId } = props;
  const [visible, toggle] = useToggle(false);
  const [actionToEdit, setActionToEdit] = useState<AppAction>();

  const isEdit = Boolean(actionToEdit);

  useEffect(() => {
    if (isEdit) {
      toggle.on();
    }
  }, [isEdit]);

  useEffect(() => {
    if (!visible) {
      setActionToEdit(undefined);
    }
  }, [visible]);

  const loadActions = useCallback(async () => {
    const response = await api.appActions.find({
      query: {
        appId,
        $limit: 100,
      },
    });

    return response.data;
  }, [appId]);

  const [actions, loading, error, setActions] = useQuery(loadActions);

  const onSuccess = (action: AppAction) => {
    setActions((prevState) => {
      const prevActions = prevState || [];

      const exists = prevActions.find((prevAction) => prevAction._id === action._id);

      if (exists) {
        return prevActions.map((prevAction) => {
          if (prevAction._id === action._id) {
            return action;
          }

          return prevAction;
        });
      }

      return [action, ...prevActions];
    });
  };

  return (
    <Row gutter={[16, 16]}>
      {
        loading && (<LoadingResponse loading={loading} error={error} />)
      }
      {
        actions?.map((action) => (
          <Col key={action._id} span={24}>
            <AppActionCard
              name={action.name}
              description={action.description}
              buttonText={action.buttonText}
              onEdit={() => {
                setActionToEdit(action);
              }}
              onDelete={async () => {
                await api.appActions.remove(action._id);
                setActions((prevState) => {
                  const prevActions = prevState || [];
                  return prevActions.filter((prevAction) => prevAction._id !== action._id);
                });
              }}
              onTrigger={async () => {
                try {
                  await api.appActions.trigger(action._id);
                  notification.success({
                    message: 'Success',
                    description: 'Action triggered successfully',
                  });
                } catch (e) {
                  notification.error({
                    message: 'Error',
                    description: 'Error triggering action',
                  });
                }
              }}
            />
          </Col>
        ))
      }
      <AppRoles roles={[AppUserRole.OWNER, AppUserRole.DEVELOPER]}>
        {
          () => (
            <>
              <Col span={24}>
                <Button type="primary" onClick={toggle.on}>Add Action</Button>
              </Col>
              <AppActionDrawerForm
                open={visible}
                onClose={toggle.off}
                appId={appId}
                onSuccess={onSuccess}
                action={actionToEdit}
              />
            </>
          )
        }
      </AppRoles>
    </Row>
  );
}
