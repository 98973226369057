import { Select, SelectProps } from 'antd';
import { useRecoilState } from 'recoil';
import { ApplicationResource } from '@kernex/common';
import appsAtom from '../../../apps/state';

interface AppResourceSelectProps extends SelectProps {
  filter?: (resources: ApplicationResource) => boolean;
}

const defaultFilter = () => true;

export default function AppResourceSelect(props: AppResourceSelectProps) {
  const { filter = defaultFilter, ...rest } = props;
  const [{ services }] = useRecoilState(appsAtom);

  const options = services.filter(filter).map((service) => ({
    label: service.name,
    value: service._id,
  }));

  return (
    <Select
      placeholder="Select a resource"
      style={{ width: '100%' }}
      options={options}
      {...rest}
    />
  );
}
