import { useRecoilState } from 'recoil';
import {
  Col, Input, Row, Table,
} from 'antd';
import {
  useCallback, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  ApplicationResourceInstance,
  AppUserRole,
} from '@kernex/common';
import { Field, FieldTypeType } from '@kernex/core';

import { ColumnsType, ColumnType } from 'antd/lib/table/interface';
import { Query } from '@feathersjs/feathers';
import debounce from 'just-debounce';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import api from '../../../../api';
import appAdminAtom from '../../state';
import PageContent from '../../../layout/PageContent';
import Button from '../../../common/components/Button';
import AppResourceFieldAdminListSettings from '../../components/AppResourceFieldAdminListSettings';
import AppRoles from '../../../permissions/components/AppRoles';
import FieldValueRenderer from '../../../fields/components/FieldValueRenderer';
import { useFieldTypes } from '../../../../plugins';

const actionsColumn: ColumnType<ApplicationResourceInstance> = {
  title: 'Actions',
  width: 180,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render: (_: any, record: ApplicationResourceInstance) => (
    <div className="d-flex flex-row">
      <Link to={`${record._id}/edit`} className="me-2">
        <Button type="ghost" size="small">
          <EditOutlined />
          Edit
        </Button>
      </Link>
      <Link to={record._id}>
        <Button type="ghost" size="small">
          <EyeOutlined />
          View
        </Button>
      </Link>
    </div>
  ),
};

export default function AppAdminResourceListPage() {
  const [{ service, fields }, setAdminState] = useRecoilState(appAdminAtom);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<ApplicationResourceInstance[]>([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [mode, setMode] = useState<'list' | 'settings'>('list');
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState(search);
  const [sortColumn, setSortColumn] = useState<string>('createdAt');
  const [sortDirection, setSortDirection] = useState<'ascend' | 'descend'>('descend');
  const fieldTypes = useFieldTypes();

  const { titleFieldName } = service || {};
  const loadPage = useCallback(
    async () => {
      if (service) {
        setLoading(true);

        const query: Query = {
          $limit: pageSize,
          $skip: (page - 1) * pageSize,
          $sort: {
            [sortColumn]: sortDirection === 'ascend' ? 1 : -1,
          },
        };

        if (titleFieldName && searchValue) {
          query[titleFieldName] = { $search: searchValue };
        }

        const response = await api.appResourceInstance(service.applicationId, service.slug).find({ query });
        setTotal(response.total);
        setData(response.data);
        setLoading(false);
      }
    },
    [page, pageSize, service?._id, searchValue, titleFieldName, sortColumn, sortDirection],
  );

  useEffect(() => {
    loadPage().then();
  }, [loadPage]);

  const onSearch = useCallback(debounce((newValue) => {
    setSearchValue(newValue);
  }, 500), []);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  if (!service) {
    return <p>Resource Not Found</p>;
  }

  const columns: ColumnsType<ApplicationResourceInstance> = [
    ...fields.filter(
      (field) => !field.adminListSettings?.hide && field.type !== FieldTypeType.RICH_TEXT,
    ).map((field) => ({
      title: field.displayName,
      dataIndex: field.name,
      render: (value: any) => (
        <FieldValueRenderer
          value={value}
          field={field}
          fieldType={fieldTypes.find((ft) => ft.type === field.type)}
        />
      ),
      sorter: true,
      sortOrder: sortColumn === field.name ? sortDirection : undefined,
    })),
    actionsColumn,
  ];

  const onFieldChange = (field: Field) => {
    setAdminState((state) => ({
      ...state,
      fields: state.fields.map((f) => (f._id === field._id ? field : f)),
    }));
  };

  return (
    <PageContent
      title={service.name}
      description={service.description}
      actions={(
        <>
          <AppRoles roles={[AppUserRole.OWNER, AppUserRole.DEVELOPER]}>
            {
              () => (
                <>
                  <Button
                    onClick={() => {
                      setMode(mode === 'list' ? 'settings' : 'list');
                    }}
                  >
                    {mode === 'list' ? 'Page Settings' : 'View List'}
                  </Button>
                  <Link to={`../../resources/${service.slug}`} className="ms-2">
                    <Button>Go to Resource</Button>
                  </Link>
                </>
              )
            }
          </AppRoles>
          <Link to="new">
            <Button
              className="ms-2"
              type="primary"
            >
              + Create
            </Button>
          </Link>
        </>
      )}
      headerExtra={service.titleFieldName && mode === 'list' && (
        <Input
          className="my-2"
          placeholder={`Search by ${service.titleFieldName}`}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onSearch(e.target.value);
          }}
        />
      )}
      disableCardWrapper
    >
      {
        mode === 'list' && (
          <Table
            dataSource={data}
            columns={columns}
            loading={loading}
            pagination={total > 1 ? {
              current: page,
              pageSize,
              total,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              size: 'default',
            } : false}
            onChange={(pagination, filters, sorter) => {
              setPage(pagination.current || 1);
              if (!Array.isArray(sorter)) {
                if (sorter.field) {
                  setSortColumn(String(sorter.field));
                }
                if (sorter.order) {
                  setSortDirection(sorter.order);
                }
              }
            }}
            size="small"
            rowKey="_id"
            sortDirections={['ascend', 'descend', 'ascend']}
            bordered
          />
        )
      }
      {
        mode === 'settings' && (
          <Row gutter={[16, 16]}>
            {
              fields
                .filter((field) => field.type !== FieldTypeType.RICH_TEXT)
                .map((field) => (
                  <Col span={24} key={field._id}>
                    <AppResourceFieldAdminListSettings field={field} onChange={onFieldChange} />
                  </Col>
                ))
            }
          </Row>
        )
      }
    </PageContent>
  );
}
