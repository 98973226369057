import { Organization } from '@kernex/common';
import { Col, Drawer, Row } from 'antd';
import { useState } from 'react';
import countries from '../../../utils/countries';
import Button from '../../common/components/Button';
import OrganizationAddressForm, { OrganizationAddressFormProps } from './OrganizationAddressForm';

interface OrganizationAddressProps extends Pick<OrganizationAddressFormProps, 'onSaveSuccess'> {
  organization: Organization;
}

function getCountryName(countryCode: string) {
  const country = countries.find((c) => c.code === countryCode);
  return country ? country.name : '';
}

export default function OrganizationAddress(props: OrganizationAddressProps) {
  const { organization, onSaveSuccess } = props;
  const { address } = organization;

  const [open, setOpen] = useState(false);

  return (
    <Row>
      <Col span={24}>
        <h4>Address</h4>
        <p className="text-muted">
          This address appears on your monthly invoice and should be the legal address of your home or business
        </p>
        <div className="d-flex justify-content-between">
          <div>
            <div>
              {address?.line1 && (<p className="m-0">{address.line1}</p>)}
              {address?.line2 && (<p className="m-0">{address.line2}</p>)}
              <p className="m-0">
                {[address.city, address.state, address.postalCode].filter(Boolean).join(', ')}
              </p>
              {
                address.country && (
                  <p className="m-0">{getCountryName(address.country)}</p>
                )
              }
            </div>
          </div>
          <Button onClick={() => { setOpen(true); }}>
            Edit Address
          </Button>
        </div>
      </Col>
      <Drawer
        open={open}
        onClose={() => { setOpen(false); }}
        title="Organization Address"
      >
        <OrganizationAddressForm
          organization={organization}
          onSaveSuccess={(updatedOrganization) => {
            setOpen(false);
            onSaveSuccess(updatedOrganization);
          }}
        />
      </Drawer>
    </Row>
  );
}
