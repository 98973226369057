import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ApplicationResourceInstance } from '@kernex/common';

import { Form } from 'antd';
import api from '../../../../api';
import appsAtom from '../../../apps/state';
import PageContent from '../../../layout/PageContent';
import appAdminAtom from '../../state';
import AppResourceUserForm from '../../../app-resources/components/AppResourceUserForm';
import { singular } from '../../../../utils';
import Button from '../../../common/components/Button';

export default function AppAdminResourceEditPage() {
  const { app } = useRecoilValue(appsAtom);
  const { resourceId } = useParams<{ resourceId: string }>();
  const { service, fields } = useRecoilValue(appAdminAtom);
  const [data, setData] = useState<ApplicationResourceInstance>();
  const [loading, setLoading] = useState(true);

  const appId = app?._id;

  const loadData = async () => {
    if (appId && service && resourceId) {
      setLoading(true);
      const response = await api.appResourceInstance(appId, service.slug).get(resourceId);
      setData(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData().then();
  }, [appId, service?.slug]);

  const [form] = Form.useForm();

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <PageContent
      title={service ? `Edit ${singular(service.name)}` : 'Edit'}
      actions={[
        <Button
          htmlType="submit"
          onClick={form.submit}
        >
          Save
        </Button>,
      ]}
    >
      {
        data && service && (
          <div>
            <div style={{ maxWidth: 1000, margin: 'auto' }}>
              <AppResourceUserForm
                applicationId={service.applicationId}
                serviceSlug={service.slug}
                fields={fields}
                data={data}
                form={form}
              />
            </div>
          </div>
        )
      }
    </PageContent>
  );
}
