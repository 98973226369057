import { FieldRelationType, FieldTypeType } from '@kernex/core';
import AppTemplate, { AppTemplateResource } from '../../definitions/AppTemplate';

const blogResources: AppTemplateResource[] = [
  {
    name: 'Categories',
    slug: 'categories',
    description: 'A blog post category',
    fields: [
      {
        templateId: 'name',
        name: 'name',
        displayName: 'Name',
        type: FieldTypeType.TEXT,
        required: true,
        description: 'Category name',
        isTitle: true,
      },
      {
        templateId: 'slug',
        name: 'slug',
        displayName: 'Slug',
        type: FieldTypeType.SLUG,
        required: true,
        description: 'Category slug (as it will show up in URLs)',
        relatedFieldId: 'name',
      },
    ],
  },
  {
    name: 'Blog Posts',
    slug: 'blog-posts',
    description: 'A blog post',
    fields: [
      {
        templateId: 'title',
        name: 'title',
        displayName: 'Title',
        type: FieldTypeType.TEXT,
        required: true,
        description: 'Blog post title',
        isTitle: true,
      },
      {
        templateId: 'slug',
        name: 'slug',
        displayName: 'Slug',
        type: FieldTypeType.SLUG,
        required: true,
        description: 'Category slug (as it will show up in URLs)',
        relatedFieldId: 'title',
      },
      {
        templateId: 'content',
        name: 'content',
        displayName: 'Content',
        type: FieldTypeType.RICH_TEXT,
        required: true,
        description: 'Blog post content',
      },
      {
        templateId: 'thumbnail',
        name: 'thumbnail',
        displayName: 'Thumbnail',
        type: FieldTypeType.IMAGE,
        required: true,
        description: 'Blog post thumbnail',
      },
      {
        templateId: 'categories',
        name: 'categories',
        displayName: 'Categories',
        type: FieldTypeType.RELATION,
        relationType: FieldRelationType.ONE_TO_MANY,
        relationResourceId: 'categories',
        required: true,
        description: 'Blog post categories',
      },
    ],
  },
];

const templates: AppTemplate[] = [
  {
    name: 'NextJS Blog (Typescript)',
    description: 'A NextJS Blog built with Typescript',
    value: 'next-js-blog-typescript',
    resources: blogResources,
    url: 'https://github.com/kernex-io/kernex-nextjs-blog-typescript',
  },
  {
    name: 'NextJS Blog (Javascript)',
    description: 'A NextJS Blog built with Javascript',
    value: 'next-js-blog-javascript',
    resources: blogResources,
    url: 'https://github.com/kernex-io/kernex-nextjs-blog-javascript',
  },
];

export default templates;
