import { AppUserRole } from '@kernex/common';
import { Outlet } from 'react-router-dom';
import AppRoles from '../../../permissions/components/AppRoles';

export default function AppResources() {
  return (
    <AppRoles roles={[AppUserRole.DEVELOPER, AppUserRole.OWNER]}>
      {
        () => <Outlet />
      }
    </AppRoles>
  );
}
