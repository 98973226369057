import { AppAction, AppUserRole } from '@kernex/common';
import { Card, Popconfirm } from 'antd';
import Button from '../../../common/components/Button';
import AppRoles from '../../../permissions/components/AppRoles';
import Icon from '../../../common/components/Icon';
import useToggle from '../../../common/hooks/useToggle';

interface AppActionCardProps extends Pick<AppAction, 'name' | 'description' | 'buttonText'> {
  onEdit: () => void;
  onDelete: () => void;
  onTrigger: () => Promise<void>;
}

export default function AppActionCard(props: AppActionCardProps) {
  const {
    name, description, buttonText, onEdit, onDelete, onTrigger,
  } = props;
  const [executing, toggle] = useToggle(false);

  const onTriggerClick = async () => {
    toggle.on();

    try {
      await onTrigger();
    } catch (e) {
      // Ignore
    }

    toggle.off();
  };

  return (
    <Card size="small">
      <div className="d-flex align-items-center">
        <div style={{ flex: 1 }}>
          <p className="m-0">{name}</p>
        </div>
        <div className="d-flex justify-content-center" style={{ flex: 2 }}>
          <p className="m-0">{description}</p>
        </div>
        <div className="d-flex justify-content-end" style={{ flex: 1 }}>
          <Button
            type="default"
            loading={executing}
            onClick={onTriggerClick}
          >
            {buttonText}
          </Button>
          <AppRoles roles={[AppUserRole.OWNER, AppUserRole.DEVELOPER]}>
            {
              () => (
                <>
                  <Button
                    type="primary"
                    className="ms-2"
                    onClick={onEdit}
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete this action?"
                    placement="leftBottom"
                    onConfirm={onDelete}
                  >
                    <Button type="default" danger className="ms-2">
                      <Icon name="delete" />
                    </Button>
                  </Popconfirm>
                </>
              )
            }
          </AppRoles>
        </div>
      </div>
    </Card>
  );
}
