import { Form, Input, Select } from 'antd';
import { AppUser, AppUserData, AppUserRole } from '@kernex/common';
import DrawerForm, { DrawerFormProps } from '../../../common/components/DrawerForm';
import api from '../../../../api';

interface AppUserDrawerFormProps extends Omit<DrawerFormProps<Pick<AppUserData, 'email' | 'role'>>, 'children'> {
  appId: AppUser['appId'];
  onSuccess?: (user: AppUser) => void;
}

export default function AppUserDrawerForm(props: AppUserDrawerFormProps) {
  const { appId, onSuccess, ...rest } = props;

  const onFinish = async (data: Pick<AppUserData, 'email' | 'role'>) => {
    const appUser = await api.appUsers.create({
      ...data,
      appId,
    });

    if (onSuccess) {
      onSuccess(appUser);
    }
  };

  return (
    <DrawerForm title="Add User" onFinish={onFinish} {...rest}>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Please enter user email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input placeholder="Enter user email" />
      </Form.Item>
      <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select user role' }]}>
        <Select placeholder="Select user role">
          <Select.Option value={AppUserRole.DEVELOPER}>Developer</Select.Option>
          <Select.Option value={AppUserRole.EDITOR}>Editor</Select.Option>
        </Select>
      </Form.Item>
    </DrawerForm>
  );
}
