import { Form, notification } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { useState } from 'react';
import {
  ApplicationResource,
  ApplicationResourceInstance,
  ApplicationResourceInstanceData,
} from '@kernex/common';
import { Field, FieldType, FieldTypeType } from '@kernex/core';
import { useNavigate } from 'react-router-dom';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import AppResourceFieldInput from '../AppResourceFieldInput';
import api from '../../../../api';
import Button from '../../../common/components/Button';
import { useFieldTypes } from '../../../../plugins';
import { setFormErrorsFromServer } from '../../../common/utils/errors';

interface AppResourceUserFormProps {
  applicationId: ApplicationResource['applicationId'];
  serviceSlug: ApplicationResource['slug'];
  fields: Field[];
  data?: ApplicationResourceInstance;
  form?: FormInstance;
}

function getRules(
  field: Field,
  fieldType?: FieldType,
) {
  const rules: Rule[] = [];

  if (field.required) {
    rules.push({
      required: true,
      message: 'This field is required',
    });
  }

  if (field.type === FieldTypeType.SLUG) {
    rules.push({
      pattern: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/,
      message: 'Only alphanumeric characters, numbers and dashes are allowed',
    });
  }

  const { validators } = fieldType || {};

  if (validators) {
    rules.push(({ getFieldsValue }) => ({
      validator: async (rule, value, callback) => {
        let i = 0;

        while (i < validators.length) {
          const { validate } = validators[i];

          // eslint-disable-next-line no-await-in-loop
          await validate(value, getFieldsValue(), field);

          i += 1;
        }

        callback();
      },
    }));
  }

  return rules;
}

export default function AppResourceUserForm(props: AppResourceUserFormProps) {
  const {
    applicationId,
    serviceSlug,
    fields,
    data,
    form: propsForm,
  } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [localForm] = Form.useForm();
  const fieldTypes = useFieldTypes();

  const form = propsForm || localForm;

  const onFinish = async (value: ApplicationResourceInstanceData) => {
    setLoading(true);

    try {
      if (!data) {
        const response = await api.appResourceInstance(applicationId, serviceSlug).create(value);
        navigate(`../${response._id}`);
        notification.success({
          message: 'Entry created',
        });
      } else {
        await api.appResourceInstance(applicationId, serviceSlug).patch(data._id, value);
        notification.success({
          message: 'Entry updated',
        });
        navigate('../');
      }
    } catch (e) {
      setFormErrorsFromServer(e, form);
    }

    setLoading(false);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={data}
      layout="vertical"
    >
      {
        fields
          .filter((field) => !field.autogenerated && !field.hideInUserForms)
          .map((field) => {
            const { relatedFieldId, type } = field;
            const fieldType = fieldTypes.find((f) => f.type === type);

            let relatedField: Field | undefined;

            if (relatedFieldId) {
              relatedField = fields.find((f) => f._id === relatedFieldId);
            }

            const renderItem = () => (
              <Form.Item
                key={field._id}
                label={field.displayName}
                name={field.name}
                rules={getRules(field, fieldType)}
              >
                <AppResourceFieldInput
                  appId={applicationId}
                  field={field}
                  relatedFieldValue={relatedField ? form.getFieldValue(relatedField.name) : undefined}
                  relatedFieldTouched={relatedField ? form.isFieldTouched(relatedField.name) : false}
                />
              </Form.Item>
            );

            if (relatedField) {
              return (
                <Form.Item
                  key={field._id}
                  shouldUpdate={(prevValues, currentValues) => {
                    const { name } = relatedField || {};
                    return name ? prevValues[name] !== currentValues[name] : false;
                  }}
                >
                  {renderItem}
                </Form.Item>
              );
            }

            return renderItem();
          })
      }
      <Form.Item hidden>
        <Button htmlType="submit" loading={loading}>Save</Button>
      </Form.Item>
    </Form>
  );
}
