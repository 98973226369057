import {
  Card, Col, Row, Tag, Popconfirm, notification, CardProps,
} from 'antd';
import { useState } from 'react';
import { Field } from '@kernex/core';
import Button from '../../../common/components/Button';
import api from '../../../../api';

interface AppResourceFieldCardProps extends CardProps {
  field: Field;
  onEditClick?: () => void;
  onDeleteSuccess?: () => void;
}

export default function AppResourceFieldCard(props: AppResourceFieldCardProps) {
  const {
    field, onEditClick, onDeleteSuccess, ...rest
  } = props;
  const [deleting, setDeleting] = useState(false);
  const { isProtected } = field;

  const onDeleteClick = async () => {
    setDeleting(true);

    try {
      await api.fields.remove(field._id);
      if (onDeleteSuccess) onDeleteSuccess();
    } catch (e) {
      notification.error({
        message: 'Error while removing field',
        description: 'Please try again',
      });
    }

    setDeleting(false);
  };

  return (
    <Card
      size="small"
      {...rest}
    >
      <Row gutter={[16, 16]}>
        <Col span={24} md={8} className="d-flex align-items-center">
          <p className="m-0">{field.displayName}</p>
        </Col>
        <Col span={24} md={8} className="d-flex align-items-center justify-content-center">
          <Tag color="blue">{field.type}</Tag>
          {
            field.required && (
              <Tag color="green">required</Tag>
            )
          }
        </Col>
        <Col span={24} md={8} className="d-flex justify-content-end">
          <Button size="small" onClick={onEditClick} disabled={isProtected}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure?"
            okText="Delete Field"
            okButtonProps={{ loading: deleting }}
            onConfirm={onDeleteClick}
          >
            <Button size="small" type="primary" className="ms-2" danger disabled={isProtected}>Delete</Button>
          </Popconfirm>
        </Col>
      </Row>
    </Card>
  );
}
