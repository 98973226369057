import {
  Card, Col, Row,
} from 'antd';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import organizationsAtom from '../../../organizations/atoms';
import useOrganizationPlan from '../../../organizations/hooks/useOrganizationPlan';
import Button from '../../../common/components/Button';
import CreateAppFlow from '../../components/CreateAppFlow';

export default function CreateAppPage() {
  const { organization, apps, newAppsCount } = useRecoilValue(organizationsAtom);
  const { plan } = useOrganizationPlan();

  if (!organization) {
    return (<p>Organization not found</p>);
  }

  const canCreateNewApps = ((plan?.appCount || 0) + newAppsCount) > apps.length;

  return (
    <div className="p-4">
      <Row justify="center" gutter={[16, 16]}>
        <Col span={24} md={22} lg={20} xl={18} xxl={16}>
          <Card>
            {
              canCreateNewApps ? (
                <Row>
                  <Col span={24}>
                    <CreateAppFlow organizationId={organization._id} />
                  </Col>
                </Row>
              ) : (
                <div className="d-flex flex-column align-items-center my-4">
                  <h5 className="flex-grow-1 text-center">
                    Your current plan allows you to create up to
                    {' '}
                    {plan?.appCount}
                    {' '}
                    app
                    {plan?.appCount !== 1 ? 's' : ''}
                    .
                    {' '}
                    Please
                    {' '}
                    {plan ? 'upgrade your subscription' : 'subscribe'}
                    {' '}
                    to create new apps.
                  </h5>
                  <Link className="mt-2" to={`/app/organizations/${organization.slug}/billing`}>
                    <Button type="primary">{plan ? 'Upgrade' : 'Subscribe'}</Button>
                  </Link>
                </div>
              )
            }
          </Card>
        </Col>
      </Row>
    </div>
  );
}
