import { Subscription, SubscriptionData } from '@kernex/common';
import ServiceAPI from '../common/ServiceAPI';

export default class Subscriptions extends ServiceAPI<Subscription, SubscriptionData> {
  /**
   * Subscribe to a subscription
   * @param data
   */
  async subscribe(data: Pick<SubscriptionData, 'organizationId' | 'planId'>) {
    return this.create(data);
  }

  /**
   * Unsubscribe
   * @param subscriptionId
   */
  async unsubscribe(subscriptionId: Subscription['_id']) {
    return this.patch(subscriptionId, { cancelAtPeriodEnd: true });
  }

  /**
   * Resubscribe to an active subscription
   * @param subscriptionId
   */
  async resubscribe(subscriptionId: Subscription['_id']) {
    return this.patch(subscriptionId, { cancelAtPeriodEnd: false });
  }
}
