import { useEffect, useState } from 'react';
import {
  Checkbox, Col, Drawer, Form, Input, notification, Row, Select, Steps,
} from 'antd';
import {
  Field,
  FieldRelationType,
  FieldTypeType,
} from '@kernex/core';
import { useRecoilValue } from 'recoil';
import api from '../../../../api';
import Button from '../../../common/components/Button';
import SelectBoxInput from '../../../common/components/SelectBoxInput';
import AppResourceSelect from '../AppResourceSelect';
import { camelCase } from '../../../../utils';
import { setFormErrorsFromServer } from '../../../common/utils/errors';
import appsAtom from '../../../apps/state';
import { useFieldTypes } from '../../../../plugins';

interface AppResourceFieldFormProps {
  resourceId: Field['resourceId'];
  fields?: Field[];
  field?: Field;
  onCancel?: () => void;
  onSuccess?: (field: Field) => void;
}

interface ApplicationResourceTypeLabelProps {
  typeName: string;
  description?: string;
}

function ApplicationResourceTypeLabel(props: ApplicationResourceTypeLabelProps) {
  const { typeName, description } = props;

  return (
    <div>
      <h5 className="mb-1">{typeName}</h5>
      <p className="m-0 text-muted">{description}</p>
    </div>
  );
}

export default function AppResourceFieldForm(props: AppResourceFieldFormProps) {
  const {
    field, onCancel, onSuccess, resourceId, fields,
  } = props;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { app } = useRecoilValue(appsAtom);
  const fieldTypes = useFieldTypes();
  const [step, setStep] = useState(0);
  const totalSteps = 3;
  const isLastStep = step === totalSteps - 1;
  const [type, setType] = useState<FieldTypeType | undefined>(field?.type);

  useEffect(() => {
    setVisible(true);
  }, []);

  const onFinish = async (values: Field) => {
    let newField: Field;
    setLoading(true);

    try {
      if (!field) {
        newField = await api.fields.create({ ...values, resourceId });
      } else {
        // No need to send the name to the server because it can not be changed
        const { name, ...data } = values;
        newField = await api.fields.patch(field._id, data);
      }

      if (onSuccess) {
        onSuccess(newField);
      }
    } catch (e) {
      setFormErrorsFromServer(e, form);
    }

    setLoading(false);
  };

  const onChange = (changedValues: Partial<Field>) => {
    if (!field && changedValues.displayName) {
      form.setFieldsValue({ name: camelCase(changedValues.displayName) });
    }

    if (changedValues.type) {
      setType(changedValues.type);
    }
  };

  const options = fieldTypes
    .filter((fieldType) => !fieldType.applicationId || fieldType.applicationId === app?._id)
    .map((fieldType) => ({
      label: (
        <ApplicationResourceTypeLabel typeName={fieldType.name} description={fieldType.description} />
      ),
      value: fieldType.type,
    }));

  const onStepChange = (newStep: number) => {
    if (newStep > 0 && !type) {
      notification.error({
        message: 'Please select field type',
      });
    } else {
      setStep(newStep);
    }
  };

  const fieldType = fieldTypes.find((ft) => ft.type === type);

  return (
    <Drawer
      open={visible}
      onClose={() => {
        setVisible(false);
        if (onCancel) {
          onCancel();
        }
      }}
      contentWrapperStyle={{ minWidth: '80vw' }}
      footer={(
        <div className="d-flex justify-content-end">
          {
            step > 0 && (
              <Button
                onClick={() => { setStep((prev) => prev - 1); }}
              >
                Previous
              </Button>
            )
          }
          {
            type && !isLastStep && (
              <Button
                type="primary"
                onClick={() => { setStep((prev) => prev + 1); }}
                className="ms-2"
              >
                Next
              </Button>
            )
          }
          {
            isLastStep && (
              <Button
                type="primary"
                loading={loading}
                onClick={form.submit}
                className="ms-2"
              >
                Save
              </Button>
            )
          }
        </div>
      )}
      title={field ? 'Edit Field' : 'Create Field'}
    >
      <Steps className="mb-4" current={step} onChange={onStepChange}>
        <Steps.Step title="Field Type" />
        <Steps.Step title="Field Info" />
        <Steps.Step title="Validation" />
      </Steps>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ required: false, hideInUserForms: false, ...(field || {}) }}
        onFinish={onFinish}
        onValuesChange={onChange}
      >
        <Row>
          <Col span={24} style={{ display: step === 0 ? 'block' : 'none' }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: 'Please select field type' }]}
                >
                  <SelectBoxInput options={options} />
                </Form.Item>
              </Col>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  if (
                    type !== FieldTypeType.RELATION
                    && type !== FieldTypeType.TITLES_LINK
                    && type !== FieldTypeType.SLUG
                  ) {
                    return null;
                  }

                  if (type === FieldTypeType.SLUG) {
                    return (
                      <Col span={24}>
                        <Form.Item
                          label="Related Field"
                          name="relatedFieldId"
                          rules={[{ required: true, message: 'Please select related field' }]}
                        >
                          <Select placeholder="Select the field to slugify">
                            {
                              (fields || [])
                                .filter((f) => f.type === FieldTypeType.TEXT)
                                .map((f) => (
                                  <Select.Option key={f._id}>{f.displayName}</Select.Option>
                                ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    );
                  }

                  if (type === FieldTypeType.TITLES_LINK) {
                    return (
                      <Col span={24}>
                        <Form.Item
                          label="Agencies Resource"
                          name="relationResourceId"
                          rules={[{ required: true, message: 'Please select agencies resource' }]}
                        >
                          <AppResourceSelect
                            filter={(resource) => resource._id !== resourceId}
                          />
                        </Form.Item>
                      </Col>
                    );
                  }

                  return (
                    <Col span={24}>
                      <Form.Item
                        label="Relation Resource"
                        name="relationResourceId"
                        rules={[{ required: true, message: 'Please select relationship resource' }]}
                      >
                        <AppResourceSelect />
                      </Form.Item>
                      <Form.Item
                        label="Relation Type"
                        name="relationType"
                        rules={[{ required: true, message: 'Please select relation type' }]}
                      >
                        <SelectBoxInput
                          options={[
                            {
                              label: (
                                <ApplicationResourceTypeLabel
                                  typeName="One to one"
                                  description="One resource can only be related to one other resource"
                                />
                              ),
                              value: FieldRelationType.ONE_TO_ONE,
                            },
                            {
                              label: (
                                <ApplicationResourceTypeLabel
                                  typeName="One to many"
                                  description="One resource can be related to many other resources"
                                />
                              ),
                              value: FieldRelationType.ONE_TO_MANY,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  );
                }}
              </Form.Item>
            </Row>
          </Col>
          <Col span={24} style={{ display: step === 1 ? 'block' : 'none' }}>
            <Row gutter={[16, 16]}>
              <Col span={24} md={12} xxl={6}>
                <Form.Item
                  label="Display Name"
                  name="displayName"
                  extra="The name that will appear in forms, lists, and other places"
                  rules={[
                    { required: true, message: 'Please input the display name' },
                  ]}
                >
                  <Input size="small" placeholder="Field Name" />
                </Form.Item>
              </Col>
              <Col span={24} md={12} xxl={6}>
                <Form.Item
                  label="Name"
                  name="name"
                  extra="Field name used in the API"
                  rules={[
                    { required: true, message: 'Please input the field name' },
                    { pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/, message: 'Only alphanumeric characters and underscores' },
                  ]}
                >
                  <Input size="small" placeholder="Field Name" disabled={!!field} />
                </Form.Item>
              </Col>
              <Col span={12} md={12} xxl={6}>
                <Form.Item
                  name="isTitle"
                  valuePropName="checked"
                  label=" "
                  extra="Whether this field is used as the title of the resource"
                >
                  <Checkbox>Use as entry title</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12} md={12} xxl={6}>
                <Form.Item
                  name="hideInUserForms"
                  valuePropName="checked"
                  label=" "
                  extra="Whether to hide this field in user-facing forms"
                >
                  <Checkbox>Hide In User Forms</Checkbox>
                </Form.Item>
              </Col>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  if (!fieldType?.supportsArray) {
                    return null;
                  }

                  return (
                    <Col span={12} md={12} xxl={6}>
                      <Form.Item
                        name="isArray"
                        valuePropName="checked"
                        label=" "
                        extra="Whether this is an array field"
                      >
                        <Checkbox>Is Array</Checkbox>
                      </Form.Item>
                    </Col>
                  );
                }}
              </Form.Item>
              <Col span={24} lg={12}>
                <Form.Item name="description" label="Description">
                  <Input size="small" placeholder="Field Description" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ display: step === 2 ? 'block' : 'none' }}>
            <Row gutter={[16, 16]}>
              <Col span={12} md={12} xxl={6}>
                <Form.Item
                  name="required"
                  valuePropName="checked"
                  extra="Whether this field is required when creating new entries"
                >
                  <Checkbox>Required</Checkbox>
                </Form.Item>
              </Col>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  if (!fieldType || !fieldType.validators) {
                    return null;
                  }

                  return (
                    <>
                      {
                        fieldType.validators.map((validator) => (
                          <Col
                            key={validator.fieldName}
                            span={12}
                            md={12}
                            xxl={6}
                          >
                            <Form.Item
                              name={['validation', validator.fieldName]}
                              label={validator.label}
                            >
                              <validator.Input />
                            </Form.Item>
                          </Col>
                        ))
                      }
                    </>
                  );
                }}
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
