import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert } from 'antd';
import AuthLayout from '../../layout/AuthLayout';
import api from '../../../../api';
import LoadingResponse from '../../../common/components/LoadingResponse';

export default function VerifyEmailPage() {
  const [params] = useSearchParams();
  const token = params.get('token');
  const [loading, setLoading] = useState(Boolean(token));
  const [error, setError] = useState(!token);
  const [verified, setVerified] = useState(false);

  const verify = async () => {
    if (token) {
      setLoading(true);

      try {
        await api.users.verify({ token });
        setVerified(true);
      } catch (e) {
        // Something went wrong
        setError(true);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    verify().then();
  }, [token]);

  return (
    <AuthLayout>
      <h1>Verify Email</h1>
      {
        error && (<p>Oops, something went wrong while verifying your email, please try again.</p>)
      }
      {
        loading && (<LoadingResponse loading={loading} />)
      }
      {
        verified && (
          <Alert
            type="success"
            message="Your email has been verified"
            action={(
              <Link to="/sign-in">
                Sign In
              </Link>
            )}
          />
        )
      }
    </AuthLayout>
  );
}
