import { Card, Col, Row } from 'antd';
import { useRecoilValue } from 'recoil';
import AppResourceForm from '../../components/AppResourceForm';
import appsAtom from '../../../apps/state';

export default function CreateAppResourcePage() {
  const { app } = useRecoilValue(appsAtom);

  if (!app?._id) {
    return (<p>App not found</p>);
  }

  return (
    <div className="p-4">
      <Row justify="center">
        <Col span={24} md={20} lg={18} xl={16} xxl={14}>
          <Card>
            <h4>Create Resource</h4>
            <AppResourceForm appId={app._id} />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
