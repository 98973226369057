import {
  Form, Input, Button, Alert,
} from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import AuthLayout from '../../layout/AuthLayout';
import useFormSubmit from '../../../common/hooks/useFormSubmit';
import api from '../../../../api';

export default function ResetPasswordPage() {
  const [form] = Form.useForm();
  const [params] = useSearchParams();
  const token = params.get('token');

  const [onFinish, loading, response] = useFormSubmit(
    async ({ password }: { password: string }) => {
      if (!token) {
        throw new Error('Token is missing');
      }
      return api.users.resetPassword({ token, password });
    },
    { form },
  );

  return (
    <AuthLayout>
      <h1>Reset Password</h1>

      {
        token ? (
          <div>
            {
              !response ? (
                <Form
                  layout="vertical"
                  className="hp-mt-sm-16 hp-mt-32"
                  onFinish={onFinish}
                >
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      { required: true, message: 'Please input your password' },
                      { min: 8, message: 'Password should contain at least 8 characters' },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="passwordConfirm"
                    dependencies={['password']}
                    rules={[
                      { required: true, message: 'Please confirm your password' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm Password" />
                  </Form.Item>

                  <Form.Item className="hp-mt-16 hp-mb-8">
                    <Button block type="primary" htmlType="submit" loading={loading}>
                      Reset Password
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Alert
                  type="success"
                  message="Password reset successfully, please login with your new password"
                  action={(
                    <Link to="/sign-in">
                      Sign In
                    </Link>
                  )}
                />
              )
            }
          </div>
        ) : (
          <p>Invalid reset password link. Your token may have expired, please try to reset your password again</p>
        )
      }
    </AuthLayout>
  );
}
