import { Application } from '@kernex/common';
import { Alert, Space } from 'antd';
import { Link } from 'react-router-dom';
import useOrganizationPlan from '../../../organizations/hooks/useOrganizationPlan';
import useAppUsage from '../../hooks/useAppUsage';
import Button from '../../../common/components/Button';
import useOrganization from '../../../organizations/hooks/useOrganization';

interface AppUsageNoticeProps {
  app?: Application;
  closable?: boolean;
  description?: string;
}

export default function AppUsageNotice(props: AppUsageNoticeProps) {
  const { app, description, closable = true } = props;
  const { plan } = useOrganizationPlan();
  const organization = useOrganization();
  const {
    recordsLimitClose, usersLimitClose, recordsLimitReached, usersLimitReached,
  } = useAppUsage(app, plan);

  let anyLimitClose = false;
  let anyLimitReached = false;

  if (recordsLimitClose || usersLimitClose) {
    anyLimitClose = true;
  }

  if (recordsLimitReached || usersLimitReached) {
    anyLimitReached = true;
  }

  const renderAction = () => (
    <div className="h-100 align-items-center justify-content-center">
      <Space direction="horizontal">
        <Link to={`/app/organizations/${organization?.slug}/apps/${app?.slug}/usage`}>
          <Button size="small">
            Check Usage
          </Button>
        </Link>
        <Link to={`/app/organizations/${organization?.slug}/billing`}>
          <Button size="small" type="primary">
            Upgrade
          </Button>
        </Link>
      </Space>
    </div>
  );

  if (anyLimitReached) {
    return (
      <Alert
        message={
        `You have reached your plan limits ${description || 'Please upgrade your plan to continue using this app.'}`
      }
        type="error"
        showIcon
        closable={closable}
        action={renderAction()}
      />
    );
  }

  if (anyLimitClose) {
    return (
      <Alert
        message={
        `You are close to your plan limits. ${description || 'Please upgrade your plan to continue using this app.'}`
      }
        type="warning"
        showIcon
        closable={closable}
        action={renderAction()}
      />
    );
  }

  return null;
}
