import { useRecoilValue } from 'recoil';
import AppDocs from '../../components/AppDocs';
import useOrganization from '../../../organizations/hooks/useOrganization';
import appsAtom from '../../state';

export default function AppDocumentationPage() {
  const organization = useOrganization();
  const { app, services } = useRecoilValue(appsAtom);

  if (!app || !organization) {
    return null;
  }

  return (<AppDocs app={app} organization={organization} resources={services} />);
}
