import { Col, Divider, Row } from 'antd';
import { Application } from '@kernex/common';
import AppDatabaseSettings from '../AppDatabaseSettings';

interface AppSettingsProps {
  app: Application;
  onUpdate?: (app: Application) => void;
}

export default function AppSettings(props: AppSettingsProps) {
  const { app, onUpdate } = props;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={16} lg={14} xl={12} xxl={10}>
        <Divider>Database</Divider>
        <AppDatabaseSettings app={app} onUpdate={onUpdate} />
      </Col>
    </Row>
  );
}
