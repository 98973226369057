import React from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';

import './global/styles/global.less';
import 'antd/dist/antd.less';
import './global/styles/bootstrap-grid.min.css';
import './global/styles/bootstrap-utilities.min.css';
import './global/styles/styles.css';
import Navigation from './navigation';

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
