import { AppIntegration, AppIntegrationService } from '@kernex/common';
import { Card, Popconfirm, Tag } from 'antd';
import { capitalize } from '../../../../utils';
import Button from '../../../common/components/Button';
import Icon from '../../../common/components/Icon';

type AppResourceIntegrationCardProps = Pick<AppIntegration, 'service' | 'enabled'> & {
  onEdit: () => void;
  onDelete: () => void;
};

function getServiceDisplayName(service: AppIntegrationService) {
  const map = {
    [AppIntegrationService.ALGOLIA]: 'Algolia',
  };

  return map[service] || capitalize(service);
}

export default function AppResourceIntegrationCard(props: AppResourceIntegrationCardProps) {
  const {
    service, enabled, onEdit, onDelete,
  } = props;

  return (
    <Card size="small">
      <div className="d-flex align-items-center">
        <p className="m-0" style={{ flex: 1 }}>{getServiceDisplayName(service)}</p>
        <div className="d-flex justify-content-center">
          <Tag
            style={{ flex: 2 }}
            color={enabled ? 'green' : 'red'}
          >
            {enabled ? 'Enabled' : 'Disabled'}
          </Tag>
        </div>
        <div className="d-flex justify-content-end" style={{ flex: 1 }}>
          <Button type="primary" size="small" onClick={onEdit}>Edit</Button>
          <Popconfirm title="Are you sure?" okText="Delete Integration" onConfirm={onDelete}>
            <Button className="ms-2" type="default" size="small" danger>
              <Icon name="delete" />
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Card>
  );
}
