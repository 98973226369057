import { Component } from 'react';
import { Field, FieldType } from '@kernex/core';

interface FieldValueRendererProps {
  field: Field;
  value?: any;
  fieldType?: FieldType;
}

export default class FieldValueRenderer extends Component<FieldValueRendererProps, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  renderRawValue() {
    const { value } = this.props;
    if (value && typeof value === 'object') {
      return JSON.stringify(value, null, 2);
    }

    return String(value);
  }

  renderValue() {
    const { fieldType, value, field } = this.props;

    if (fieldType && fieldType.DetailsRenderer) {
      return (<fieldType.DetailsRenderer field={field} value={value} />);
    }

    return this.renderRawValue();
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div>
          <p>Error showing field details, showing raw value.</p>
          <div>
            {this.renderRawValue()}
          </div>
        </div>
      );
    }

    return this.renderValue();
  }
}
